import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Error from "../Error/Error";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";

export default function Showcoursedetails() {
  const [load, setLoad] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [freeCourses, setFreeCourse] = useState([]);
  const [course, setCourse] = useState({ name: "" });
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackageById?id=${id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          //   setPackages(data);
          setCourse(data);
          //   setPrice(data.price);
          //   setLoad(false);
        });
      } else {
        // setLoad(false);
        // setErr(true);
      }
    });

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${id}`
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        res.json().then((data) => {
          setFreeCourse(data);
          // console.log(data)
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  const LessonsMaped = freeCourses.map((e, index) => {
    return (
      <div
        className=" relative shadow-md rtl p-6 mx-4 md:mx-6 my-2 rounded-3xl bg-[#fdebf3] col-span-1"
        key={index}
      >
        <div className=" overflow-  rounded-full flex h-8 w-8 md:h-10 md:w-10 justify-center relative text-white  bg-[#e51d74]">
          <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-6px]">
            {index + 1}
          </h1>
        </div>
        <hr className=" w-[70%] mx-auto  my-4 border-black/20"></hr>
        <div className=" mb-20">
          <h1 className=" text-[#e51d74] text-2xl my-4 font-bold text-center">
            {e.title}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#e51d74]/50">{e.unit.title}</h4> */}
        </div>

        <div className=" absolute bottom-2 left-0 w-full px-6">
          <Link to={`/show-free-lesson?id=${e.id}&course=${id}`}>
            <div className=" bg-white shadow-md text-[#e51d74] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" flex items-center justify-between w-full">
                <h1 className=" mx-5 text-lg md:text-base lg:text-xl">
                  {" "}
                  الذهاب للدرس
                </h1>
                <i className="  mx-8 an text-[#e51d74] rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });

  if (!id) {
    return <Error />;
  } else {
    return (
      <div>
        {" "}
        {load && <Loader />}
        <div className="  bg-white shadow-md  top-0 left-0 w-full py-2 px-4">
          <div>
            <div className=" flex items-center justify-between">
              <section className=" text-lg ">
                <Link to="/">
                  <img
                    className="  w-28"
                    src="./imgs/logo.png"
                    alt="Logo BioGenius"
                  />
                </Link>
              </section>
              <section className="hidden md:flex">
                <Link to="/login">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
                <Link to="/signup">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
              <section className=" md:hidden">
                <button
                  onClick={() => setOpenDrop(!openDrop)}
                  className=" text-2xl p-4"
                >
                  {openDrop ? (
                    <i className="fa-solid fa-xmark"></i>
                  ) : (
                    <i className="fa-solid fa-bars"></i>
                  )}
                </button>
              </section>
            </div>
            <div
              className={` ${
                openDrop ? "h-44" : "h-0"
              } transition-all ease-in-out duration-500  text-center overflow-hidden`}
            >
              <section>
                <Link to="/login">
                  <button className=" my-6 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
              </section>
              <section>
                <Link to="/signup">
                  <button className=" my-4 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
            </div>
          </div>
        </div>
        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-center text-2xl m-2 font-bold text-[#3B3486]">
            محتويات الكورس
          </h1>
          <hr className=" m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        <div className=" text-center">
          <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {freeCourses.length === 0 ? (
              <div className=" w-full text-center col-span-1 md:col-span-2 lg:col-span-3">
                <h1 className=" text-center text-xl font-bold ">
                  لا توجد دروس قصيرة في هذا الكورس
                </h1>
              </div>
            ) : (
              // <></>
              <>{LessonsMaped}</>
            )}{" "}
          </div>
          <Link className="text-center" to={`/login`}>
            <button className=" py-2 px-10 bg-green-800 text-white text-xl rounded-md shadow-lg mb-6">
              تسجيل لعرض محتوي الكورس كامل
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
