import React, { useState } from "react";
import Loader from "../Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

export default function VodafonePay(props) {
  // 4625416 MIGS-online Online Card	EGP	Live
  // 4625415	UIG-online_new	Mobile Wallet	EGP	Live
  // 4619430	-	Online Card	EGP	Test

  // new
  // ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RnM05UTXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuSDR0RDZta2dscFBlb1lPTnhZeXc1S3RLZVpzeE1PTHVDLS1GeUcyVllqTGpjdy0yNVFUQUhjdTNGYWhoVjBpSFQ4dUJiYVdaNDZSYnphV2Q0RUZLVVE=

  // old
  // ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RRM016QXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuakZLaUhQSkVPT0lOMW9oblJianIyS2Q0UTAxbWVaZ01SM0pZMlNiOTBMOWdVTndTM3BaeTYxblZRY3VQOEJETlF3UDlBcWo5d0F6V25xdlZsNWkwQ0E=

  const [load, setLoad] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [phoneNum, setphoneNum] = useState();

  const name = Cookies.get("name");
  const phone = Cookies.get("phone");
  const mail = Cookies.get("mail");

  const payHandeller = () => {
    setLoad(true);
    let auth = "";
    let id = "";
    let token = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      api_key:
        "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RnM05UTXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuSDR0RDZta2dscFBlb1lPTnhZeXc1S3RLZVpzeE1PTHVDLS1GeUcyVllqTGpjdy0yNVFUQUhjdTNGYWhoVjBpSFQ4dUJiYVdaNDZSYnphV2Q0RUZLVVE=",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://accept.paymob.com/api/auth/tokens", requestOptions).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          res
            .json()
            .then((data) => {
              auth = data.token;
            })
            //Geting OrderId
            .then(() => {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              //   console.log(auth);
              var raw = JSON.stringify({
                auth_token: auth,
                delivery_needed: "false",
                amount_cents: 100 * props.price,
                currency: "EGP",
              });

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(
                "https://accept.paymob.com/api/ecommerce/orders",
                requestOptions
              ).then((res) => {
                if (res.status === 201 || res.status === 200) {
                  res
                    .json()
                    .then((data) => {
                      id = data.id;
                      window.localStorage.setItem("T3JkZXJfaWQ", data.id);
                      window.localStorage.setItem("name", props.name);
                      window.localStorage.setItem("end", props.end);
                      window.localStorage.setItem("start", props.start);
                      window.localStorage.setItem("year", props.year);
                      window.localStorage.setItem("term", props.term);
                      window.localStorage.setItem("id", props.id);
                      window.localStorage.setItem("price", props.price);
                      // window.localStorage.setItem("type", props.type);
                    })
                    .then(() => {
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        auth_token: auth,
                        amount_cents: 100 * +props.price,
                        expiration: 3600,
                        order_id: id,
                        billing_data: {
                          apartment: "803",
                          email: mail,
                          floor: "42",
                          first_name: name,
                          street: "Ethan Land",
                          building: "8028",
                          phone_number: phone,
                          shipping_method: "PKG",
                          postal_code: "11111",
                          city: "Test",
                          country: "Egypt",
                          last_name: name,
                          state: "Test",
                        },
                        currency: "EGP",
                        // integration_id: 4547849,
                        integration_id: 4625415,
                      });

                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };

                      fetch(
                        "https://accept.paymob.com/api/acceptance/payment_keys",
                        requestOptions
                      ).then((res) => {
                        if (res.status === 201 || res.status == 200) {
                          res
                            .json()
                            .then((data) => {
                              token = data.token;
                              // window.location.href = `https://accept.paymob.com/api/acceptance/iframes/809707?payment_token=${data.token}`;
                            })
                            .then(() => {
                              var myHeaders = new Headers();
                              myHeaders.append(
                                "Content-Type",
                                "application/json"
                              );
                              var raw = JSON.stringify({
                                source: {
                                  identifier: phoneNum,
                                  subtype: "WALLET",
                                },
                                payment_token: token,
                              });
                              var requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                body: raw,
                                redirect: "follow",
                              };

                              fetch(
                                "https://accept.paymob.com/api/acceptance/payments/pay",
                                requestOptions
                              ).then((res) => {
                                if (res.status === 201 || res.status == 200) {
                                  res.json().then((data) => {
                                    //   console.log(data.iframe_redirection_url)
                                    //   console.log(data.redirect_url)
                                    window.location.href =
                                      data.iframe_redirection_url;
                                  });
                                } else {
                                  setLoad(false);
                                  toast.error(" هناك مشكلة , اعد المحاولة", {
                                    position: "bottom-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                  });
                                }
                              });
                            });
                        } else {
                          setLoad(false);
                          toast.error(" هناك مشكلة , اعد المحاولة", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }
                      });
                    });
                } else {
                  setLoad(false);
                  toast.error(" هناك مشكلة , اعد المحاولة", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });
            });
        } else {
          setLoad(false);
          toast.error(" هناك مشكلة , اعد المحاولة", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    );
  };
  return (
    <div>
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <button
        onClick={() => setOpenPay(true)}
        className=" text-white rounded-md m-2 py-1 px-3 text-lg bg-[#ff212f] shadow-md "
      >
        <i className="fa-solid fa-mobile"></i> دفع بمحفظة
      </button>

      {openPay && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-[#ff212f] mx-4 md:mx-12 my-4">
              دفع عن طريق المحافظ الالكترونية
            </h1>
            <div className=" w-full flex justify-center">
              <input
                className=" border w-64 p-2 mx-auto outline-none shadow-md"
                placeholder="رقم المحفظة"
                value={phoneNum}
                onChange={(e) => setphoneNum(e.target.value)}
                type="text"
              ></input>
            </div>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={payHandeller}
                className=" bg-[#ff212f] text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                <i className="fa-solid fa-money-bill"></i> دفع
              </button>
              <button //Cancel Btn
                onClick={() => setOpenPay(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
