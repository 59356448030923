import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function MyCourses() {
  const [load, setLoad] = useState(false);
  const [packages, setPackages] = useState([]);
  const [previousBuy, setPrevious] = useState([]);

  // Feching data

  const token = Cookies.get("token");
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackages`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        console.log(response.status);
        response.json().then((data) => {
          setPackages(data);
        });
      } else {
        setLoad(false);
      }
    });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptionsforAlreadyBought = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/getAllPurchaseOnly",
      requestOptionsforAlreadyBought
    ).then((res) => {
      console.log("data");
      console.log(res.status);
      res.json().then((data) => {
        if (!!data) {
          setPrevious(data);
        }
        // console.log(data);
        setLoad(false);
      });
    });
  }, []);

  // Maping Packages

  const LessonsMaped = packages.map((e, index) => {
    let prevs = previousBuy;
    prevs = prevs.filter((el) => el.id == e.id);
    if (prevs.length > 0) {
      return (
        <div
          className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1"
          key={index}
        >
          <div>
            <img
              src={
                e.imageUrl
                  ? `https://biogeniusdotnet.biogeniuses.com${e.imageUrl}`
                  : "./imgs/1.avif"
              }
              className=" w-full h-48 rounded-md"
              alt={`course ${e.name}`}
            />
            <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
              {e.name}
            </h1>
            {/* <h4 className=" my-6 text-xl text-[#3B3486]/50">
                النوع: {e.isStudy ? "باقة شرح" : "باقة مراجعة"}
              </h4> */}
            {/* <h4 className=" my-6 text-xl text-[#3B3486]/50">الصف: {e.year}</h4>
            <h4 className=" my-6 text-xl text-[#3B3486]/50">الفصل: {e.term}</h4> */}
            {/* <h4 className=" my-6 text-xl text-center font-bold text-[#3B3486]">
              السعر: {e.price} جنيهاً
            </h4> */}
          </div>
          <div className="mb-2">
            <Link
              // to={`/lessons?id=${e.id}`}
              to={`/course?course=${e.id}`}
            >
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    عرض التفاصيل
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
  });
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}

      {/* Title */}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">اشتراكاتى</h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/* Packages */}
      <div className=" text-center">
        {previousBuy.length === 0 && (
          <>
            <h1 className=" text-center my-8 text-red-500 text-xl">
              لا توجد اشتراكات حتي الان{" "}
            </h1>
            <Link to={"/courses"}>
              <button className="my-4 text-lg text-white p-2 rounded-md bg-green-800 shadow-md ">
                الذهاب للكورسات
              </button>
            </Link>
          </>
        )}
      </div>

      <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <>{LessonsMaped}</>
      </div>
    </div>
  );
}
