import React, { useEffect, useState } from "react";
import Image from "./GetImageLink";
import ImageViaLink from "./ImageViaLink";

export default function ImagesForRev(props) {
  const [openImage, setOpenImage] = useState(false);
  const [index, setIndex] = useState(0);

  const [links, setLinks] = useState([]);

  const linkGetting = (link) => {
    let li = [...links];
    li.push(link);
    setLinks(li);
  };

  const Getting = props.images.map((e, index) => {
    // setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
  });

  const ImagesMaped = props.images.map((e, indx) => {
    return (
      <div
        key={indx}
        onClick={() => {
          setIndex(indx);
          setOpenImage(true);
        }}
        className=" cursor-pointer border rounded-md shadow-lg m-4 col-span-1 h-48 "
      >
        {/* <Image id={e.value} /> */}
        <ImageViaLink
          link={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
        />
      </div>
    );
  });

  // viewer

  function Viewer() {
    return <Image id={props.images[index].value} />;
  }

  return (
    <div className="mb-10  rounded-xl py-6 mx-6 md:mx-auto md:w-3/4">
      <hr />
      <h1 className=" text-2xl text-center text-[#9ed04e] font-bold my-10">
        الصور التوضيحية
      </h1>

      <div className=" grid grid-cols-1 md:grid-cols-3 ">{ImagesMaped}</div>
      {Getting}
      {/*  */}
      {/* Image */}
      {/* */}

      {openImage && (
        <div className=" z-30 fixed transition-all ease-in-out duration-200 w-full h-screen bg-black/50 top-0 left-0 ">
          <div className="  relative p-6 h-full">
            <button //Close Btn
              onClick={() => setOpenImage(false)}
              className=" absolute left-6  text-2xl text-red-600"
            >
              <i className="fa-solid fa-xmark"></i>
            </button>
            {/* previous */}
            <button //Previous
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                } else {
                  setIndex(props.images.length - 1);
                }
              }}
              className=" absolute text-2xl text-green-500 left-6 top-[50%]"
            >
              <i class="fa-solid fa-arrow-left"></i>
            </button>
            {/* image */}
            <div className=" flex items-center justify-center h-screen text-center">
              <img //Image
                className=" max-h-[90vh] my-auto mx-auto max-w-[85%]"
                alt="صورة توضيحية للدرس"
                src={
                  "https://biogeniusdotnet.biogeniuses.com/" +
                  props.images[index].fileUrl
                }
              />

              {/* {<Viewer />} */}
              <br></br>
            </div>

            {/* next */}
            <button //Next
              onClick={() => {
                if (index < props.images.length - 1) {
                  setIndex(index + 1);
                } else {
                  setIndex(0);
                }
              }}
              className="absolute text-2xl text-green-500 right-6 top-[50%]"
            >
              <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
