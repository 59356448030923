import React from "react";
import DNA from "../../components/Home/DNA";
import { Link } from "react-router-dom";

export default function Teacher() {
  return (
    <div>
      <img
        className=" lg:hidden mx-auto h-64 w-64 md:h-72 md:w-72 lg:w-96 lg:h-96 mb-12 shadow-md rounded-full border-4 border-black/20  "
        src="./imgs/teacher.jpg"
        alt="teacher"
      />
      <div className="  rtl p-4 flex items-center justify-between">
        <img
          className=" hidden lg:flex mx-auto h-48 w-48 md:h-72 md:w-72 lg:w-96 lg:h-96 mb-12 shadow-md rounded-full border-4 border-black/20  "
          src="./imgs/teacher.jpg"
          alt="teacher"
        />
        <div>
          <h1 className=" my-6 text-5xl sh mx-6 md:text-8xl  text-[#ff005f] font-bold">
            حاتم احمد جاد
          </h1>
          <h4 className=" mx-6 text-3xl">
            معلم خبير في تدريس الاحياء للثانوية العامة.
          </h4>
          <Link to={"/teacher-info"}>
            <button className="  hover:bg-white/0 hover:text-[#ff005f] transition-all ease-in-out duration-500 bg-[#ff005f] text-xl py-1 px-5 mx-6 my-6 rounded-md shadow-md text-white">
              {" "}
              معرفة المزيد
              <i class="fa-solid fa-arrow-right rotate-180 mr-6"></i>
            </button>
          </Link>
        </div>
        <div className=" ml-2  md:mx-auto">
          <DNA />
        </div>
      </div>
    </div>
  );
}
