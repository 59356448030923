import React, { useContext, useEffect, useState } from "react";
import "./in.css";
import { login } from "../../../utils/LoginStore";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
export default function LoginStudent() {
  const [load, setLoad] = useState(false);
  // login Checking
  const logged = useContext(login);
  const navigation = useNavigate();

  useEffect(() => {
    if (logged.isLogged) {
      navigation("/");
      // return;
    }
  }, []);

  //
  // Login
  //

  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(false);

  const closError = () => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  const loginHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var formdata = new FormData();
    formdata.append("password", password);
    formdata.append("email", mail);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/loginStudent",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        const thisTime = new Date().getTime();
        res.json().then((data) => {
          Cookies.set("token", data.access_token, { expires: 5 / 24 });
          Cookies.set("name", data.student.name, { expires: 5 / 24 });
          Cookies.set("id", data.student.id, { expires: 5 / 24 });
          Cookies.set("mail", data.student.email, { expires: 5 / 24 });
          Cookies.set("phone", data.student.phone, { expires: 5 / 24 });
          Cookies.set("dHlwZSBvZiBsb2c", "dGhlIHR5cGUgaXMgc3R1ZGVudA", {
            expires: 5 / 24,
          });
          Cookies.set("expirein", thisTime + 5 * 60 * 60 * 1000, {
            expires: 5 / 24,
          });
          logged.setIsLogged(true);
          logged.setType("student");
          navigation("/courses");
        });
      } else {
        setLoad(false);
        setError(true);
        closError();
      }
    });
  };
  if (logged.isLogged) {
    // navigation(-1);
    return;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}
        {/* Nav Bar */}
        <div className=" fixed right-6 bottom-6 text-green-500 py-3 px-4 opacity-75 z-50 hover:opacity-100 bg-gray-200 shadow-xl rounded-full">
          <a target="_blank" href="https://wa.me/201118028630">
            <i className="fa-brands fa-whatsapp text-2xl md:text-4xl"></i>
          </a>
        </div>

        <div className=" z-40 mb-20 md:mb-4 p-4 md:p-8">
          <Link to={"/"}>
            <img
              className=" w-24 md:w-32"
              src="./imgs/logo.png"
              alt="Logo Bio Genuis"
            />{" "}
          </Link>
        </div>

        {/* Login Form  */}
        <div className=" z-10  h-screen flex items-center justify-center absolute top-0 w-full">
          <div className="  relative  flex items-center justify-center">
            <div className=" bg-white  flex items-center justify-center text-[#00aab3] text-5xl absolute top-[-3rem] h-24 w-24 border-2 shadow-md p-4 rounded-full  ">
              <i className="fa-solid fa-user "></i>
            </div>
            <div className=" pt-20 p-8 shadow-xl rounded-xl border">
              <h1 className=" text-center text-xl px-12 md:px-24 text-[#07a9b3]">
                تسجيل الدخول
              </h1>
              <hr className=" my-6" />

              <div className=" rtl">
                <form onSubmit={loginHandeller}>
                  <div className=" my-4 flex items-center border bg-slate-50 rounded-md ">
                    <i className=" mx-2 text-sky-800/50 fa-solid fa-at"></i>
                    <input //Email
                      className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                      value={mail}
                      required
                      onChange={(e) => setMail(e.target.value)}
                      placeholder="البريد الالكتروني"
                      type="email"
                    />
                  </div>
                  <div className=" my-4 flex items-center border bg-slate-50 rounded-md ">
                    <i className=" mx-2 text-sky-800/50 fa-solid fa-lock"></i>
                    <input //password
                      className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                      value={password}
                      required
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="كلمة السر"
                      type="password"
                    />
                  </div>
                  {/* Forget */}
                  {/* <Link className=" text-sky-800" to="/resetPassword">
                    هل نسيت كلمة السر ؟
                  </Link> */}
                  {/* Error */}
                  {error && (
                    <div className=" opacity-70 bg-red-600 p-2 text-white rounded-md">
                      <i className=" ml-2 fa-solid fa-circle-exclamation"></i>
                      كلمة السر او البريد الالكتروني خطأ{" "}
                    </div>
                  )}

                  <div
                    className={` text-center ${error ? "mt-5" : "mt-12"} mb-2`}
                  >
                    <button //Login BTN
                      className=" disabled:opacity-80 py-2 px-4 bg-[#219b2b] text-white rounded-md "
                    >
                      تسجيل دخول{" "}
                      <i className=" fa-solid fa-right-to-bracket"></i>
                    </button>
                  </div>
                </form>
              </div>

              <hr className=" my-6" />
              <div className=" text-sky-900 flex rtl text-center py-2 ">
                <h1>ليس لديك حساب؟ </h1>{" "}
                <Link className=" font-bold" to={"/signup"}>
                  انشاء حساب
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
