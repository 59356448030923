import React, { createContext, useState } from "react";

export const conect = createContext();
export default function ChatStore(props) {
    const [connection , setConnectin] = useState()
  return (
    <>
      <conect.Provider value={{ connection , setConnectin }}>
        {props.children}
      </conect.Provider>
    </>
  );
}
