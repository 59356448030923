import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

export default function TeacherSettings() {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);
  const token = Cookies.get("token");
  const id = Cookies.get("id");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  // Change Mail
  const [mail, setMail] = useState();

  const updateMailHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var raw = JSON.stringify({
      fullName: "string",
      username: "string",
      email: mail,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Auth/EditProfile",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        toast.success("تم تغيير البيانات بنجاح", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error("هناك مشكلة  تغيير البيانات", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // Reset Password
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmatio] = useState("");

  const resetPasswordHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var raw = JSON.stringify({
      id: id,
      password: password,
      confirmPassword: passwordConfirmation,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Auth/ResetPassword",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        setErr(false);
        toast.success("تم تغيير البيانات بنجاح", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        setErr(true);
        setTimeout(() => {
          setErr(false);
        }, 7000);
      }
    });
  };
  return (
    <div className=" p-4">
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className=" p-8 md:w-2/3 mx-auto shadow-lg rounded-xl my-12 ">
        <h1 className=" text-center text-xl m-2 font-bold text-[#3B3486]">
          تغيير اعدادات الحساب
        </h1>
        <hr className=" w-1/2 mx-auto my-6"></hr>
        {/*  */}
        {/* Change Mail Section */}
        {/*  */}
        <div className=" flex items-end justify-center">
          <form onSubmit={updateMailHandeller}>
            <input //Mail Input
              required
              className=" text-center w-72 outline-none border-b p-2 text-lg"
              placeholder="البريد الالكتروني الجديد"
              type="email"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
            />

            <div className=" text-center">
              <button //update BTN
                disabled={!mail}
                type="submit"
                //   onClick={}
                className=" disabled:opacity-80 my-1 mx-4 bg-sky-800 rounded-full py-[2p] px-8 text-lg text-white font-sans"
              >
                تغيير{" "}
              </button>
            </div>
          </form>
        </div>
        {/*  */}
        {/* Reset Password Section */}
        {/*  */}
        <div>
          <form
            onSubmit={resetPasswordHandeller}
            className=" w-72 mx-auto my-12"
          >
            <input //password
              required
              className=" my-4 text-center w-72 outline-none border-b p-2 text-lg"
              placeholder="كلمة السر الجديدة"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <input //confirm password
              required
              className=" my-4 text-center w-72 outline-none border-b p-2 text-lg"
              placeholder="كلمة السر الجديدة"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmatio(e.target.value)}
              type="password"
            />
            <div className=" text-center">
              {" "}
              <button //Reset Password BTN
                disabled={
                  !password ||
                  !passwordConfirmation ||
                  password != passwordConfirmation
                }
                className=" mx-auto disabled:opacity-80 text-center bg-sky-800 rounded-full py-[2p] px-8 text-lg text-white font-sans"
                type="submit"
              >
                تغيير كلمة السر
              </button>
            </div>
          </form>
        </div>
        {err && (
          <div //Err MSG
            className=" flex items-center bg-red-600 opacity-60 px-6 py-2 rtl rounded-xl text-white w-96 mx-auto"
          >
            <i className="fa-solid fa-circle-exclamation text-xl ml-2"></i>
            <p>
              {" "}
              كلمة السر يجب ان تحتوي علي احرف كبيرة وصغيرة وارقام واحروف مميزة
              وان لا تقل عن 8 حروف
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
