import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

export default function ExamsList() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const editCourse = Cookies.get("EditCourse") === "true";

  const search = useLocation().search;
  const packagesId = new URLSearchParams(search).get("id");

  // Exams
  const [exams, setExams] = useState([]);

  // Fetching
  const auth = btoa("11162387:60-dayfreetrial");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Basic ${auth}`);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Exams/GetExamsByUnit/${packagesId}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setExams(data);
          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, [refresh]);

  // Delete
  const [openDelte, setOpenDelte] = useState(false);
  const [DelteId, setDelteId] = useState();

  const openDeleteUnitHandeller = (id) => {
    setOpenDelte(true);
    setDelteId(id);
  };

  const deleteUnitHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${auth}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Exams/Delete/${DelteId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDelte(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  //    Maping

  const exmasOfMaping = exams.map((e, index) => {
    return (
      <tr key={index} className=" p-2 grid grid-cols-8 md:grid-cols-12">
        <td className="text-start text-lg text-[#3B3486] font-bold col-span-7 md:col-span-11">
          {e.title}
        </td>
        {/* <td className=" col-span-1 text-center">
          <Link to={`/editExam/${e.id}`}>
            <i className="fa-solid text-lg text-[#3B3486] fa-file-pen"></i>
          </Link>
        </td> */}
        {editCourse && (
          <td className=" col-span-1 text-lg font-bold text-red-600 text-center">
            <button onClick={() => openDeleteUnitHandeller(e.id)}>
              {" "}
              <i className="fa-solid fa-trash"></i>
            </button>
          </td>
        )}
      </tr>
    );
  });
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {/* Header */}
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">الامتحانات </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      {editCourse && (
        <div className=" flex justify-between w-full p-4">
          <Link to={`/addexam?id=${packagesId}`}>
            <button className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400">
              امتحان جديد <i className="fa-solid fa-file-circle-plus"></i>
            </button>
          </Link>
        </div>
      )}

      {/* Table of Exams */}
      <div className=" p-4">
        <table className=" rtl w-full my-4">
          <thead>
            <tr className="bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
              <th className="text-start col-span-7 md:col-span-11">الامتحان</th>
              {/* <th className=" col-span-1">تعديل</th> */}
              {editCourse && <th className=" col-span-1">حذف</th>}
            </tr>
          </thead>
          <tbody>{exmasOfMaping}</tbody>
        </table>
      </div>

      {openDelte && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-12 my-4 md:mx-24">
              حذف هذا الامتحان
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpenDelte(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
