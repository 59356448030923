import React, { useState } from "react";
import Loader from "../Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

export default function PayBtn(props) {
  // 4625416 MIGS-online Online Card	EGP	Live
  // 4625415	UIG-online_new	Mobile Wallet	EGP	Live
  // 4619430	-	Online Card	EGP	Test

  // new
  // ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RnM05UTXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuSDR0RDZta2dscFBlb1lPTnhZeXc1S3RLZVpzeE1PTHVDLS1GeUcyVllqTGpjdy0yNVFUQUhjdTNGYWhoVjBpSFQ4dUJiYVdaNDZSYnphV2Q0RUZLVVE=

  // old
  // ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RRM016QXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuakZLaUhQSkVPT0lOMW9oblJianIyS2Q0UTAxbWVaZ01SM0pZMlNiOTBMOWdVTndTM3BaeTYxblZRY3VQOEJETlF3UDlBcWo5d0F6V25xdlZsNWkwQ0E=

  const [load, setLoad] = useState(false);
  // getAuth Token
  const [authToken, setAuthToken] = useState("");

  const name = Cookies.get("name");
  const phone = Cookies.get("phone");
  const mail = Cookies.get("mail");

  // Getting all data

  const payHandeller = async () => {
    setLoad(true);
    let auth = "";
    let id = "";
    let token = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      api_key:
        "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2ljSEp2Wm1sc1pWOXdheUk2T1RnM05UTXpMQ0p1WVcxbElqb2lhVzVwZEdsaGJDSjkuSDR0RDZta2dscFBlb1lPTnhZeXc1S3RLZVpzeE1PTHVDLS1GeUcyVllqTGpjdy0yNVFUQUhjdTNGYWhoVjBpSFQ4dUJiYVdaNDZSYnphV2Q0RUZLVVE=",
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    // getting Auth Token
    fetch("https://accept.paymob.com/api/auth/tokens", requestOptions).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          res
            .json()
            .then((data) => {
              auth = data.token;
            })
            //Geting OrderId
            .then(() => {
              var myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              //   console.log(auth);
              var raw = JSON.stringify({
                auth_token: auth,
                delivery_needed: "false",
                amount_cents: 100 * props.price,
                currency: "EGP",
              });

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(
                "https://accept.paymob.com/api/ecommerce/orders",
                requestOptions
              ).then((res) => {
                if (res.status === 201 || res.status === 200) {
                  res
                    .json()
                    .then((data) => {
                      id = data.id;
                      window.localStorage.setItem("T3JkZXJfaWQ", data.id);
                      window.localStorage.setItem("name", props.name);
                      window.localStorage.setItem("end", props.end);
                      window.localStorage.setItem("start", props.start);
                      window.localStorage.setItem("year", props.year);
                      window.localStorage.setItem("term", props.term);
                      window.localStorage.setItem("price", props.price);
                      window.localStorage.setItem("id", props.id);
                      // window.localStorage.setItem("type", props.type);
                    })
                    .then(() => {
                      var myHeaders = new Headers();
                      myHeaders.append("Content-Type", "application/json");

                      var raw = JSON.stringify({
                        auth_token: auth,
                        amount_cents: 100 * +props.price,
                        expiration: 3600,
                        order_id: id,
                        billing_data: {
                          apartment: "803",
                          email: mail,
                          floor: "42",
                          first_name: name,
                          street: "Ethan Land",
                          building: "8028",
                          phone_number: phone,
                          shipping_method: "PKG",
                          postal_code: "11111",
                          city: "Test",
                          country: "Egypt",
                          last_name: name,
                          state: "Test",
                        },
                        currency: "EGP",
                        // integration_id: 4418115,
                        integration_id: 4625416,
                      });

                      var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                      };

                      fetch(
                        "https://accept.paymob.com/api/acceptance/payment_keys",
                        requestOptions
                      ).then((res) => {
                        if (res.status === 201 || res.status == 200) {
                          res.json().then((data) => {
                            // console.log(data.token);
                            // window.location.href = `https://accept.paymob.com/api/acceptance/iframes/809707?payment_token=${data.token}`;
                            window.location.href = `https://accept.paymob.com/api/acceptance/iframes/858706?payment_token=${data.token}`;
                          });
                        } else {
                          setLoad(false);
                          toast.error(" هناك مشكلة , اعد المحاولة", {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                        }
                      });
                    });
                } else {
                  console.log('')
                  setLoad(false);
                  toast.error(" هناك مشكلة , اعد المحاولة", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });
            });
        } else {
          setLoad(false);
          toast.error(" هناك مشكلة , اعد المحاولة", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    );
  };

  return (
    <div>
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* {authToken} */}
      <button
        onClick={payHandeller}
        className=" text-white rounded-md m-2 py-1 px-3 text-lg bg-[#3B3486] shadow-md "
      >
        <i className="fa-solid fa-money-bill"></i> دفع بالفيزا
      </button>
    </div>
  );
}
