import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./main.css";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

export default function LessonsList() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const editCourse = Cookies.get("EditCourse") === "true";

  const search = useLocation().search;
  const unitId = new URLSearchParams(search).get("id");

  //
  // Fetching Lessons
  //
  const [lessons, setLessons] = useState([]);
  const [lessonsForSeach, setLessonsForSeacrch] = useState([]);

  const auth = btoa("11162387:60-dayfreetrial");

  useEffect(() => {
    setLoad(true);
    var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Basic ${auth}`); // add auth
    var requestOptions = {
      // define Vars
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Units/Details/${unitId}`,
      requestOptions
    ) // Fetch data
      .then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            // console.log(res.lessons)
            // res.map((e) => {
            //   e.lessons.map((les) => {
            //     less.push(les);
            //   });
            // });
            setLessons(res.lessons);
            // setLessons(res);
            setLessonsForSeacrch(res.lessons);
            // setLessonsForSeacrch(res);
            setLoad(false);
          });
        } else {
          setLoad(false);
        }
      })
      .then()
      .catch((error) => console.log("error", error));
  }, [refresh]);

  //
  // Filtering
  //
  const [year, setYear] = useState(0); // year
  const [semster, setSemster] = useState(0); //semeter

  //
  // Maping lessons
  //

  // Filtering

  useEffect(() => {
    var search = [...lessonsForSeach];
    if (year !== 0) {
      search = search.filter((e) => e.year == year);
      // console.log(search)
    }
    if (semster !== 0) {
      search = search.filter((e) => e.term == semster);
      // console.log(search)
    }
    setLessons(search);
  }, [year, semster, lessonsForSeach]);

  //
  // Delete
  //

  const [openDelete, setOpenDelete] = useState(false);
  const [deletedElement, setDeletedElement] = useState("");

  const openDeleteHandeller = (id) => {
    setOpenDelete(true);
    setDeletedElement(id);
  };

  const delteHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${auth}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Lessons/Delete/${deletedElement}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDelete(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // maping
  const LessonsOfMaping = lessons.map((e, index) => {
    return (
      <tr className="p-2 grid grid-cols-8 md:grid-cols-12" key={index}>
        <td className=" underline text-lg font-bold text-[#3B3486] text-start col-span-6 md:col-span-10">
          <Link to={`/viewLesson?id=${e.id}`}>{e.title}</Link>
        </td>
        {editCourse && (
          <>
            <td className=" text-center text-lg font-bold text-[#3B3486] col-span-1">
              <Link to={`/editlesson?id=${e.id}`}>
                <i className="fa-solid fa-file-pen"></i>
              </Link>
            </td>
            <td className=" text-center text-lg font-bold text-red-600 col-span-1">
              <button onClick={() => openDeleteHandeller(e.id)}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </td>
          </>
        )}
      </tr>
    );
  });

  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">جميع الدروس</h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      {/*  */}
      {/* Lessons filter */}
      {/*  */}

      <div className=" flex items-center justify-between p-4">
        {editCourse && (
          <div className="">
            <button className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400">
              {" "}
              {/* New Lesson */}
              <Link to={`/addlesson?id=${unitId}`}>
                درس جديد <i className="fa-solid fa-file-circle-plus"></i>
              </Link>
            </button>
          </div>
        )}
        <div className=" w-52 md:w-auto rtl">
          {" "}
          {/* Filters*/}{" "}
          {/* <select //Year
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              if (e.target.value == 3) {
                setSemster(0);
              }
            }}
            className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الصف
            </option>
            <option value={1}>الاول الثانوي</option>
            <option value={2}>الثاني الثانوي</option>
            <option value={3}>الثالث الثانوي</option>
          </select> */}
        </div>
      </div>
      {/*  */}
      {/* Lessons */}
      {/*  */}
      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
              <th className=" text-start col-span-6 md:col-span-10">الدرس</th>
              {editCourse && (
                <>
                  <th className=" col-span-1">تعديل </th>
                  <th className=" col-span-1">حذف</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>{LessonsOfMaping}</tbody>
        </table>
        {lessons.length === 0 && (
          <h1 className=" text-center text-xl text-red-500  my-24">
            لا توجد دروس في هذه الوحدة
          </h1>
        )}
      </div>

      {openDelete && (
        <div // Delete Lesson Section
          className=" bg-black/30 h-screen w-full fixed top-0 left-0 flex items-center justify-center"
        >
          <div className=" coin p-8 bg-white rounded-lg">
            <h1 className=" text-xl text-red-600 text-bold mx-20">
              حذف هذا الدرس
            </h1>
            <div className="  text-center rtl mt-10">
              <button
                onClick={() => setOpenDelete(false)}
                className=" bg-sky-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                الغاء
              </button>
              <button
                onClick={delteHandeller}
                className=" bg-red-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
