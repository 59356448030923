import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import "./unit.css";
import Add from "./Add";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Edit from "./Edit";
import Cookies from "js-cookie";

export default function UnitsList() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const search = useLocation().search;
  const packagesId = new URLSearchParams(search).get("id");
  const matraial = new URLSearchParams(search).get("matrail");
  const editCourse = Cookies.get("EditCourse") === "true";

  const refreshHandeller = () => {
    setRefresh(!refresh);
  };
  //
  // filtering
  //

  const [year, setYear] = useState(1); //year
  const [semster, setSemster] = useState(1); //term

  //
  // getting Units
  //

  const [units, setUnits] = useState([]);

  const auth = btoa("11162387:60-dayfreetrial");
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Basic ${auth}`);
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Units/GetAllUnits/${packagesId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setUnits(data);
            setLoad(false);
          });
        } else {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  }, [year, semster, refresh]);

  //
  // Delete
  //
  const [openDelte, setOpenDelte] = useState(false);
  const [DelteId, setDelteId] = useState();

  const openDeleteUnitHandeller = (id) => {
    setOpenDelte(true);
    setDelteId(id);
  };

  const deleteUnitHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${auth}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Units/Delete/${DelteId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDelte(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  //
  // Add New Unit
  //

  const [openAdd, setOpenAdd] = useState(false);

  const openAddUnitHanedller = () => {
    setOpenAdd(true);
  };
  const closeAddUnitHanedller = () => {
    setOpenAdd(false);
  };

  const [editId, setEditId] = useState("");
  const closeEdit = () => {
    setEditId("");
  };
  // Maping
  const unitesMaped = units.map((e, index) => {
    return (
      <tr className="p-2 grid grid-cols-8 md:grid-cols-12" key={index}>
        <td
          className={` underline text-lg font-bold text-[#3B3486] text-start ${
            editCourse
              ? "col-span-5 md:col-span-9"
              : "col-span-7 md:col-span-11"
          }`}
        >
          <Link to={`/lessons?id=${e.id}`}> {e.title}</Link>
        </td>

        <td className=" text-center text-lg font-bold text-[#3B3486] col-span-1">
          <Link to={`/all-exams?id=${e.id}`}>
            <i className="fa-solid fa-eye"></i>
          </Link>
        </td>
        {editCourse && (
          <>
            <td className=" text-center text-lg font-bold text-[#3B3486] col-span-1">
              <button onClick={() => setEditId(e.id)}>
                <i className="fa-solid fa-file-pen"></i>
              </button>
            </td>
            <td className=" text-center text-lg font-bold text-red-600 col-span-1">
              <button onClick={() => openDeleteUnitHandeller(e.id)}>
                <i className="fa-solid fa-trash"></i>
              </button>
            </td>
          </>
        )}
      </tr>
    );
  });
  return (
    <>
      <div>
        {/* Lading */}
        {load && <Loader />}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">الوحدات </h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {/*  */}
        {/* unit filter */}
        {/*  */}

        <div className=" flex items-center justify-between p-4">
          {editCourse && (
            <div className="">
              <button //Add
                onClick={openAddUnitHanedller}
                className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400"
              >
                {" "}
                {/* New Unit */}
                وحدة جديدة <i className="fa-solid fa-file-circle-plus"></i>
              </button>
            </div>
          )}
          <div className=" w-52 md:w-auto rtl">
            {" "}
            {/* Filters*/}{" "}
            {/* <select //Year
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                الصف
              </option>
              <option value={1}>الاول الثانوي</option>
              <option value={2}>الثاني الثانوي</option>
              <option value={3}>الثالث الثانوي</option>
            </select> */}
          </div>
        </div>
        {/*  */}
        {/* Units */}
        {/*  */}
        <div className=" p-4">
          <table className=" rtl w-full shadow-sm rounded-md">
            <thead>
              <tr className=" bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
                <th
                  className={` text-start ${
                    editCourse
                      ? "col-span-5 md:col-span-9"
                      : "col-span-7 md:col-span-11"
                  }`}
                >
                  الوحدة
                </th>
                <th className=" col-span-1">الامتحانات</th>
                {editCourse && (
                  <>
                    <th className=" col-span-1">تعديل</th>
                    <th className=" col-span-1">حذف</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>{unitesMaped}</tbody>
          </table>
          {units.length === 0 && (
            <h1 className=" text-center text-xl text-red-500  my-24">
              لا توجد وحدات في هذا الكورس
            </h1>
          )}
        </div>
        {/*  */}
        {/* Delete sec */}
        {/*  */}
        {openDelte && (
          <div // Delte Cont
            className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
          >
            <div className=" coin bg-white p-8 rounded-lg">
              <h1 className=" text-bold text-xl text-red-600 mx-12 my-4 md:mx-24">
                حذف هذه الوحدة
              </h1>
              <div className="  text-center mt-8">
                <button //Delte Btn
                  onClick={deleteUnitHandeller}
                  className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
                >
                  حذف
                </button>
                <button //Cancel Btn
                  onClick={() => setOpenDelte(false)}
                  className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
                >
                  الغاء
                </button>
              </div>
            </div>
          </div>
        )}
        {openAdd && (
          <Add
            matrail={matraial}
            packagesId={packagesId}
            refresh={refreshHandeller}
            cancel={closeAddUnitHanedller}
          />
        )}
        {editId && (
          <Edit unitId={editId} refresh={refreshHandeller} cancel={closeEdit} />
        )}
      </div>
    </>
  );
}
