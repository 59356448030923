import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditExams() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const rev = new URLSearchParams(search).get("rev");
  // Getting Exam

  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState({});
  const [year, setYear] = useState(0);
  const [semeter, setSemster] = useState(0);
  const [userid, setId] = useState(0);
  const [newExam, setNewExam] = useState([]);
  const [newExamIds, setNewExamIds] = useState([]);

  useEffect(() => {
    //Exams Getting
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    //Deatils
    const deatails = () => {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Reviews/RevisionDetails/${rev}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setExams(data.revisionExams);
          });
        } else {
          setLoad(false);
          setError(true);
        }
      });
    };
    deatails();
  }, []);

  useEffect(() => {
    let ex = [...exams];
    ex = ex.filter((e) => e.id == id);
    if (ex.length === 0) {
      setError(true);
    } else {
      setError(false);
      setExam(ex[0]);
      //   console.log(ex[0].revisionQuestions[0].revisionQuestion);
      setYear(ex[0].year);
      setSemster(ex[0].term);
      setId(ex[0].revisionId);
      // setNewExam(ex[0].revisionQuestions);
      let arr = [];
      let arrIds = [];
      ex[0].revisionQuestions.map((e, index) => {
        arrIds.push(e.revisionQuestion.id);
        arr.push(e.revisionQuestion);
        setNewExam(arr);
        setNewExamIds(arrIds);
      });
    }
  }, [exams]);

  // Delete
  const [openExe, setOpenExe] = useState(false);
  const navigate = useNavigate();

  const delteExeHadeller = () => {
    setLoad(true);

    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/DeleteExam/${id}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenExe(false);
        // setRefresh(!refresh);
        navigate("/all-revisions");
        toast.success("تم الحذف ", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // Exam
  // delete question
  const deleteHandeller = (inx) => {
    let an = [...newExam];
    let anId = [...newExamIds];

    an.splice(inx, 1);
    anId.splice(inx, 1);

    setNewExam(an);
    setNewExamIds(anId);
  };

  //new Question
  const newQuestionHandeller = () => {
    setNewExam([
      ...newExam,
      {
        questionContent: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correctAnswer: "0",
        questionScore: 1000,
        year: +year,
        term: +semeter,
        lessonId: +userid,
        isExerciseQuestion: false,
      },
    ]);
  };
  // maping questions

  // console.log(newExam[0])

  const questionsOfNewExam = newExam.map((e, index) => {
    //  console.log(e.question);
    return (
      <div key={index} className=" border-b p-2 my-2 ">
        <div className=" flex items-center justify-between">
          <h1 className=" text-lg py-2 px-4">{index + 1} .</h1>
          <button
            onClick={() => deleteHandeller(index)}
            className=" px-4 opacity-50 hover:opacity-80 text-red-600 underline"
          >
            حذف <i className="fa-solid fa-trash"></i>
          </button>
        </div>

        <input
          required
          type="text"
          //set values of Question
          onChange={(el) => {
            let ques = [...newExam];
            let quest = ques[index];
            ques[index] = {
              ...quest,
              questionContent: el.target.value,
            };
            setNewExam(ques);
          }}
          value={newExam[index].questionContent}
          className=" outline-none text-lg text-slate-500 border focus:border-sky-500 transition-all duration-300 font-bold bg-gray-100 w-full rounded-md p-3"
          placeholder="السؤال"
        ></input>

        <hr className="my-2 w-[50%] mx-auto opacity-50"></hr>

        <div className=" my-3">
          {/* Choose 1 */}
          <div className=" relative">
            <button //choose as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  correctAnswer: quest.option1,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option1 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option1}
              // set choose 1
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  option1: el.target.value,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الاول"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option1 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 2 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  correctAnswer: quest.option2,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option2 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option2}
              // set choose 2
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  option2: el.target.value,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الثاني"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option2 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 3 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  correctAnswer: quest.option3,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option3 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الثالث"
              // set choose 3
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  option3: el.target.value,
                };
                setNewExam(ques);
              }}
              value={newExam[index].option3}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option3 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 4 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  correctAnswer: quest.option4,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option4 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الرابع"
              value={newExam[index].option4}
              // set choose 4
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  ...quest,
                  option4: el.target.value,
                };
                setNewExam(ques);
              }}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option4 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
        </div>
      </div>
    );
  });

  // Send Edits
  // Send edits

  const sendHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: +id,
      title: exam.title,
      description: "string",
      year: +year,
      term: year == 3 ? null : +semeter,
      revisionId: +userid,
      selectedQuestionIds: [],
      questionsList: newExam,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Reviews/EditExam",
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        setLoad(false);
        toast.success("تم التعديل ", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/all-revisions");
      } else {
        setLoad(false);
        console.log(response);
        console.log("err");

        if (response.status !== 500) {
          response.json().then((err) => {
            toast.error(err.errorMessage, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        }
      }
    });
  };

  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {error ? (
        <div className=" text-center flex items-center justify-center h-screen">
          <h1 className="text-2xl font-bold">لايوجد هذا التدريب</h1>
        </div>
      ) : (
        <div>
          <div className=" my-4 flex items-center justify-center">
            <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
            <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
              {" "}
              {exam.title}
            </h1>
            <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          </div>
          {/* Delete */}
          <div className=" text-center my-8">
            <button
              onClick={() => setOpenExe(true)}
              className=" bg-red-600 font-bold text-white rounded-md shadow-md py-2 px-6"
            >
              <i className="fa-solid fa-trash mx-1"></i>
              حذف الاختبار{" "}
            </button>
          </div>
          {/*  */}
          {/* Question */}
          {/*  */}
          <div className=" rtl">
            {newExam.length === 0 && (
              <h1 className=" text-lg text-center m-4 ">لا يوجد اسئلة</h1>
            )}

            {questionsOfNewExam}

            <div className="text-center mt-12">
              {/* <button //New Question Btn
                onClick={newQuestionHandeller}
                className=" text-lg py-2 px-4 my-12 text-white shadow-md bg-green-600 rounded-md text-center mx-2"
              >
                اضافة سؤال
              </button> */}
              <button // Send Questions Btn
                onClick={sendHandeller}
                disabled={newExam.length === 0}
                className=" disabled:cursor-not-allowed disabled:opacity-40 text-lg py-2 px-4 my-12 text-white shadow-md bg-sky-600 rounded-md text-center mx-2"
              >
                تعديل
              </button>
            </div>
          </div>
        </div>
      )}
      {openExe && (
        <div // Delete Lesson Section
          className=" bg-black/30 h-screen w-full fixed top-0 left-0 flex items-center justify-center"
        >
          <div className=" coin p-8 bg-white rounded-lg">
            <h1 className=" text-xl text-red-600 text-bold mx-20">
              حذف هذا الاختبار
            </h1>
            <div className="  text-center rtl mt-10">
              <button
                onClick={() => setOpenExe(false)}
                className=" bg-sky-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                الغاء
              </button>
              <button
                onClick={delteExeHadeller}
                className=" bg-red-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
