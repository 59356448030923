import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./layout.css";
import { login } from "../../utils/LoginStore";
import Error from "../Error/Error";
import Cookies from "js-cookie";

import { conect } from "../../utils/ChatStore";
import { Avatar, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";

export default function TeacherLayout({ children }) {
  const [openList, setOpenList] = useState(false);
  // login Checking
  const logged = useContext(login);
  const type = useContext(login).type;
  const setLogged = useContext(login).setIsLogged;
  const setType = useContext(login).setType;
  const navigation = useNavigate();

  const li = useLocation().pathname;

  useEffect(() => {
    if (!logged.isLogged) {
      navigation("/teacher-login");
      // return;
    }
  }, []);

  const logOutHandeller = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("dHlwZSBvZiBsb2c");
    Cookies.remove("expirein");
    Cookies.remove("assestent");
    Cookies.remove("Money");
    Cookies.remove("EditCourse");
    Cookies.remove("DeleteCourse");
    Cookies.remove("Report");
    Cookies.remove("Chat");
    setLogged(false);
    setType("");
    navigation("/teacher-login");
  };

  const thisTime = new Date().getTime();
  const exin = Cookies.get("expirein");
  const OutTime = +exin - thisTime;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Access

  const asse = Cookies.get("assestent") === "true";
  const chat = Cookies.get("Chat") === "true";
  const report = Cookies.get("Report") === "true";

  if (!logged.isLogged) {
    return;
  } else if (type === "student") {
    return <Error />;
  } else if (type === "teacher") {
    setTimeout(logOutHandeller, OutTime);
    return (
      <div>
        <div className=" flex items-center justify-between p-4 shadow-md mb-2">
          <div>
            <Link to={"/packages"}>
              <img
                className=" w-20"
                src="./imgs/logo.png"
                alt="Logo BioGenius"
              />
            </Link>
          </div>
          <div className=" rtl hidden xl:flex">
            <Link
              className={` ${
                li === "/packages" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/packages"
            >
              الكورسات
            </Link>
            <Link
              className={` ${
                li === "/matrail" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/matrail"
            >
              المواد
            </Link>
            {/* <Link
              className={` ${
                li === "/lessons" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/lessons"
            >
              {" "}
              الدروس{" "}
            </Link> */}
            {/* <Link
              className={` ${
                li === "/units" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/units"
            >
              الوحدات{" "}
            </Link> */}
            {/* <Link
              className={` ${
                li === "/all-revisions" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/all-revisions"
            >
              المراجعات{" "}
            </Link> */}
            {/* <Link
              className={` ${
                li === "/free" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/free"
            >
              المحتوي المجاني{" "}
            </Link> */}
            {/* <Link
              className={` ${
                li === "/all-exams" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/all-exams"
            >
              الامتحانات
            </Link> */}

            <Link
              className={` ${
                li === "/intro" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/intro"
            >
              الفيديوهات التعريفية
            </Link>
            {chat && (
              <Link
                className={` ${
                  li === "/list-chats" && "bg-black/10"
                }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
                to="/list-chats"
              >
                المحادثات
              </Link>
            )}
            {report && (
              <Link
                className={` ${
                  li === "/reports" && "bg-black/10"
                }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
                to="/reports"
              >
                الطلاب
              </Link>
            )}
            {report && (
              <Link
                className={` ${
                  li === "/reports-courses" && "bg-black/10"
                }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
                to="/reports-courses"
              >
                تقارير الكورسات
              </Link>
            )}
            {asse && (
              <Link
                className={` ${
                  li === "/assestens" && "bg-black/10"
                }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
                to="/assestens"
              >
                المساعدين
              </Link>
            )}
          </div>
          <div className="hidden px-10 xl:flex ">
            <button
              onClick={handleClick}
              className=" w-14 h-14 text-[#00a3aa] rounded-full flex items-center justify-center  shadow-lg hover:bg-gray-300 text-2xl  bg-gray-200 "
            >
              <i className="fa-solid fa-user"></i>
            </button>
          </div>
          <div className=" xl:hidden  ">
            <button // open List Btn
              onClick={() => setOpenList(!openList)}
              className=" text-2xl text-center text-sky-900"
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        {openList && (
          <div
            onClick={() => setOpenList(!openList)}
            className=" fixed top-0 left-0 w-full h-screen "
          ></div>
        )}

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Link to={"/edit"}>
              <div className=" text-lg flex items-center justify-between font-sans">
                <h1 className=" mx-2">اعدادات الحساب</h1>
                <i className="fa-solid fa-gear"></i>
              </div>
            </Link>
          </MenuItem>
          <Divider />
          <div className=" flex justify-center">
            <button //LogOut BTN
              onClick={logOutHandeller}
              className=" mx-auto shadow-lg py-2 px-4 text-white text-md bg-red-700 cursor-pointer rounded-lg"
            >
              تسجيل خروج <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </Menu>

        <div
          className={` fixed z-50 top-0 right-0 transition-all ease-in-out duration-[1500ms] ${
            openList ? "mr-[0]" : "mr-[-100%]"
          }  p-4 w-80 md:w-96  rtl bg-white h-screen shadow-2xl text-sky-900`}
        >
          <div // Side Nave Bar
            className=" overflow-y-auto text-center "
          >
            <button className="text-2xl" onClick={() => setOpenList(!openList)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <hr className=" w-[70%] mx-auto my-8" />
            <div>
              {/* <div className=" mx-auto w-14 h-14 text-[#00a3aa] rounded-full flex items-center justify-center  shadow-lg hover:bg-gray-300 text-2xl  bg-gray-200 ">
                <i className="fa-solid fa-user"></i>
              </div> */}
              <Link to={"/edit"}>
                <div
                  onClick={() => setOpenList(false)}
                  className=" w-fit mx-auto mt-6 text-lg flex items-center justify-between font-sans"
                >
                  <h1 className=" mx-2">اعدادات الحساب</h1>
                  <i className="fa-solid fa-gear"></i>
                </div>
              </Link>
            </div>
            <hr className=" w-[70%] mx-auto my-8" />
            <div>
              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/packages"
                  >
                    {" "}
                    الكورسات{" "}
                  </Link>
                </button>
              </div>
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/lessons"
                  >
                    {" "}
                    الدروس{" "}
                  </Link>
                </button>
              </div> */}
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/units"
                  >
                    {" "}
                    الوحدات{" "}
                  </Link>
                </button>
              </div> */}
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/all-revisions"
                  >
                    {" "}
                    المراجعات{" "}
                  </Link>
                </button>
              </div> */}
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/free"
                  >
                    {" "}
                    المحتوي المجاني{" "}
                  </Link>
                </button>
              </div> */}
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/all-exams"
                  >
                    {" "}
                    الامتحانات{" "}
                  </Link>
                </button>
              </div> */}
              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/matrail"
                  >
                    {" "}
                    المواد{" "}
                  </Link>
                </button>
              </div>

              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/intro"
                  >
                    {" "}
                    الفيديوهات التعريفية{" "}
                  </Link>
                </button>
              </div>
              {chat && (
                <div className=" my-6">
                  <button onClick={() => setOpenList(false)}>
                    <Link
                      className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                      to="/list-chats"
                    >
                      {" "}
                      المحادثات{" "}
                    </Link>
                  </button>
                </div>
              )}
              {report && (
                <div className=" my-6">
                  <button onClick={() => setOpenList(false)}>
                    <Link
                      className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                      to="/reports"
                    >
                      {" "}
                      الطلاب{" "}
                    </Link>
                  </button>
                </div>
              )}
              {report && (
                <div className=" my-6">
                  <button onClick={() => setOpenList(false)}>
                    <Link
                      className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                      to="/reports-courses"
                    >
                      تقارير الكورسات
                    </Link>
                  </button>
                </div>
              )}
              {asse && (
                <div className=" my-6">
                  <button onClick={() => setOpenList(false)}>
                    <Link
                      className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                      to="/assestens"
                    >
                      {" "}
                      المساعدين{" "}
                    </Link>
                  </button>
                </div>
              )}
            </div>
            <hr className=" w-[70%] mx-auto my-8" />
            <div>
              <button //log out Btn
                onClick={logOutHandeller}
                className=" shadow-lg py-2 px-4 text-white bg-red-700 cursor-pointer rounded-lg"
              >
                تسجيل خروج <i className="fa-solid fa-right-from-bracket"></i>
              </button>
            </div>
          </div>
        </div>

        <div>{children}</div>
      </div>
    );
  }
}
