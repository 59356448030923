import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SendedMessage from "../../../components/chat/SendedMessage";
import RecivedMessage from "../../../components/chat/RecivedMessage";
import "./chat.css";
import Loader from "../../../components/Loader/Loader";
import Error from "../../Error/Error";
import * as signalr from "@microsoft/signalr";
import {
  hubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import ChatListComp from "./ChatListComponent";
import Cookies from "js-cookie";

export default function Chat() {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);

  const search = useLocation().search;
  const loca = useLocation().pathname;

  const id = new URLSearchParams(search).get("id");

  // refresh List of Users
  const [refresh, setRefresh] = useState(false);

  const fet = () => {
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/GetAllChatBetweenTwoUsers/30a9511b-4a0a-423c-ac14-0d3874f85598/${id}`
    ).then((res) => {
      if (res.status == 200) {
        res.json().then((data) => {
          setMessages(data);
          setLoad(false);
          if (data.length > 0) {
            // let id = data[data.length - 1].id;
            // // console.log(id);
            // var requestOptions = {
            //   method: "POST",
            //   redirect: "follow",
            // };
            // fetch(
            //   `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/ChangeReadingMessage/${id}`,
            //   requestOptions
            // );
          }
        });
      } else {
        setErr(true);
      }
    });
  };

  // start Connection with chat
  const conn = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    // .configureLogging(LogLevel.Information)
    .withUrl("https://biogeniusdotnet.biogeniuses.com/chatmessagehub", {
      skipNegotiation: true,
      transport: signalr.HttpTransportType.WebSockets,
    })
    .build();

  const addConnectionWithoutController = async (connectionId, userId) => {
    try {
      const dto = {
        connectionId: connectionId,
        userId: userId,
      };
      await conn.invoke("AddConnection", dto);
      // console.log("Connection added successfully.");
    } catch (error) {
      // console.error("Error adding connection:", error);
    }
  };

  const joinChatRoom = async () => {
    try {
      await conn.start().then(() => {});

      const id = await conn.invoke("GetConnectionId");
      console.log(id.value.value);
      await addConnectionWithoutController(
        id.value.value,
        "30a9511b-4a0a-423c-ac14-0d3874f85598"
      );

      conn.on("ReceiveMessage", (message) => {
        // console.log(`Received message : ${message} `);
        if (loca == "/chat") {
          fet();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Join

  useEffect(() => {
    joinChatRoom();
    // setConn(conn);
  }, []);

  //
  //
  // Getting Messages
  //
  //
  const scrollContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  const report = Cookies.get("Report") === "true";

  // Getting Messages
  const [student, setStudent] = useState("");

  useEffect(() => {
    setLoad(true);
    fet();
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/auth/AllStudent",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        // setLoad(false);
        res.json().then((data) => {
          let students = data;
          students = students.filter((e) => e.id == id);
          if (students.length > 0) {
            setStudent(students[0].name);
            // console.log(students[0].name);
          } else {
            setErr(true);
          }
        });
      }
    });
  }, []);

  // maping Messages

  const messageList = messages.map((e, index) => {
    return (
      <div key={index}>
        {e.senderId == id ? (
          <>
            <RecivedMessage student={true} message={e.message} />
          </>
        ) : (
          <>
            <SendedMessage message={e.message} />
          </>
        )}
      </div>
    );
  });

  // Message
  const [message, setMessage] = useState("");

  //
  //
  // Send Message
  //
  //

  const sendHandeller = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      senderId: "30a9511b-4a0a-423c-ac14-0d3874f85598",
      receiverId: id,
      message: message,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/SendMessage",
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let id = data.id;

          var requestOptions = {
            method: "POST",
            redirect: "follow",
          };
          fetch(
            `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/ChangeReadingMessage/${id}`,
            requestOptions
          );
        });
      }
    });

    setMessages([
      ...messages,
      {
        senderId: "30a9511b-4a0a-423c-ac14-0d3874f85598",
        receiverId: id,
        message: message,
      },
    ]);
    // fet()
    setMessage("");
  };

  useEffect(() => {
    if (messages.length > 0) {
      let id = messages[messages.length - 1].id;
      // console.log(id);

      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/ChangeReadingMessage/${id}`,
        requestOptions
      );
    }
  }, [messages]);

  if (!id || err) {
    return <Error />;
  } else {
    return (
      <div className="  h-screen pt-24 mt-[-6.25rem] flex ">
        {/* Lading */}
        {load && <Loader />}
        {/*  */}
        {/* List of Users */}
        {/*  */}
        {/* <div className=" hidden  mt-[-4px] md:flex w-1/4 border-r-2 ">
          <div className=" h-full overflow-y-auto border w-full">
            <ChatListComp refresh={refresh} />
          </div>
        </div> */}
        {/*  */}
        {/* Message Section */}
        <div className=" relative w-full border ">
          {/* Name Tag */}
          <section className=" absolute top-0 w-full mt-[-6px] rtl bg-gray-100 flex items-center justify-between p-3 border-b shadow-lg text-xl font-bold text-sky-700 ">
            {/* <div className=" bg-gray-400 h-8 w-8 rounded-full my-2 flex items-center justify-center text-sm text-sky-800">
            <i className="fa-solid fa-graduation-cap"></i>
          </div> */}
            {report ? (
              <Link to={`/student?id=${id}`}>{student}</Link>
            ) : (
              <h1>{student}</h1>
            )}
            <Link to="/list-chats">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </section>
          {/* Messages */}
          <section className=" sc  pb-20 pt-12 h-full">
            <div
              ref={scrollContainerRef}
              className="  overflow-y-scroll px-2 h-full"
            >
              {messageList}
            </div>{" "}
          </section>
          {/* Send field */}
          <section className=" absolute bottom-0 w-full px-2  my-1 ">
            <div className=" bg-gray-200 w-full rounded-xl p-3 ">
              <form onSubmit={sendHandeller} className=" flex items-center">
                <input //Message text place
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="اكتب الرسالة"
                  className=" rtl bg-white/0 border-b border-gray-300 focus:border-sky-800 text-lg p-2 outline-none w-full"
                ></input>
                <button //Send Button
                  disabled={!message}
                  className=" mx-2 text-sky-800 text-2xl"
                >
                  <i className="fa-solid fa-paper-plane"></i>
                </button>
              </form>{" "}
            </div>
          </section>
        </div>
      </div>
    );
  }
}
