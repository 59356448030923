import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";

export default function ShowCourse() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [load, setLoad] = useState(false);
  const [course, setCourse] = useState({ name: "" });
  const [freeCourses, setFreeCourse] = useState([]);
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackageById?id=${id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          //   setPackages(data);
          setCourse(data);
          //   setPrice(data.price);
          //   setLoad(false);
        });
      } else {
        // setLoad(false);
        // setErr(true);
      }
    });

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${id}`
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        res.json().then((data) => {
          setFreeCourse(data);
          // console.log(data)
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  const LessonsMaped = freeCourses.map((e, index) => {
    return (
      <div
        className=" relative shadow-md rtl p-6 mx-4 md:mx-6 my-2 rounded-3xl bg-[#fdebf3] col-span-1"
        key={index}
      >
        <div className=" overflow-  rounded-full flex h-8 w-8 md:h-10 md:w-10 justify-center relative text-white  bg-[#e51d74]">
          <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-6px]">
            {index + 1}
          </h1>
        </div>
        <hr className=" w-[70%] mx-auto  my-4 border-black/20"></hr>
        <div className=" mb-20">
          <h1 className=" text-[#e51d74] text-2xl my-4 font-bold text-center">
            {e.title}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#e51d74]/50">{e.unit.title}</h4> */}
        </div>

        <div className=" absolute bottom-2 left-0 w-full px-6">
          <Link to={`/free-lesson?id=${e.id}&course=${id}`}>
            <div className=" bg-white shadow-md text-[#e51d74] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" flex items-center justify-between w-full">
                <h1 className=" mx-5 text-lg md:text-base lg:text-xl">
                  {" "}
                  الذهاب للدرس
                </h1>
                <i className="  mx-8 an text-[#e51d74] rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <div>
      {load && <Loader />}
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          {course.name}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[hsl(245,44%,36%)] rounded-full" />
      </div>
      <h1 className=" text-lg font-bold text-center">الدروس القصيرة</h1>
      <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {freeCourses.length === 0 ? (
          <div className=" w-full text-center col-span-1 md:col-span-2 lg:col-span-3">
            <h1 className=" text-center text-xl font-bold ">
              لا توجد دروس قصيرة في هذا الكورس
            </h1>
          </div>
        ) : (
          // <></>
          <>{LessonsMaped}</>
        )}{" "}
      </div>

      <div className=" text-center">
        <Link className="text-center" to={`/checkout?id=${id}`}>
          <button className=" py-2 px-10 bg-green-800 text-white text-xl rounded-md shadow-lg mb-6">
            شراء لعرض محتوي الكورس كامل
          </button>
        </Link>
      </div>
    </div>
  );
}
