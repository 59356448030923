import React from "react";
import "./dna.scss";

export default function DNA() {
  const Orbits = () => {
    return (
      <div>
        {Array.from({ length: 30 }, (_, i) => (
          <div key={i} className="orbit" style={{ "--i": i }}>
            <div className="ball"></div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className=" bo">
      <div className="">
        <div>
          <div className="dna-loader-3d" style={{ "--count": 30 }}>
            <Orbits />
          </div>
        </div>
      </div>
    </div>
  );
}
