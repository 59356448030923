import React, { useState } from "react";

export default function Video(props) {
  const [file, setFile] = useState();
  return (
    <div>
      <div className=" rtl font-bold text-2xl  text-[#45407e] shadow-md p-8   flex items-center justify-center mx-auto border rounded-lg">
        {" "}
        {/* Upload Video */}
        {file ? (
          <div className=" w-full relative">
            <button //Delete Btn
              onClick={() => {
                setFile();
                props.add();
              }}
              className=" text-center text-red-500/70 hover:text-red-500 cursor-pointer text-lg "
            >
              <i className="fa-solid fa-trash"></i>
            </button>{" "}
            {/*  Video */}
            {file.type.substring(0, 5) === "video" ? (
              <div className=" w-full">
                <video
                  className=" w-full h-60  border  rounded-md shadow-md mx-auto"
                  controls
                  src={URL.createObjectURL(file)}
                />
              </div>
            ) : (
              <div>
                <img
                  alt="show "
                  src={URL.createObjectURL(file)}
                  className=" w-full h-60  border  rounded-md shadow-md mx-auto"
                />
              </div>
            )}
          </div>
        ) : (
          <section>
            <label className=" po cursor-pointer h-full w-full" htmlFor="file">
              اضافة الملف <i className="fa-solid fa-cloud-arrow-up"></i>
            </label>

            <input
              required
              type="file"
              id="file"
              onChange={(e) => {
                setFile(e.target.files[0]);
                props.add(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
          </section>
        )}
      </div>
    </div>
  );
}
