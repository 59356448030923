import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import Cookies from "js-cookie";

export default function ResetPassword() {
  const [load, setLoad] = useState(false);
  const token = Cookies.get("token");

  // Reset Password
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmatio] = useState("");

  const resetPasswordHandeller = (e) => {
    e.preventDefault();
    setLoad(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("password", password);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/ressetPassword",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        toast.success("تم تغيير البيانات بنجاح", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error(" هناك مشكلة !  ", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className=" p-8 md:w-2/3 mx-auto shadow-lg rounded-xl my-12 ">
        <h1 className=" text-center text-xl m-2 font-bold text-[#3B3486]">
          تغيير اعدادات الحساب
        </h1>
        <hr className=" w-1/2 mx-auto my-6"></hr>

        {/*  */}
        {/* Reset Password Section */}
        {/*  */}
        <div>
          <form
            onSubmit={resetPasswordHandeller}
            className=" w-72 mx-auto mb-12"
          >
            <input //password
              required
              className=" my-4 text-center w-72 outline-none border-b p-2 text-lg"
              placeholder="كلمة السر الجديدة"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
            <input //confirm password
              required
              className=" my-4 text-center w-72 outline-none border-b p-2 text-lg"
              placeholder="كلمة السر الجديدة"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmatio(e.target.value)}
              type="password"
            />
            <div className=" text-center">
              {" "}
              <button //Reset Password BTN
                disabled={
                  !password ||
                  !passwordConfirmation ||
                  password != passwordConfirmation
                }
                className=" mx-auto disabled:opacity-80 text-center bg-sky-800 rounded-full py-[2p] px-8 text-lg text-white font-sans"
                type="submit"
              >
                تغيير كلمة السر
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
