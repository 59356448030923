import React, { useState } from "react";
import { Link } from "react-router-dom";
import Sub from "./Sub";
import DNA from "../../components/Home/DNA";

export default function US() {
  const [openDrop, setOpenDrop] = useState(false);
  return (
    <>
      <div className="  bg-white shadow-md  top-0 left-0 w-full py-2 px-4">
        <div>
          <div className=" flex items-center justify-between">
            <section className=" text-lg ">
              <Link to="/">
                <img
                  className="  w-28"
                  src="./imgs/logo.png"
                  alt="Logo BioGenius"
                />
              </Link>
            </section>
            <section className="hidden md:flex">
              <Link to="/login">
                <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                  تسجيل الدخول <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </Link>
              <Link to="/signup">
                <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                  انشاء حساب <i className="fa-solid fa-user-plus"></i>
                </button>
              </Link>
            </section>
            <section className=" md:hidden">
              <button
                onClick={() => setOpenDrop(!openDrop)}
                className=" text-2xl p-4"
              >
                {openDrop ? (
                  <i className="fa-solid fa-xmark"></i>
                ) : (
                  <i className="fa-solid fa-bars"></i>
                )}
              </button>
            </section>
          </div>
          <div
            className={` ${
              openDrop ? "h-44" : "h-0"
            } transition-all ease-in-out duration-500  text-center overflow-hidden`}
          >
            <section>
              <Link to="/login">
                <button className=" my-6 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                  تسجيل الدخول <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </Link>
            </section>
            <section>
              <Link to="/signup">
                <button className=" my-4 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                  انشاء حساب <i className="fa-solid fa-user-plus"></i>
                </button>
              </Link>
            </section>
          </div>
        </div>
      </div>
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">من نحن </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <div className="  rtl p-4 flex items-center justify-between">
        <div className=" w-full md:w-2/3">
          <div className="  p-8 shadow-md rounded-lg w-full bg-[#ff006c]">
            <h1 className=" lg:w-[80%] my-6 text-4xl sh text-white font-bold">
              مرحبا بايو جينيس
            </h1>
            <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
              {" "}
              منصة تعليمية متخصصة ف المادة بشكل سهل وممتع، وذلك من خلال
            </h1>
            <h1 className=" px-3 lg:w-[80%] text-xl text-white font-semibold my-3">
              * شرح شامل ومبسط للمفاهيم الاساسية لمادة الاحياء
            </h1>
            <h1 className=" px-3 lg:w-[80%] text-xl text-white font-semibold my-3">
              * مراجعة شاملة لمنهج الثانوية العامة
            </h1>
            <h1 className=" px-3 lg:w-[80%] text-xl text-white font-semibold my-3">
              * تقديم واجبات وتدريبات متنوعة
            </h1>
            <h1 className=" px-3 lg:w-[80%] text-xl text-white font-semibold my-3">
              * توفير محتوي تعليمي غني ومتنوع يشمل :
            </h1>
            <h1 className=" px-6 lg:w-[80%] text-xl text-white font-semibold my-3">
              فيديوهات تعليمية - ملخصات ونصوص دراسية - رسومات توضيحية. -اختبارات
              تفاعلية.
            </h1>
            <h1 className=" px-3 lg:w-[80%] text-xl text-white font-semibold my-3">
              * امكانية التواصل مع الاستاذ حاتم جاد للاجابة علي الاسئلة وتقديم
              الدعم
            </h1>
          </div>
        </div>
        <div className=" hidden md:flex items-center  py-6 ml-2  md:mx-auto">
          <DNA />
        </div>
      </div>
      <h1 className=" rtl my-6 text-5xl sh mx-6 md:text-8xl  text-[#ff005f] font-bold">
        الاستاذ حاتم جاد
      </h1>
      <h1 className=" rtl my-6 text-xl mx-6 md:text-3xl  text-[#ff005f] font-bold">
        معلم خبير في مادة الاحياء للثانوية العامة لاكثر من 20 عام
      </h1>
      <h1 className=" rtl my-6 text-مل mx-6 md:text-xl  text-[black] font-bold">
        يُعرف استاذ حاتم جاد باسلوبه المميز في الشرح وخبرته الواسعة في مجال
        تدريس مادة الاحياء وقدرته على توصيل المعلومات الي الطلاب بسهولة ووضوح ,
        كما انه يحرص علي ربط مادة الاحياء بالحياة اليومية مما يجعلها أكثر متعة
        وفائدة للطلاب
      </h1>
      <div className=" mt-24 mb-4 flex items-center justify-center">
        <hr className="  m-2 w-12 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-center text-xl m-2 font-bold text-[#3B3486]">
          {" "}
          لماذا تختار منصة بايو جينيس
        </h1>
        <hr className=" m-2 w-12 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <h1 className=" rtl my-6 text-xl mx-6 md:text-3xl  text-[#ff005f] font-bold">
        محتوى تعليمي غني ومتنوع يناسب جميع المستويات الطلاب
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * أسلوب رشح سهل وممتع يجعل التعلم أكثر فاعلية
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * امكانية التواصل مع معلم خبير للاجابة علي الاسئلة وتقديم الدعم
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * منصة تعليمية معتمدة ومتوافقة مع منهج الثانوية العامة
      </h1>
      <h1 className=" rtl my-6 text-xl mx-6 md:text-3xl  text-[#ff005f] font-bold">
        مع منصة "بايو جينيس" ستتمكن من :
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * فهم مادة األحياء بشكل سهل وممتع
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * تحسين درجاتك في امتحانات الثانوية العامة
      </h1>
      <h1 className=" rtl px-20  text-xl  font-semibold my-3">
        * اكتساب مهارات علمية حيوية ستفيدك في حياتك الجامعية و المهنية
      </h1>
      <h1 className=" rtl mt-24 mb-6 text-center text-3xl  mx-6  text-[#ff005f] font-bold">
        انضم إلينا الان و ابدأ رحلة التعلم الممتعة في عالم الاحياء
      </h1>

      <Sub />
    </>
  );
}
