import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Error from "../Error/Error";
import Loader from "../../components/Loader/Loader";

export default function AllCourses() {
  const [load, setLoad] = useState(false);
  const [packages, setPackages] = useState([]);
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("year");
  const [openDrop, setOpenDrop] = useState(false);
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackagesByDateRange`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let packs = data.filter((pa) => pa.year == id);
          setPackages(packs);
          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  const LessonsMaped = packages.map((e, index) => {
    return (
      <div
        className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1"
        key={index}
      >
        {+e.discount > 0 && (
          <section className=" absolute top-0 h-[30px] flex items-center justify-center px-3 text-[15px] bg-[#3B3486]/70 text-white ">
            خصم
          </section>
        )}
        <div>
          <img
            src={
              e.imageUrl
                ? `https://biogeniusdotnet.biogeniuses.com${e.imageUrl}`
                : "./imgs/1.avif"
            }
            className=" w-full h-48 rounded-md"
            alt={`course ${e.name}`}
          />
          <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
            {e.name}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#3B3486]/50">
          النوع: {e.isStudy ? "باقة شرح" : "باقة مراجعة"}
        </h4> */}
          <h4 className=" my-6 text-xl text-[#3B3486]/50">الصف: {e.year}</h4>
          {e.term && (
            <h4 className=" my-6 text-xl text-[#3B3486]/50">الفصل: {e.term}</h4>
          )}
          <h4 className=" my-6 text-xl text-[#3B3486]/50">
            المادة: {e.materialName}
          </h4>
          <h4 className=" flex  justify-center my-6 text-xl text-center font-bold text-[#3B3486]">
            السعر:{" "}
            {+e.discount > 0 && (
              <section className="relative mx-2 text-[#3B3486]/80  w-fit">
                {e.price}
                <hr className=" mt-[-15px] rotate-[150deg]  h-[1px] bg-[red]/60 border-[red]/60 "></hr>{" "}
              </section>
            )}{" "}
            {+e.price - +e.discount} جنيهاً
          </h4>
        </div>
        <div className="mb-2">
          <Link
            // to={`/lessons?id=${e.id}`}
            to={`/showcoursedetails?id=${e.id}`}
          >
            <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" flex items-center justify-between w-full">
                <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                  {" "}
                  المحتوي
                </h1>
                <i className=" mx-8 an fa-solid fa-chevron-left"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });

  if (id != "1" && id != "2" && id != "3") {
    return <Error />;
  } else {
    return (
      <div>
        {load && <Loader />}
        <div className="  bg-white shadow-md  top-0 left-0 w-full py-2 px-4">
          <div>
            <div className=" flex items-center justify-between">
              <section className=" text-lg ">
                <Link to="/">
                  <img
                    className="  w-28"
                    src="./imgs/logo.png"
                    alt="Logo BioGenius"
                  />
                </Link>
              </section>
              <section className="hidden md:flex">
                <Link to="/login">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
                <Link to="/signup">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
              <section className=" md:hidden">
                <button
                  onClick={() => setOpenDrop(!openDrop)}
                  className=" text-2xl p-4"
                >
                  {openDrop ? (
                    <i className="fa-solid fa-xmark"></i>
                  ) : (
                    <i className="fa-solid fa-bars"></i>
                  )}
                </button>
              </section>
            </div>
            <div
              className={` ${
                openDrop ? "h-44" : "h-0"
              } transition-all ease-in-out duration-500  text-center overflow-hidden`}
            >
              <section>
                <Link to="/login">
                  <button className=" my-6 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
              </section>
              <section>
                <Link to="/signup">
                  <button className=" my-4 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
            </div>
          </div>
        </div>
        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-center text-2xl m-2 font-bold text-[#3B3486]">
            كورسات الصف {id == "1" && "الاول"} {id == "2" && "الثاني"}{" "}
            {id == "3" && "الثالث"}
          </h1>
          <hr className=" m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        <div className=" rtl  py-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {LessonsMaped}
        </div>
        {packages.length === 0 && (
          <h1 className=" text-center text-2xl text-sky-800 font-bold">
            لا توجد كورسات حتي الان تابع الموقع لكل جديد
          </h1>
        )}
      </div>
    );
  }
}
