import React, { useEffect, useState } from "react";
import "./add.css";
import Video from "./Video";
import VideoShow from "../../../components/Lesson/Video";
import Gallery from "./Gallery";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Images from "../../../components/Lesson/Images";
import ImgesEdit from "./ImgesEdit";
import VideoViaLink from "../../../components/Lesson/VideoViaLink";

export default function EditRe() {
  const search = useLocation().search;
  const lessonid = new URLSearchParams(search).get("id");
  const [id, setId] = useState(lessonid);

  const [refresh, setRefresh] = useState(false);

  const [error, setError] = useState(false);
  //Over all [progress and loading]
  const [progress, setProgress] = useState(1);
  const [load, setLoad] = useState(false);
  const auth = btoa("11162387:60-dayfreetrial");

  //
  // Headers of Lesson
  //
  const [title, setTitle] = useState(""); //title
  const [year, setYear] = useState(0); //Year
  const [semster, setSemster] = useState(1); //semster
  const [unit, setUnit] = useState(0); //semster

  const [gettingVideos, setGettingVides] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    //Deatils
    const deatails = () => {
      setLoad(true);
      setFiles([]);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Reviews/RevisionDetails/${lessonid}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setTitle(data.title);
            setYear(data.year);
            setFiles(data.reviewFiles);
            setExcercieses(data.revisionExams);
            if (!!data.term) {
              setSemster(data.term);
            } else {
              setSemster(1);
            }
            // setUnit(data.unitId);
          });
        } else {
          setLoad(false);
          setError(true);
        }
      });
    };

    setGettingVides([]);
    deatails();
  }, [refresh]);

  //
  // Details
  //

  const editBasicHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      // id: +id,
      title: title,
      description: "string",
      year: +year,
      term: year == 3 ? null : +semster,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/EditRevision/${id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        setLoad(false);
        toast.success("تم التعديل", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        console.log(response);
        console.log("err");

        if (response.status !== 500) {
          response.json().then((err) => {
            toast.error(err.errorMessage, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        }
      }
    });
  };
  //   delete Video

  const [openDeleteVideo, setOpenDeleteVideo] = useState(false);
  const [deletedVideoId, setDeletedVideoId] = useState();

  const delteVideoHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/DeleteReviewFile/${deletedVideoId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDeleteVideo(false);
        setRefresh(!refresh);
        toast.success("تم حذف الفيديو", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // edit Videos
  // const GettedvideosMaped = gettingVideos.map((e, index) => {
  //   return (
  //     <div className=" realtive">
  //       <button
  //         onClick={() => {
  //           setOpenDeleteVideo(true);
  //           setDeletedVideoId(e.value);
  //         }}
  //         className=" text-red-600"
  //       >
  //         <i className="fa-solid fa-trash"></i>
  //       </button>
  //       <VideoShow key={index} id={e.value} />
  //     </div>
  //   );
  // });
  const GettedvideosMaped = files.map((e, index) => {
    if (e.type == 2) {
      return;
    } else {
      return (
        <div className=" realtive">
          <button
            onClick={() => {
              setOpenDeleteVideo(true);
              setDeletedVideoId(e.id);
            }}
            className=" text-red-600"
          >
            <i className="fa-solid fa-trash"></i>
          </button>
          <VideoViaLink
            key={index}
            desc={e.description}
            link={"https://biogeniusdotnet.biogeniuses.com" + e.fileUrl}
          />
        </div>
      );
    }
  });

  // Video Uploading

  const [video, setVideo] = useState();
  const addVid = (el) => {
    setVideo(el);
  };
  const ShowVid = React.useMemo(() => <Video add={addVid} />, []);
  const [desc, setDesc] = useState(""); // Details

  const [videos, setVideos] = useState([]);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const [loadUpload, setLoadUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const mapVideos = videos.map((e, index) => {
    return (
      <div className=" rtl border p-4 my-4 grid grid-cols-6 " key={index}>
        <video
          className=" col-span-2 border h-72 w-72  rounded-md shadow-md mx-auto"
          controls
          src={URL.createObjectURL(e.file)}
        ></video>
        <p className=" col-span-4 text-lg p-8 text-sky-800 font-bold">
          {e.desc}
        </p>
      </div>
    );
  });

  const sendVideoHandeller = () => {
    // setLoad(true);
    setLoadUpload(true);

    let num = Math.random();

    var formdata = new FormData();
    formdata.append("File", video);
    formdata.append("Type", 1);
    formdata.append("RevisionId", +id);
    formdata.append("Description", desc);
    formdata.append("Year", +year);
    formdata.append("Term", +semster);
    formdata.append(
      "Title",
      ` فيديو شرح ${title} رقم ${videos.length} ${id} ${num}  `
    );

    axios({
      method: "post",
      url: "https://biogeniusdotnet.biogeniuses.com/api/Reviews/CreateReviewFile",
      headers: { Authorization: "Basic " + auth },
      data: formdata,
      onUploadProgress: (p) => {
        setUploadProgress((p.loaded / p.total) * 100);
      },
    })
      .then((response) => {
        setLoad(false);
        if (response.status === 200) {
          setLoadUpload(false);
          let vi = [...videos];
          vi.push({
            file: video,
            desc: desc,
          });
          setVideos(vi);
          setDesc("");
          setVideo();
          setOpenAddVideo(false);
        } else {
          setLoadUpload(false);
          toast.error("هناك مشكلة في رفع الفيديو", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        // console.log(error)
        setLoadUpload(false);
        toast.error("هناك مشكلة في رفع الفيديو", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return Promise.reject(error);
      });
  };

  // Image Uploading

  const [image, setImage] = useState();
  const addImg = (el) => {
    setImage(el);
  };

  const ShowGallery = React.useMemo(() => <Gallery add={addImg} />, []);
  useEffect(() => {
    // Add Images
    if (image) {
      setLoad(true);
      let num = Math.random();
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Basic ${auth}`);
      var formdata = new FormData();
      formdata.append("File", image);
      formdata.append("Type", 2);
      formdata.append("RevisionId", +id);
      formdata.append("Description", desc);
      formdata.append("Year", +year);
      formdata.append("Term", +semster);
      formdata.append(
        "Title",
        ` فيديو شرح ${title} رقم ${videos.length} ${id} ${num}  `
      );
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      fetch(
        "https://biogeniusdotnet.biogeniuses.com/api/Reviews/CreateReviewFile",
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
          });
        } else {
          setLoad(false);
          response.json().then((err) => {
            // console.log(err.errorMessage)
            toast.error(err.errorMessage, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        }
      });
    }
  }, [image]);

  //
  // Eaxams
  //

  const [exams, setExams] = useState([]); //Array of All Exams
  const [open, setOpen] = useState(false); //Add Exam Boolen
  const [newExam, setNewExam] = useState([]); //New Exam Question
  const [examTitle, setExamTitle] = useState(""); //New Exam Title

  //Maping Exams
  const sendedExams = exams.map((e, index) => {
    return (
      <tr key={index} className="  border">
        <td className=" text-start p-2">{e.title}</td>
        <td className=" text-end p-2">{e.num} </td>
      </tr>
    );
  });

  // Add Exam
  const addExamHandeller = () => {
    //Add Exam Handelller
    setNewExam([]);
    setExamTitle("");
    setOpen(true);
  };

  //new Question
  const newQuestionHandeller = () => {
    setNewExam([
      ...newExam,
      {
        questionContent: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correctAnswer: "0",
        questionScore: 1000,
        year: +year,
        term: +semster,
        lessonId: +id,
        isExerciseQuestion: true,
      },
    ]);
  };

  // delete question
  const deleteHandeller = (inx) => {
    let an = [...newExam];

    an.splice(inx, 1);

    setNewExam(an);
  };

  //Maping Questions
  const questionsOfNewExam = newExam.map((e, index) => {
    return (
      <div key={index} className=" border-b p-2 my-2 ">
        <div className=" flex items-center justify-between">
          <h1 className=" text-lg py-2 px-4">{index + 1} .</h1>
          <button
            onClick={() => deleteHandeller(index)}
            className=" px-4 opacity-50 hover:opacity-80 text-red-600 underline"
          >
            حذف <i className="fa-solid fa-trash"></i>
          </button>
        </div>

        <input
          required
          type="text"
          //set values of Question
          onChange={(el) => {
            let ques = [...newExam];
            let quest = ques[index];
            ques[index] = {
              questionContent: el.target.value,
              option1: quest.option1,
              option2: quest.option2,
              option3: quest.option3,
              option4: quest.option4,
              correctAnswer: quest.correctAnswer,
              questionScore: 1000,
              year: +year,
              term: +semster,
              lessonId: +id,
              isExerciseQuestion: true,
            };
            setNewExam(ques);
          }}
          value={newExam[index].questionContent}
          className=" outline-none text-lg text-slate-500 border focus:border-sky-500 transition-all duration-300 font-bold bg-gray-100 w-full rounded-md p-3"
          placeholder="السؤال"
        ></input>

        <hr className="my-2 w-[50%] mx-auto opacity-50"></hr>

        <div className=" my-3">
          {/* Choose 1 */}
          <div className=" relative">
            <button //choose as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option1,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option1 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option1}
              // set choose 1
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: el.target.value,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الاول"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option1 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 2 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option2,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option2 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option2}
              // set choose 2
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: el.target.value,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الثاني"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option2 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 3 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option3,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option3 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الثالث"
              // set choose 3
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: el.target.value,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              value={newExam[index].option3}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option3 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 4 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option4,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option4 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الرابع"
              value={newExam[index].option4}
              // set choose 4
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: el.target.value,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: +semster,
                  lessonId: +id,
                  isExerciseQuestion: true,
                };
                setNewExam(ques);
              }}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option4 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
        </div>
      </div>
    );
  });

  // Send Exam
  const senExamHandeller = () => {
    let ex = [...exams];
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${auth}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      title: examTitle,
      description: "string",
      year: +year,
      term: year == 3 ? null : +semster,
      revisionId: +id,
      selectedQuestionIds: [],
      questionsList: newExam,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Reviews/CreateExamWithQuestions",
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          ex.push({
            title: examTitle,
            num: newExam.length,
          });
          setExams(ex);
          setOpen(false);
          setLoad(false);
        });
      } else {
        setLoad(false);

        response.json().then((err) => {
          console.log(err.errorMessage);
          console.log(err);
          toast.error(err.errorMessage, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      }
    });
  };

  // Exercise View

  const [openExe, setOpenExe] = useState(false);
  const [deletedExe, setDeletedExe] = useState();

  const delteExeHadeller = () => {
    setLoad(true);

    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/DeleteExam/${deletedExe}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenExe(false);
        setRefresh(!refresh);
        toast.success("تم الحذف ", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const ExamsMaped = excercieses.map((e, index) => {
    return (
      <div key={index}>
        <div className=" flex items-center">
          <button
            onClick={() => {
              setOpenExe(true);
              setDeletedExe(e.id);
            }}
            className=" text-xl text-red-600 mx-4"
          >
            <i className="fa-solid fa-trash "></i>
          </button>
          <Link
            className=" w-full"
            to={`/edit-rev-exercise?id=${e.id}&rev=${id}`}
          >
            <div className=" ma hover:bg-white hover:shadow-md hover:text-[#9ed04e] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#9ed04e]">
                <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                  {index + 1}
                </h1>
              </div>
              <div className=" flex items-center justify-between w-full">
                <h1 className=" text-xl"> {e.title}</h1>
                <i className="  mx-8 ani text-[#9ed04e]/0 rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className=" rtl">
        {/* Lading */}
        {load && <Loader />}
        {/* <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        /> */}
        {error ? (
          <div className=" text-center flex items-center justify-center h-screen">
            <h1 className="text-2xl font-bold"> هذا الدرس غير موجود </h1>
          </div>
        ) : (
          <div>
            <div className=" relative  w-full overflow-x-hidden  h-[83vh]">
              {/*  */}
              {/* Headers of Lesson */}
              {/*  */}
              <div className=" text-center">
                {/* Head */}
                <div className=" flex items-center justify-center">
                  <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
                  <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
                    تعديل المراجعة
                  </h1>
                  <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
                </div>
                {/* Progress */}
                <div className="my-4 rounded-full bg-gray-200/30">
                  <div //progress
                    className={` p-1 rounded-full bg-[#3B3486]/50 transition-all ease-in-out  duration-500 ${
                      progress === 1 && " w-1/4"
                    } ${progress === 2 && " w-2/4"} ${
                      progress === 3 && " w-3/4"
                    } ${progress === 4 && " w-full"} `}
                  ></div>
                </div>

                {/* Adding progress */}

                <div //Basic Info
                  className={` transition-all ease-in-out duration-500 absolute   ${
                    progress == 1 ? "right-0" : "right-[-100%]"
                  } w-full `}
                >
                  <div>
                    {" "}
                    <h1 className=" text-[#3B3486] text-xl font-bold  my-8 ">
                      البيانات الاساسية
                    </h1>
                    {/* Main  Info */}
                    <input //title
                      className="p-2 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      placeholder="عنوان الدرس"
                    />{" "}
                    <br />
                    <select //Year
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
                    >
                      <option selected hidden>
                        الصف
                      </option>
                      <option value={1}>الاول الثانوي</option>
                      <option value={2}>الثاني الثانوي</option>
                      <option value={3}>الثالث الثانوي</option>
                    </select>
                    <br />
                    {year != 3 && (
                      <select //semster
                        value={semster}
                        onChange={(e) => setSemster(e.target.value)}
                        className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
                      >
                        <option selected hidden>
                          الفصل الدراسي
                        </option>
                        <option value={1}>الاول </option>
                        <option value={2}>الثاني </option>
                        {/* <option value="revision"> مراجعة</option> */}
                      </select>
                    )}
                  </div>
                  <button //Next Btn
                    disabled={!year || !title}
                    onClick={editBasicHandeller}
                    className=" mx-2 disabled:opacity-60 mt-32 text-white py-2 px-4  bg-green-700  rounded-md shadow-md"
                  >
                    تعديل <i className="fa-solid fa-paper-plane"></i>
                  </button>
                  <button //Next Btn
                    onClick={() => setProgress(progress + 1)}
                    className=" mx-2 disabled:opacity-60 mt-32 text-white py-2 px-4 bg-[#3B3486] rounded-md shadow-md"
                  >
                    التالي{" "}
                    <i className=" rotate-180 fa-solid fa-chevron-right"></i>
                  </button>
                </div>

                {/*  */}
                {/* Details */}
                {/*  */}
                {/* Upload Video */}
                <div //Upload Video
                  className={` transition-all ease-in-out duration-500 absolute  ${
                    progress == 1
                      ? "right-[100%]"
                      : `${progress == 2 ? "right-[0%]" : "right-[-100%]"}`
                  } w-full `}
                >
                  {progress == 2 && (
                    <div>
                      <h1 className=" text-[#3B3486] text-xl font-bold  my-8 ">
                        {" "}
                        رفع الفيدوهات
                      </h1>

                      <div className=" p-4">
                        {/* {videos.length === 0 && (
                          <h1 className=" text-lg"> لا توجد فيديوهات</h1>
                        )} */}
                        <div>{GettedvideosMaped}</div>
                        <div>{mapVideos}</div>

                        <div // Add new Vide Btn
                          onClick={() => setOpenAddVideo(true)}
                          className=" cursor-pointer hover:bg-gray-200/50 p-8  border rounded-lg my-8"
                        >
                          <h1 className=" text-lg font-bold text-[#45407e]">
                            {" "}
                            اضافة فيديو{" "}
                            <i className="fa-solid fa-cloud-arrow-up"></i>
                          </h1>
                        </div>
                      </div>
                      <button //Next Btn
                        // disabled={videos.length === 0}
                        className=" disabled:opacity-70 mt-32 text-white py-2 px-4 bg-[#3B3486] rounded-md shadow-md"
                        onClick={() => setProgress(3)}
                      >
                        التالي{" "}
                        <i className=" rotate-180 fa-solid fa-chevron-right"></i>
                      </button>
                    </div>
                  )}
                </div>
                <div //Upload images
                  className={` transition-all ease-in-out duration-500 absolute top-28 ${
                    progress < 3
                      ? "right-[100%]"
                      : `${progress == 3 ? "right-[0%]" : "right-[-100%]"}`
                  } w-full `}
                >
                  {/* Gallery */}
                  {progress == 3 && (
                    <>
                      <div>
                        <ImgesEdit
                          refresh={() => setRefresh(!refresh)}
                          images={files}
                        />
                      </div>

                      <div>{ShowGallery}</div>
                    </>
                  )}

                  <button //Next Btn
                    className=" mt-32 text-white py-2 px-4 bg-[#3B3486] rounded-md shadow-md"
                    onClick={() => setProgress(progress + 1)}
                  >
                    التالي{" "}
                    <i className=" rotate-180 fa-solid fa-chevron-right"></i>
                  </button>
                </div>
                <div //Add Exames
                  className={` transition-all ease-in-out duration-500 absolute ${
                    progress < 4
                      ? "right-[100%]"
                      : `${progress == 4 ? "right-[0%]" : "right-[-100%]"}`
                  } w-full `}
                >
                  {progress == 4 && (
                    <div>
                      <h1 className=" text-2xl text-[#45407e] my-4 font-bold">
                        اضافة اختبارات
                      </h1>{" "}
                      <div //Exams
                        className=" mx-auto w-full md:w-2/3"
                      >
                        {excercieses.length > 0 && (
                          <div className=" shadow-md bg-[#9ed04e]/20  rounded-3xl p-8  my-8 rtl">
                            {ExamsMaped}
                          </div>
                        )}
                        <table //List of Exams
                          className="w-full"
                        >
                          <thead>
                            <tr className=" bg-[#b2b2b350] border">
                              <th className=" text-start p-2">الامتحان</th>
                              <th className=" text-end p-2">عدد الاسئلة</th>
                            </tr>
                          </thead>
                          <tbody>{sendedExams}</tbody>
                        </table>

                        <div //Add Exam Btn
                          onClick={addExamHandeller}
                          className=" text-xl text-[#45407e] mt-12 hover:bg-gray-200/20 cursor-pointer transition-all ease-in-out duration-500 border p-2 rounded-md shadow-md"
                        >
                          اضافة اختبار <i className="fa-solid fa-plus"></i>
                        </div>
                      </div>
                      <Link to={"/all-revisions"}>
                        <button //finish Btn
                          className=" mt-32 text-white py-2 px-4 bg-[#3B3486] rounded-md shadow-md"
                        >
                          انهاء <i className="fa-regular fa-paper-plane"></i>
                        </button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {openAddVideo && (
          <div //new Video
            className="  flex items-center justify-center  absolute top-0 left-0 w-full h-full"
          >
            <div className=" max-h-[90vh] p-4 overflow-y-scroll relative coin shadow-2xl bg-gray-200 w-full mx-2 md:w-[85%]  rounded">
              <button //Close Btn
                onClick={() => setOpenAddVideo(false)}
                className=" absolute top-4 left-4 text-xl text-red-600"
              >
                {!loadUpload && <i className="fa-solid fa-xmark"></i>}
              </button>
              <h1 className=" text-center text-2xl text-[#45407e] my-4 font-bold">
                اضافة فيديو
              </h1>

              <div className=" my-4 p-2  ">
                <div>{ShowVid}</div>

                <div className=" my-4 items-center">
                  <textarea
                    disabled={loadUpload}
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    rows={8}
                    className="  text-lg font-medium w-full  border rounded-md shadow-md p-2 outline-none focus:border transition-all ease-in-out duration-500 focus:border-sky-200"
                    placeholder="الشرح"
                  ></textarea>
                </div>
              </div>
              {!loadUpload && (
                <div className="text-center mt-12">
                  <button //cancel  Btn
                    onClick={() => setOpenAddVideo(false)}
                    className=" text-lg py-2 px-4 my-12 text-white shadow-md bg-red-600 rounded-md text-center mx-2"
                  >
                    الغاء
                  </button>
                  <button // Send Video Btn
                    onClick={sendVideoHandeller}
                    disabled={!video}
                    className=" disabled:cursor-not-allowed disabled:opacity-40 text-lg py-2 px-4 my-12 text-white shadow-md bg-sky-600 rounded-md text-center mx-2"
                  >
                    رفع
                  </button>
                </div>
              )}

              {loadUpload && (
                <div className=" m-2 border text-ceneter border-sky-900 p-2 rounded-md">
                  <h1 className=" text-lg text-sky-800 font-bold mb-4 text-center">
                    {" "}
                    يتم الرفع {uploadProgress.toString().substring(0, 4)}%
                  </h1>
                  <div className=" bg-gray-400 rounded-full">
                    <div
                      style={{ width: `${uploadProgress}%` }}
                      className=" transition-all ease-in-out duration-300 rounded-full bg-sky-900 p-1"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {open && (
          <div //New Exam
            className="  flex items-center justify-center  absolute top-0 left-0 w-full h-full"
          >
            <div className=" max-h-[90vh] overflow-y-scroll relative coin shadow-2xl bg-gray-200 w-full mx-2 md:w-2/3  rounded">
              <button //Close Btn
                onClick={() => setOpen(false)}
                className=" absolute top-4 left-4 text-xl text-red-600"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
              <h1 className=" text-center text-2xl text-[#45407e] my-4 font-bold">
                اختبار جديد
              </h1>

              <div // Title of Exam
                className=" text-center"
              >
                <input
                  value={examTitle}
                  onChange={(e) => setExamTitle(e.target.value)}
                  className=" outline-none p-2 w-64 bg-white/0 border-b border-[#45407e] text-[#45407e]/80 mx-auto"
                  type="text"
                  placeholder="اسم الامتحان"
                />
              </div>
              {newExam.length === 0 && (
                <h1 className=" text-lg text-center m-4 ">لا يوجد اسئلة</h1>
              )}

              {questionsOfNewExam}

              <div className="text-center mt-12">
                <button //New Question Btn
                  onClick={newQuestionHandeller}
                  className=" text-lg py-2 px-4 my-12 text-white shadow-md bg-green-600 rounded-md text-center mx-2"
                >
                  اضافة سؤال
                </button>
                <button // Send Questions Btn
                  onClick={senExamHandeller}
                  disabled={newExam.length === 0 || !examTitle}
                  className=" disabled:cursor-not-allowed disabled:opacity-40 text-lg py-2 px-4 my-12 text-white shadow-md bg-sky-600 rounded-md text-center mx-2"
                >
                  انهاء
                </button>
              </div>
            </div>
          </div>
        )}

        {openDeleteVideo && (
          <div // Delete Lesson Section
            className=" bg-black/30 h-screen w-full fixed top-0 left-0 flex items-center justify-center"
          >
            <div className=" coin p-8 bg-white rounded-lg">
              <h1 className=" text-xl text-red-600 text-bold mx-20">
                حذف هذا الفيديو
              </h1>
              <div className="  text-center rtl mt-10">
                <button
                  onClick={() => setOpenDeleteVideo(false)}
                  className=" bg-sky-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
                >
                  الغاء
                </button>
                <button
                  onClick={delteVideoHandeller}
                  className=" bg-red-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
                >
                  حذف
                </button>
              </div>
            </div>
          </div>
        )}
        {openExe && (
          <div // Delete Lesson Section
            className=" bg-black/30 h-screen w-full fixed top-0 left-0 flex items-center justify-center"
          >
            <div className=" coin p-8 bg-white rounded-lg">
              <h1 className=" text-xl text-red-600 text-bold mx-20">
                حذف هذا الاختبار
              </h1>
              <div className="  text-center rtl mt-10">
                <button
                  onClick={() => setOpenExe(false)}
                  className=" bg-sky-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
                >
                  الغاء
                </button>
                <button
                  onClick={delteExeHadeller}
                  className=" bg-red-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
                >
                  حذف
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
