import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate , useLocation } from "react-router-dom";
import "./layout.css";
import { login } from "../../utils/LoginStore";
import Cookies from "js-cookie";
import Error from "../Error/Error";
import { Divider, Menu, MenuItem } from "@mui/material";

export default function StudentLayout({ children }) {
  const [openList, setOpenList] = useState(false);
  const li = useLocation().pathname;

  // login Checking
  const logged = useContext(login);
  const type = useContext(login).type;
  const setLogged = useContext(login).setIsLogged;
  const setType = useContext(login).setType;
  const navigation = useNavigate();

  useEffect(() => {
    if (!logged.isLogged) {
      navigation("/login");
      // return;
    }
  }, []);

  const logOutHandeller = () => {
    Cookies.remove("token");
    Cookies.remove("dHlwZSBvZiBsb2c");
    Cookies.remove("expirein");
    Cookies.remove("id");
    Cookies.remove("mail");
    Cookies.remove("name");
    Cookies.remove("phone");
    setLogged(false);
    setType("");
    navigation("/login");
  };

  const thisTime = new Date().getTime();
  const exin = Cookies.get("expirein");
  const OutTime = +exin - thisTime;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!logged.isLogged) {
    return;
  } else if (type === "teacher") {
    return <Error />;
  } else if (type === "student") {
    setTimeout(logOutHandeller, OutTime);
    return (
      <div>
        <div className=" flex items-center justify-between p-4 shadow-md mb-2">
          <div>
            <Link to={"/courses"}>
              <img
                className=" w-20"
                src="./imgs/logo.png"
                alt="Logo BioGenius"
              />
            </Link>
          </div>
          <div className=" rtl hidden md:flex">
            <Link
              className={` ${
                li === "/courses" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/courses"
            >
              الكورسات
            </Link>
            <Link
              className={` ${
                li === "/mycourses" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/mycourses"
            >
              اشتراكاتي
            </Link>
            {/* <Link
              className={` ${
                li === "/home" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/home"
            >
              {" "}
              الدروس{" "}
            </Link> */}
            {/* <Link
              className=" border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
              to="/revisions"
            >
              {" "}
              المراجعات{" "}
            </Link> */}

            {/* <Link
              className=" border-b p-2 mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
              to="/exams"
            >
              الامتحانات
            </Link> */}

            <Link
              className={` ${
                li === "/communication" && "bg-black/10"
              }  border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300`}
              to="/communication"
            >
              الاسئلة
            </Link>
          </div>
          <div className="hidden md:flex ">
            <button
              onClick={handleClick}
              className=" w-14 h-14 text-[#00a3aa] rounded-full flex items-center justify-center  shadow-lg hover:bg-gray-300 text-2xl  bg-gray-200 "
            >
              <i className="fa-solid fa-user"></i>
            </button>
          </div>
          <div className=" md:hidden  ">
            <button // open List Btn
              onClick={() => setOpenList(!openList)}
              className=" text-2xl text-center text-sky-900"
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
        {openList && (
          <div
            onClick={() => setOpenList(!openList)}
            className=" fixed top-0 left-0 w-full h-screen "
          ></div>
        )}

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Link to={"/edit-profile"}>
              <div className=" text-lg flex items-center justify-between font-sans">
                <h1 className=" mx-2">اعدادات الحساب</h1>
                <i className="fa-solid fa-gear"></i>
              </div>
            </Link>
          </MenuItem>
          <Divider />
          <div className=" flex justify-center">
            <button
              onClick={logOutHandeller}
              className=" shadow-lg py-2 px-4 text-white text-md bg-red-700 cursor-pointer rounded-lg"
            >
              تسجيل خروج <i className="fa-solid fa-right-from-bracket"></i>
            </button>
          </div>
        </Menu>

        <div
          className={` fixed z-40 top-0 right-0 transition-all ease-in-out duration-[1500ms] ${
            openList ? "mr-[0]" : "mr-[-100%]"
          }  p-4 w-[70%] rtl bg-white h-screen shadow-2xl text-sky-900`}
        >
          <div // Side Nave Bar
            className=" text-center "
          >
            <button className="text-2xl" onClick={() => setOpenList(!openList)}>
              <i className="fa-solid fa-xmark"></i>
            </button>
            <hr className=" w-[70%] mx-auto my-8" />
            <div>
              <div className=" mx-auto w-14 h-14 text-[#00a3aa] rounded-full flex items-center justify-center  shadow-lg hover:bg-gray-300 text-2xl  bg-gray-200 ">
                <i className="fa-solid fa-user"></i>
              </div>
              <Link to={"/edit-profile"}>
                <div
                  onClick={() => setOpenList(false)}
                  className=" w-fit mx-auto mt-6 text-lg flex items-center justify-between font-sans"
                >
                  <h1 className=" mx-2">اعدادات الحساب</h1>
                  <i className="fa-solid fa-gear"></i>
                </div>
              </Link>
            </div>
            <hr className=" w-[70%] mx-auto my-8" />

            <div>
              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/courses"
                  >
                    الكورسات
                  </Link>
                </button>
              </div>
              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/mycourses"
                  >
                    اشتراكاتي
                  </Link>
                </button>
              </div>
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/home"
                  >
                    {" "}
                    الدروس{" "}
                  </Link>
                </button>
              </div> */}
              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/revisions"
                  >
                    {" "}
                    المراجعات{" "}
                  </Link>
                </button>
              </div> */}

              {/* <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/exams"
                  >
                    {" "}
                    الامتحانات{" "}
                  </Link>
                </button>
              </div> */}

              <div className=" my-6">
                <button onClick={() => setOpenList(false)}>
                  <Link
                    className=" text-xl border-b p-2  mx-2 hover:text-sky-700 hover:border-sky-700 transition-all ease-in-out duration-300"
                    to="/communication"
                  >
                    {" "}
                    الاسئلة{" "}
                  </Link>
                </button>
              </div>
            </div>
            <hr className=" w-[70%] mx-auto my-8" />
            <div>
              <button
                onClick={logOutHandeller}
                className=" shadow-lg py-2 px-4 text-white bg-red-700 cursor-pointer rounded-lg"
              >
                تسجيل خروج <i className="fa-solid fa-right-from-bracket"></i>
              </button>
            </div>
          </div>
        </div>

        <div>{children}</div>
      </div>
    );
  }
}
