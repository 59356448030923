import React from "react";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";

export default function Lessins() {
  return (
    <>
      <div className=" relative mb-56 md:mb-44 lg:mb-16 flex mx-4 rounded-md rtl shadow-lg my-12">
        <div className="  p-12  rounded-lg w-full bg-[#ff006c]">
          <h1 className=" my-6 text-4xl sh text-white font-bold">
            الثانوية العامة
          </h1>

          <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
            هي مرحلة تعليمية حاسمة
          </h1>
          <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
            تمثل نهاية مرحلة التعليم الأساسي وبداية رحلة أكاديمية جديدة
          </h1>
          <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
            تقدم الثانوية للطلاب المعرفة والمهارات اللازمة للاتحاق بالجامعة أو
            دخول سوق العمل
          </h1>
          <h1 className=" sh text-center lg:w-[80%] text-2xl text-white font-semibold my-6">
            انضم الي بايوجنيس اليوم .... واستثمر في مستقبلك!
          </h1>
        </div>
        <div className=" bg-slate-50 rounded-md hidden w-5/12 relative md:flex">
          <div className=" w-72  md:w-96 ltr bg-white rounded-xl shadow-2xl absolute  my-auto top-[89%] md:top-[84%] lg:top-[24%] left-[10%] md:left-0 lg:right-[-90px] border">
            <ReactOwlCarousel
              autoplay
              autoplayTimeout={2000}
              autoplayHoverPause={true}
              items={1}
              className="owl-theme"
              loop
              margin={10}
            >
              <div className=" rtl w-72  md:w-96">
                <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                  <h1 className=" mb-[-16px]">1</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-xl font-bold">الشرح</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-lg">
                    نوفر لكم شرحا تفصيليا للمواد الدراسية مع أمثلة وتطبيقات
                    عملية.
                  </h1>
                </section>
              </div>
              <div className=" rtl w-72  md:w-96">
                <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                  <h1 className=" mb-[-16px]">2</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-xl font-bold">مراجعة</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-lg">
                    شاملة للمواد الدراسية لكل فصل دراسي وكل درس علي حدا مع
                    التركيز علي الاسئلة المهمة
                  </h1>
                </section>
              </div>
              <div className=" rtl w-72  md:w-96">
                <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                  <h1 className=" mb-[-16px]">3</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-xl font-bold">
                    اختبارات وتقييمات
                  </h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-lg">
                    لمساعدة الطلاب علي قياس مستوي فهمهم للمواد وتحديد نقاط القوة
                    والضعف
                  </h1>
                </section>
              </div>
              <div className=" rtl w-72  md:w-96">
                <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                  <h1 className=" mb-[-16px]">4</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-xl font-bold"> دعم ومساندة</h1>
                </section>
                <section className=" p-2">
                  <h1 className=" px-3  text-lg">
                    يتوفر لكم دعمًا ومساندة من خلال منتديات ومجموعات للمناقشة
                    وطرح الاسئلة
                  </h1>
                </section>
              </div>
            </ReactOwlCarousel>
          </div>
        </div>
        <div className=" w-72 md:hidden  md:w-96 ltr bg-white rounded-xl shadow-2xl absolute  my-auto top-[89%] md:top-[82%] lg:top-[40%] left-[10%] md:left-0 lg:left-[4%] border">
          <ReactOwlCarousel
            autoplay
            autoplayTimeout={2000}
            autoplayHoverPause={true}
            items={1}
            className="owl-theme"
            loop
            margin={10}
          >
            <div className=" rtl w-72  md:w-96">
              <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                <h1 className=" mb-[-16px]">1</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-xl font-bold">الشرح</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-lg">
                  نوفر لكم شرحا تفصيليا للمواد الدراسية مع أمثلة وتطبيقات عملية.
                </h1>
              </section>
            </div>
            <div className=" rtl w-72  md:w-96">
              <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                <h1 className=" mb-[-16px]">2</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-xl font-bold">مراجعة</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-lg">
                  شاملة للمواد الدراسية لكل فصل دراسي وكل درس علي حدا مع التركيز
                  علي الاسئلة المهمة
                </h1>
              </section>
            </div>
            <div className=" rtl w-72  md:w-96">
              <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                <h1 className=" mb-[-16px]">3</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-xl font-bold">اختبارات وتقييمات</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-lg">
                  لمساعدة الطلاب علي قياس مستوي فهمهم للمواد وتحديد نقاط القوة
                  والضعف
                </h1>
              </section>
            </div>
            <div className=" rtl w-72  md:w-96">
              <section className=" m-3 bg-[#ff006c] flex items-center justify-center text-white text-2xl font-bold min-w-9 w-9 h-9 min-h-9 rounded-full">
                <h1 className=" mb-[-16px]">4</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-xl font-bold"> دعم ومساندة</h1>
              </section>
              <section className=" p-2">
                <h1 className=" px-3  text-lg">
                  يتوفر لكم دعمًا ومساندة من خلال منتديات ومجموعات للمناقشة وطرح
                  الاسئلة
                </h1>
              </section>
            </div>
          </ReactOwlCarousel>
        </div>

        <div></div>
      </div>
    </>
  );
}
