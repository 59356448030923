import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export default function VideoViaLink(props) {
  const [load, setLoad] = useState(false);
 

  return (
    <div className=" shadow-lg mb-10 border rounded-xl py-6 mx-6 md:mx-auto md:w-3/4 ">
      {/* Lading */}
      {load && <Loader />}
      {/* Video */}
      <video
        className=" w-full h-96"
        controlsList="nodownload"
        src={props.link}
        controls
      />

      {/* Description */}
      {!!props.desc && (
        <div>
          <h1 className=" text-xl text-center text-[#9ed04e]  my-6 ">الشرح</h1>

          <p className=" text-lg leading-relaxed text-right mx-auto w-2/3">
            {props.desc}
          </p>
        </div>
      )}
    </div>
  );
}
