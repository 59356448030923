import React, { useEffect, useState } from "react";
import "./unit.css";
import Loader from "../../../components/Loader/Loader";
import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

export default function Edit(props) {
  const { unitId } = props;
  const [load, setLoad] = useState(false);
  const [title, setTitle] = useState("");
  const [year, setYear] = useState(1);
  const [semster, setSemster] = useState(1);
  const [packages, setPackages] = useState([]);

  const [packagesOptions, setPackagesOptions] = useState([]);
  const [material, setMaterial] = useState();
  console.log(unitId);

  useEffect(() => {
    fetch(`https://biogeniusdotnet.biogeniuses.com/api/Units/Details/${unitId}`)
      .then((res) => res.json())
      .then((data) => {
        setTitle(data.title);
        setYear(data.year);
        setSemster(data.term);
        setMaterial(data.materialId);
        let ids = [];
        data.packages.map((e) => {
          ids.push(e.id);
        });
        setPackages(ids);
        // setPackagesOptions(data.term);
      });

    fetch("https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackages")
      .then((res) => res.json())
      .then((data) => setPackagesOptions(data));
  }, []);

  const addUnitHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    const auth = btoa("11162387:60-dayfreetrial");
    myHeaders.append("Authorization", `Basic ${auth}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: unitId,
      title: title,
      description: "string",
      year: +year,
      term: +semster,
      materialId: material,
      packages: packages,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Units/Edit",
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          setLoad(false);
          props.cancel();
          props.refresh();
        } else {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPackages(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className=" z-50 fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20">
      {/* Lading */}
      {load && <Loader />}
      <div className=" coin bg-white p-8 rounded-lg">
        <h1 className=" text-bold text-xl text-sky-600 mx-12 my-4 md:mx-24">
          اضافة وحدة
        </h1>
        <div className=" text-center rtl">
          <form>
            <input //title
              className="p-2 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              placeholder=" اسم الوحدة"
            />{" "}
            <br />
            {/* <select //Year
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                الصف
              </option>
              <option value={1}>الاول الثانوي</option>
              <option value={2}>الثاني الثانوي</option>
              <option value={3}>الثالث الثانوي</option>
            </select> */}
            <br />
            <h1>الكورسات :</h1>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
              multiple
              value={packages}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              // MenuProps={MenuProps}
            >
              {packagesOptions.map((name) => (
                <MenuItem
                  key={name}
                  value={name.id}
                  // style={getStyles(name, personName, theme)}
                >
                  {name.name}
                </MenuItem>
              ))}
            </Select>
          </form>
        </div>
        <div className="  text-center mt-8">
          <button //Delte Btn
            disabled={year === 0 || semster === 0 || !title}
            onClick={addUnitHandeller}
            className=" disabled:opacity-70 bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            تعديل
          </button>
          <button //Cancel Btn
            onClick={props.cancel}
            className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  );
}
