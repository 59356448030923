import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { useLocation } from "react-router";
import Error from "../../Error/Error";
import PayBtn from "../../../components/payment/PayBtn";
import VodafonePay from "../../../components/payment/VodafonePay";

export default function Checkout() {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);
  const [price, setPrice] = useState();
  const [pack, setPack] = useState({
    name: "",
    year: "",
    term: "",
    endDate: "",
    startDate: "",
    isStudy: true,
  });

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackageById?id=${id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          //   setPackages(data);
          setPack(data);
          setPrice(+data.price - +data.discount);
          setLoad(false);
        });
      } else {
        setLoad(false);
        setErr(true);
      }
    });
  }, []);

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}
        <div className=" md:w-2/3 mx-auto shadow-md rounded-lg p-3 md:p-12 my-8">
          <h1 className=" font-bold text-center text-2xl "> بيانات الفاتورة</h1>
          <hr className=" my-3"></hr>
          <table className=" text-lg border w-full rtl">
            <tbody>
              <tr></tr>
              <tr className=" grid grid-cols-12">
                <td className=" col-span-3 md:col-span-2 p-2">اسم الكورس</td>
                <td className=" col-span-9 md:col-span-10 p-2 text-sky-800 font-bold">
                  {pack.name}
                </td>
              </tr>
              <tr className=" grid grid-cols-12">
                <td className=" col-span-3 md:col-span-2 p-2">الصف</td>
                <td className=" col-span-9 md:col-span-10 p-2 text-sky-800 font-bold">
                  {pack.year == 1 && "الاول"}
                  {pack.year == 2 && "الثاني"}
                  {pack.year == 3 && "الثالث"} {"الثانوي"}
                </td>
              </tr>
              {pack.term && (
                <tr className=" grid grid-cols-12">
                  <td className=" col-span-3 md:col-span-2 p-2">التيرم</td>
                  <td className=" col-span-9 md:col-span-10 p-2 text-sky-800 font-bold">
                    {"الفصل الدراسي  "}
                    {pack.term == 1 && "الاول"}
                    {pack.term == 2 && "الثاني"}
                  </td>
                </tr>
              )}

              <tr className=" grid grid-cols-12">
                <td className=" col-span-3 md:col-span-2 p-2"> السعر</td>
                <td className=" col-span-9 md:col-span-10 p-2 text-sky-800 font-bold">
                  {price} {"جنيها"}
                </td>
              </tr>
            </tbody>
          </table>
          <div className=" text-center flex items-center justify-center m-2">
            <PayBtn
              name={pack.name}
              end={pack.endDate.substring(0, 10)}
              start={pack.startDate.substring(0, 10)}
              year={pack.year}
              term={pack.term}
              id={pack.id}
              type={pack.isStudy ? "units" : "revision"}
              price={+price}
            />
            <VodafonePay
              name={pack.name}
              end={pack.endDate.substring(0, 10)}
              start={pack.startDate.substring(0, 10)}
              year={pack.year}
              term={pack.term}
              id={pack.id}
              type={pack.isStudy ? "units" : "revision"}
              price={+price}
            />
          </div>
        </div>
      </div>
    );
  }
}
