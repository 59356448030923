import { Route, Routes } from "react-router";
import "./App.css";
import Home from "./pages/Home/Home";
import "./utils/all.min.css";
import Add from "./pages/Teacher/AddLesson/Add";
import LessonsList from "./pages/Teacher/Main/LessonsList";
import TeacherLayout from "./pages/Teacher/TeacherLayout";
import UnitsList from "./pages/Teacher/units/UnitsList";
import ExamsList from "./pages/Teacher/Exams/ExamsList";
import AddExam from "./pages/Teacher/Exams/AddExam";
import StudentLayout from "./pages/Student/Layout";
import HomeStudent from "./pages/Student/Home/HomeStudent";
import ExamsForstudent from "./pages/Student/exams/Exams";
import Lesson from "./pages/lesson/Lesson";
import Exercise from "./pages/Student/exercise/Exercise";
import Exam from "./pages/Student/Exam/Exam";
import ViewLesson from "./pages/Teacher/ViewLesson/ViewLesson";
import EditExcer from "./pages/Teacher/editLesson/editExercise";
import EditLesson from "./pages/Teacher/editLesson/EditLesson";
import LoginTeacher from "./pages/Teacher/Login/Login";
import LoginStore from "./utils/LoginStore";
import Main from "./pages/Home/Main";
import Error from "./pages/Error/Error";
import Packeges from "./pages/Teacher/Packages/Packeges";
import LoginStudent from "./pages/Student/Auth/LoginStudent";
import SignUp from "./pages/Student/Auth/SignUp";
import Free from "./pages/Teacher/free/Free";
import Intro from "./pages/Teacher/Intro/Intro";
import Chat from "./pages/Teacher/Chat/Chat";
import Test from "./pages/Student/Payment/Test";
import Revisions from "./pages/Teacher/revisions/Revisions";
import AddRevision from "./pages/Teacher/revisions/AddRevision";
import CallBack from "./pages/Student/Payment/CallBack";
import EditRe from "./pages/Teacher/EditRe/EditRe";
import EditExams from "./pages/Teacher/EditRe/EditExams";
import CallbackTest from "./pages/Student/Payment/CallbackTest";
import FreeLesson from "./pages/lesson/FreeLesson";
import Package from "./pages/Student/Package/Package";
import Checkout from "./pages/Student/Package/Checkout";
import PayErr from "./pages/Student/Payment/PayErr";
import Reviosin from "./pages/Student/Revision/Reviosin";
import ShowRevision from "./pages/Student/Revision/ShowRevision";
import ExcrsiseForRevi from "./pages/Student/Revision/ExcrsiseForRevi";
import ChatTest from "./pages/Teacher/Chat/ChatTest";
import ChatStore from "./utils/ChatStore";
import Communication from "./pages/Student/chat/Communication";
import TeacherSettings from "./pages/Teacher/settings/TeacherSettings";
import Users from "./pages/Teacher/users/Users";
import Student from "./pages/Teacher/users/Student";
import ResetPassword from "./pages/Student/settings/ResetPassword";
import ChatList from "./pages/Teacher/Chat/ChatList";
import TeacherInfo from "./pages/Home/TeacherInfo";
import Matrail from "./pages/Teacher/matrails/Matrail";
import Assestens from "./pages/Teacher/assestens/Assestens";
import AddAssesten from "./pages/Teacher/assestens/add-assesten/AddAssesten";
import Cookies from "js-cookie";
import MyCourses from "./pages/Student/myCourses/MyCourses";
import ShowCourse from "./pages/Student/Package/show/ShowCourse";
import Course from "./pages/Student/course/Course";
import US from "./pages/Home/US";
import Policises from "./pages/Home/Policises";
import AllCourses from "./pages/Home/AllCourses";
import Showcoursedetails from "./pages/Home/Showcoursedetails";
import ShowfreeLesson from "./pages/Home/ShowfreeLesson";
import ReportsCourse from "./pages/Teacher/Packages/Reports";

function App() {
  // const asse = true;
  // const chat = true;
  // const report = true;
  // const editCourse = true;
  const asse = Cookies.get("assestent") === "true";
  const chat = Cookies.get("Chat") === "true";
  const report = Cookies.get("Report") === "true";
  const editCourse = Cookies.get("EditCourse") === "true";
  return (
    <div>
      <LoginStore>
        <ChatStore>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/teacher-info" element={<TeacherInfo />} />
            <Route path="*" element={<Error />} />
            {/* Teacher */}
            <Route path="/teacher-login" element={<LoginTeacher />} />
            <Route
              path="/test"
              element={
                <TeacherLayout>
                  <Test />
                </TeacherLayout>
              }
            />
            {
              <Route
                path="/addlesson"
                element={
                  editCourse ? (
                    <TeacherLayout>
                      <Add />
                    </TeacherLayout>
                  ) : (
                    <Error />
                  )
                }
              />
            }
            <Route
              path="/lessons"
              element={
                <TeacherLayout>
                  <LessonsList />
                </TeacherLayout>
              }
            />
            <Route
              path="/matrail"
              element={
                <TeacherLayout>
                  <Matrail />
                </TeacherLayout>
              }
            />
            <Route
              path="/viewLesson"
              element={
                <TeacherLayout>
                  <ViewLesson />
                </TeacherLayout>
              }
            />
            {
              <>
                <Route
                  path="/editlesson"
                  element={
                    editCourse ? (
                      <TeacherLayout>
                        <EditLesson />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
                <Route
                  path="/editexercise"
                  element={
                    editCourse ? (
                      <TeacherLayout>
                        <EditExcer />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
              </>
            }
            <Route
              path="/units"
              element={
                <TeacherLayout>
                  <UnitsList />
                </TeacherLayout>
              }
            />
            <Route
              path="/all-revisions"
              element={
                <TeacherLayout>
                  <Revisions />
                </TeacherLayout>
              }
            />
            <Route
              path="/add-revision"
              element={
                <TeacherLayout>
                  <AddRevision />
                </TeacherLayout>
              }
            />
            <Route
              path="/edit-revi"
              element={
                <TeacherLayout>
                  <EditRe />
                </TeacherLayout>
              }
            />
            <Route
              path="/edit-rev-exercise"
              element={
                <TeacherLayout>
                  <EditExams />
                </TeacherLayout>
              }
            />
            <Route
              path="/free"
              element={
                <TeacherLayout>
                  <Free />
                </TeacherLayout>
              }
            />
            <Route
              path="/all-exams"
              element={
                <TeacherLayout>
                  <ExamsList />
                </TeacherLayout>
              }
            />
            <Route
              path="/packages"
              element={
                <TeacherLayout>
                  <Packeges />
                </TeacherLayout>
              }
            />
            <Route
              path="/intro"
              element={
                <TeacherLayout>
                  <Intro />
                </TeacherLayout>
              }
            />
            {
              <Route
                path="/addexam"
                element={
                  editCourse ? (
                    <TeacherLayout>
                      <AddExam />
                    </TeacherLayout>
                  ) : (
                    <Error />
                  )
                }
              />
            }
            {
              <>
                <Route
                  path="/chat"
                  element={
                    chat ? (
                      <TeacherLayout>
                        <Chat />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
                <Route
                  path="/list-chats"
                  element={
                    chat ? (
                      <TeacherLayout>
                        <ChatList />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
              </>
            }
            <Route
              path="/edit"
              element={
                <TeacherLayout>
                  <TeacherSettings />
                </TeacherLayout>
              }
            />
            {
              <Route
                path="/reports-courses"
                element={
                  report ? (
                    <TeacherLayout>
                      <ReportsCourse />
                    </TeacherLayout>
                  ) : (
                    <Error />
                  )
                }
              />
            }
            {
              <Route
                path="/reports"
                element={
                  report ? (
                    <TeacherLayout>
                      <Users />
                    </TeacherLayout>
                  ) : (
                    <Error />
                  )
                }
              />
            }

            {/* Assestent */}
            {
              <>
                <Route
                  path="/assestens"
                  element={
                    asse ? (
                      <TeacherLayout>
                        <Assestens />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
                <Route
                  path="/add-assesten"
                  element={
                    asse ? (
                      <TeacherLayout>
                        <AddAssesten />
                      </TeacherLayout>
                    ) : (
                      <Error />
                    )
                  }
                />
              </>
            }
            {
              <Route
                path="/student"
                element={
                  report ? (
                    <TeacherLayout>
                      <Student />
                    </TeacherLayout>
                  ) : (
                    <Error />
                  )
                }
              />
            }
            {/* <Route
              path="/chatTest"
              element={
                <TeacherLayout>
                  <ChatTest />
                </TeacherLayout>
              }
            /> */}
            {/* Student */}
            <Route path="/login" element={<LoginStudent />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/us" element={<US />} />
            <Route path="/police" element={<Policises />} />
            <Route path="/allcourses" element={<AllCourses />} />
            <Route path="/showcoursedetails" element={<Showcoursedetails />} />
            <Route path="/show-free-lesson" element={<ShowfreeLesson />} />
            {/* <Route path="/callbackafterpay" element={<CallBack />} /> */}
            <Route path="/callbackafterpay" element={<CallbackTest />} />
            <Route path="/payerr" element={<PayErr />} />
            {/* <Route path="/callbackfortest" element={<CallbackTest />} /> */}
            <Route
              path="/courses"
              element={
                <StudentLayout>
                  <Package />
                </StudentLayout>
              }
            />
            <Route
              path="/course"
              element={
                <StudentLayout>
                  <Course />
                </StudentLayout>
              }
            />
            <Route
              path="/mycourses"
              element={
                <StudentLayout>
                  <MyCourses />
                </StudentLayout>
              }
            />
            <Route
              path="/showcourse"
              element={
                <StudentLayout>
                  <ShowCourse />
                </StudentLayout>
              }
            />
            <Route
              path="/checkout"
              element={
                <StudentLayout>
                  <Checkout />
                </StudentLayout>
              }
            />
            <Route
              path="/home"
              element={
                <StudentLayout>
                  <HomeStudent />
                </StudentLayout>
              }
            />
            <Route
              path="/revisions"
              element={
                <StudentLayout>
                  <Reviosin />
                </StudentLayout>
              }
            />
            <Route
              path="/revision-show"
              element={
                <StudentLayout>
                  <ShowRevision />
                </StudentLayout>
              }
            />
            <Route
              path="/revision-exercise"
              element={
                <StudentLayout>
                  <ExcrsiseForRevi />
                </StudentLayout>
              }
            />
            <Route
              path="/exams"
              element={
                <StudentLayout>
                  <ExamsForstudent />
                </StudentLayout>
              }
            />
            <Route
              path="/exam"
              element={
                <StudentLayout>
                  <Exam />
                </StudentLayout>
              }
            />
            <Route
              path="/lesson"
              element={
                <StudentLayout>
                  <Lesson />
                </StudentLayout>
              }
            />
            <Route
              path="/free-lesson"
              element={
                <StudentLayout>
                  <FreeLesson />
                </StudentLayout>
              }
            />
            <Route
              path="/exercise"
              element={
                <StudentLayout>
                  <Exercise />
                </StudentLayout>
              }
            />
            <Route
              path="/communication"
              element={
                <StudentLayout>
                  <Communication />
                </StudentLayout>
              }
            />
            <Route
              path="/edit-profile"
              element={
                <StudentLayout>
                  <ResetPassword />
                </StudentLayout>
              }
            />
          </Routes>
        </ChatStore>
      </LoginStore>
    </div>
  );
}

export default App;
