import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loader from "../../components/Loader/Loader";
import Video from "../../components/Lesson/Video";
import Images from "../../components/Lesson/Images";
import Excers from "../../components/Lesson/Excers";
import Cookies from "js-cookie";
import { error } from "jquery";
import Error from "../Error/Error";
import VideoViaLink from "../../components/Lesson/VideoViaLink";
import ImageViaLink from "../../components/Lesson/ImageViaLink";

export default function Lesson() {
  const [load, setLoad] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const course = new URLSearchParams(search).get("course");
  const unit = new URLSearchParams(search).get("unit");
  const [err, setErr] = useState(false);

  //
  // MAIN DATA
  //

  const [title, setTitle] = useState("");
  const [Videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  // fetching main data

  const token = Cookies.get("token");
  // console.log(token)
  useEffect(() => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append("idLesson", id);
    formdata.append("idUnit", unit);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://student.biogeniuses.com/api/checkSpecialDataStudent/${course}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        // console.log(res.text());
        res.json().then((data) => {
          // console.log(data[1])
          setErr(false);
          console.log(data);
          setTitle(data.title);
          setVideos(data.videos);
          setImages(data.images);
          setExcercieses(data.exercises);
          // data.map((e) => {
          //   if (e) {
          //     setErr(false);
          //     setTitle(e.title);
          //     setVideos(e.videos);
          //     setImages(e.images);
          //     setExcercieses(e.exercises);
          //   }
          // });
        });
      } else {
        setLoad(false);
        setErr(true);
      }
    });
  }, []);

  // maping Videos

  const videoMaped = Videos.map((e, index) => {
    return (
      <VideoViaLink
        key={index}
        link={"https://biogeniusdotnet.biogeniuses.com/" + e.videoUrl}
        desc={e.description}
      />
    );
  });

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}

        {/* Title */}

        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]"> {title}</h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>

        {/* Videos List */}
        <div>{videoMaped}</div>
        {/* Gallery */}
        <div>{images.length !== 0 && <Images images={images} />}</div>
        {/* Ecxercises */}
        <div>
          {excercieses.length !== 0 && (
            <Excers unit={unit} course={course} ex={id} exc={excercieses} />
          )}
        </div>
      </div>
    );
  }
}
