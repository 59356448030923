import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Error from "../../Error/Error";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import VideoViaLink from "../../../components/Lesson/VideoViaLink";
import ImagesForRev from "../../../components/Lesson/ImagesForRev";
import Excers from "../../../components/Lesson/Excers";
import ExcersForRev from "../../../components/Lesson/ExercsForRevision";

export default function ShowRevision() {
  const [load, setLoad] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [err, setErr] = useState(false);

  //
  // MAIN DATA
  //

  const [title, setTitle] = useState("");
  const [Videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  // fetching main data

  const token = Cookies.get("token");
  useEffect(() => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://student.biogeniuses.com/api/GetRevisionById/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);

        res.json().then((data) => {
          setErr(true);
          data.map((e) => {
            if (e.length > 0) {
              setErr(false);
              setTitle(e[0].title);

              let vids = [];
              let imgs = [];
              e[0].reviewFiles.map((el) => {
                if (el.type == 1) {
                  vids.push(el);
                } else {
                  imgs.push(el);
                }
                setImages(imgs);
                setVideos(vids);
              });
              setExcercieses(e[0].revisionExams);
            }
          });
        });
      } else {
        setLoad(false);
        setErr(true);
      }
    });
  }, []);

  // maping Videos

  const videoMaped = Videos.map((e, index) => {
    return (
      <VideoViaLink
        key={index}
        link={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
        desc={e.description}
      />
    );
  });

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}

        {/* Title */}

        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]"> {title}</h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {/* Videos List */}
        <div>{videoMaped}</div>
        {/* Gallery */}
        <div>{images.length !== 0 && <ImagesForRev images={images} />}</div>
        {/* Ecxercises */}
        <div>
          {excercieses.length !== 0 && (
            <ExcersForRev ex={id} exc={excercieses} />
          )}
        </div>
      </div>
    );
  }
}
