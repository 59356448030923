import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import VideoShow from "../../../components/Lesson/Video";
import Images from "../../../components/Lesson/Images";
import Excers from "../../../components/Lesson/Excers";
import Cookies from "js-cookie";

export default function ViewLesson() {
  const [load, setLoad] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const editCourse = Cookies.get("EditCourse") === "true";

  const [error, setError] = useState(false);

  const [title, setTilte] = useState({});
  const [videos, setVides] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  // Fetching Data
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    //Deatils
    const deatails = () => {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Lessons/Details/${id}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setTilte({
              title: data.title,
              year: data.year,
              term: data.term,
              unit: data.unit,
            });
          });
        } else {
          setLoad(false);
          setError(true);
        }
      });
    };

    //   Videos
    const getVideo = () => {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/LessonVideos/GetLessonVideos/${id}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setVides(data);
          });
        } else {
          setLoad(false);
          setError(true);
        }
      });
    };

    //   Images
    const getImages = () => {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/LessonImages/GetLessonImages/${id}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setImages(data);
          });
        } else {
          setLoad(false);
          setError(true);
        }
      });
    };

    //   exercices
    const getExercices = () => {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Exercises/GetByLessonId/${id}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setLoad(false);
            setExcercieses(data);
            // setShowExcercieses(true)
          });
        } else {
          setLoad(false);
          // setError(true);
        }
      });
    };

    deatails();
    getVideo();
    getImages();
    getExercices();
  }, []);

  // Videos
  const videosMaped = videos.map((e, index) => {
    return <VideoShow key={index} id={e.value} />;
  });

  // Exercise

  const ExamsMaped = excercieses.map((e, index) => {
    return (
      <div key={index}>
        {editCourse ? (
          <Link className="" to={`/editexercise?id=${e.id}`}>
            <div className=" ma hover:bg-white hover:shadow-md hover:text-[#9ed04e] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#9ed04e]">
                <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                  {index + 1}
                </h1>
              </div>
              <div className=" flex items-center justify-between w-full">
                <h1 className=" text-xl"> {e.title}</h1>
                <i className="  mx-8 ani text-[#9ed04e]/0 rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        ) : (
          <div className=" ma hover:bg-white hover:shadow-md hover:text-[#9ed04e] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
            <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#9ed04e]">
              <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                {index + 1}
              </h1>
            </div>
            <div className=" flex items-center justify-between w-full">
              <h1 className=" text-xl"> {e.title}</h1>
              <i className="  mx-8 ani text-[#9ed04e]/0 rotate-180 fa-solid fa-chevron-right"></i>
            </div>
          </div>
        )}
      </div>
    );
  });

  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      {/* Title */}

      {error ? (
        <div className=" text-center flex items-center justify-center h-screen">
          <h1 className="text-2xl font-bold">لايوجد هذا الدرس</h1>
        </div>
      ) : (
        <div className=" rtl p-4">
          <div className=" my-4 flex items-center justify-center">
            <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
            <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
              {" "}
              {title.title}
            </h1>
            <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          </div>

          <div className=" flex items-center justify-between text-lg text-sky-800">
            <h1>
              {title.year} / {title.year != 3 && title.term + "/"}
              {title.unit}
            </h1>
            {editCourse && (
              <Link to={`/editlesson?id=${id}`}>
                <button className=" py-2 px-4 text-lg bg-[#7ac170] text-white rounded-md shadow-md">
                  تعديل <i className="fa-solid fa-pen"></i>
                </button>
              </Link>
            )}
          </div>
          {/* Videos */}
          <div>{videosMaped}</div>
          {/* Gallery */}
          <div>{images.length !== 0 && <Images images={images} />}</div>
          {/* Ecxercises */}
          {excercieses.length > 0 && (
            <div className="mb-10  rounded-xl py-6 mx-6 md:mx-auto md:w-3/4">
              <hr />
              <h1 className=" text-2xl text-center text-[#9ed04e] font-bold my-10">
                الاختبارات
              </h1>
              {/* Ecxercices */}
              <div className=" shadow-md bg-[#9ed04e]/20  rounded-3xl p-8  my-8 rtl">
                {ExamsMaped}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
