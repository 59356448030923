import React from "react";

export default function Benfi() {
  return (
    <>
      <div className=" flex-wrap my-10 flex items-center justify-around">
        <div className=" text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
            <i className="fa-solid fa-play"></i>
          </section>
          <h1 className=" text-gray-800 text-xl">شاهد دروسك في اي وقت</h1>
        </div>
        <div className=" text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
            <i className="fa-solid fa-chalkboard-user"></i>
          </section>
          <h1 className=" text-gray-800 text-xl"> خبراء متخصصون</h1>
        </div>
        <div className="text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
            <i className="fa-solid fa-book-open"></i>
          </section>
          <h1 className=" text-gray-800 text-xl"> سهولة الاستخدام</h1>
        </div>
        {/* <div className=" text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
            <i className="fa-solid fa-comments"></i>
          </section>
          <h1 className=" text-gray-800 text-xl"> تفاعل مع الطلاب </h1>
        </div> */}
        <div className=" text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
            <i className="fa-solid fa-rectangle-list"></i>
          </section>
          <h1 className=" text-gray-800 text-xl"> امتحانات دورية </h1>
        </div>
        <div className=" text-center w-36 md:w-auto   md:m-8">
          <section className=" mx-auto text-3xl flex items-center justify-center h-16 w-16 text-[#ff006c] shadow-xl rounded-full my-4 ">
          <i className="fa-regular fa-clock"></i>
          </section>
          <h1 className=" text-gray-800 text-xl"> وفر وقتك </h1>
        </div>
      </div>
      <hr className=" my-2 w-[70%] mx-auto" />
    </>
  );
}
