import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Error from "../../Error/Error";
import "./CallBack.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function CallbackTest() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("order");
  const order_id = window.localStorage.getItem("T3JkZXJfaWQ");

  const [load, setLoad] = useState(true);

  const success = new URLSearchParams(location.search).get("success");

  const [err, setErr] = useState(false);

  const navigate = useNavigate();
  const token = Cookies.get("token");
  const name = window.localStorage.getItem("name");
  const end = window.localStorage.getItem("end");
  const start = window.localStorage.getItem("start");
  // const type = window.localStorage.getItem("type");
  const year = window.localStorage.getItem("year");
  const term = window.localStorage.getItem("term");
  const idCourse = window.localStorage.getItem("id");
  const price = window.localStorage.getItem("price");

  const [doFetch, setDoFetch] = useState(false);

  useEffect(() => {
    if (id === order_id) {
      if (success === "true") {
        // setLoad(true);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("Purchases", idCourse);
        formdata.append("end", end);
        formdata.append("name", name);
        formdata.append("price", price);
        formdata.append("start", start);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        if (doFetch) {
          fetch(
            "https://student.biogeniuses.com/api/updateDataStudent",
            requestOptions
          ).then((res) => {
            console.log("11");
            if (res.status === 200) {
              // console.log(name);
              setLoad(false);
              // console.log(formdata)
              setTimeout(() => {
                window.localStorage.removeItem("name");
                window.localStorage.removeItem("end");
                window.localStorage.removeItem("type");
                window.localStorage.removeItem("year");
                window.localStorage.removeItem("term");
                window.localStorage.removeItem("T3JkZXJfaWQ");
                window.localStorage.removeItem("price");
                window.localStorage.removeItem("id");
                navigate("/mycourses");
              }, 1000);
            } else {
              // console.log(res.status);
              setErr(true);
              setLoad(false);
            }
          });
        }
      } else {
        setLoad(false);
        navigate("/payerr");
      }
    } else {
      setLoad(false);
    }
  }, [doFetch]);

  useEffect(() => {
    setTimeout(() => {
      setDoFetch(true);
    }, 2000);
  }, []);

  if (id !== order_id) {
    return <Error />;
  } else {
    if (success === "true") {
      return (
        <div>
          {load ? (
            <div className=" w-full h-screen flex items-center justify-center">
              <div class="dots">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div>
              {err ? (
                <div className=" flex items-center justify-center h-screen">
                  <div className=" text-center shadow-md border mx-auto p-4 md:p-12 md:w-2/3">
                    <i className="fa-solid fa-ban text-6xl text-red-700"></i>
                    <h1 className=" text-3xl my-6"> عملية الدفع لم تنجح</h1>
                    <Link to={"/courses"}>
                      <button className=" underline text-sky-700">
                        الرجوع الي الباقات
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className=" flex items-center justify-center h-screen">
                  <div className=" text-center shadow-md border mx-auto p-4 md:p-12 md:w-2/3">
                    <i className="fa-solid fa-check text-6xl text-green-700"></i>
                    <h1 className=" text-3xl my-6"> تم الدفع بنجاح </h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    } else {
      return (
        <>
          <div className=" flex items-center justify-center h-screen">
            <div className=" text-center shadow-md border mx-auto p-4 md:p-12 md:w-2/3">
              <i className="fa-solid fa-ban text-6xl text-red-700"></i>
              <h1 className=" text-3xl my-6"> عملية الدفع لم تنجح</h1>
              <Link to={"/courses"}>
                <button className=" underline text-sky-700">
                  الرجوع الي الباقات
                </button>
              </Link>
            </div>
          </div>
        </>
      );
    }
  }
}
