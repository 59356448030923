import React, { useEffect, useState } from "react";
import Error from "../../Error/Error";
import Loader from "../../../components/Loader/Loader";
import { useLocation } from "react-router";
import AddPach from "./AddPach";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Student() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [err, setErr] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  //
  // Fetching Data
  //

  const [student, setStudent] = useState({
    name: "",
    phone: "",
    parchase: [],
  });
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    setLoad(true);
    // setOpen(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/AllStudent",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        res.json().then((data) => {
          let students = data;

          students = students.filter((e) => e.id == id);
          if (students.length > 0) {
            setStudent(students[0]);
          } else {
            setErr(true);
          }
        });
      }
    });

    const formdata = new FormData();
    formdata.append("idStudent", id);

    const requestOptionstoCourse = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/report",
      requestOptionstoCourse
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        res.json().then((data) => {
          setPackages(data);
        });
      }
    });
  }, [refresh]);

  //
  // Add Package
  //

  const close = () => {
    setOpen(false);
  };

  let total = 0;

  const chat = Cookies.get("Chat") === "true";
  const money = Cookies.get("Money") === "true";

  const [password, setPassword] = useState("");
  const [sucsses, setSucsses] = useState(false);
  const resetPasswordHandeller = () => {
    setLoad(true);

    const formdata = new FormData();
    formdata.append("password", password);
    formdata.append("idStudent", id);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/adminressetPassword",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setSucsses(true);
        setPassword("");
      }
    });
  };

  if (err || !id) {
    return <Error />;
  } else {
    return (
      <div className=" p-4">
        {load && <Loader />}
        {open && <AddPach re={() => setRefresh(!refresh)} close={close} />}
        {/* { <AddPach close={() => setOpen(false)} />} */}
        <div className=" md:w-2/3 mx-auto my-20 shadow-xl border rounded-3xl p-6">
          <h1 className=" text-xl text-center m-2 font-bold text-[#3B3486]">
            {student.name}
          </h1>

          {/* Main Info */}
          <div className=" my-6 flex items-center justify-between text-lg  rtl">
            <h4>
              رقم التليفون :{" "}
              <span className=" text-sky-900 font-bold mx-2">
                {student.phone}
              </span>
            </h4>
            <h4>
              {" "}
              عدد الباقات :{" "}
              <span className=" text-sky-900 font-bold mx-2">
                {packages.length}
              </span>
            </h4>
          </div>
          <hr className=" my-3 w-2/3 mx-auto"></hr>
          {/* Packages Info */}
          {money && (
            <div className=" p-4">
              <table className=" rtl w-full shadow-sm rounded-md">
                <thead>
                  <tr className=" bg-[#b2b2b350] p-2  grid grid-cols-12 items-center justify-between  ">
                    <th className=" text-start col-span-8  ">الباقة</th>
                    <th className=" text-start col-span-3  ">تاريخ الشراء</th>
                    <th className=" text-start col-span-1  ">السعر</th>
                  </tr>
                </thead>
                <tbody>
                  {packages.map((e, index) => {
                    total = total + +e.price;
                    return (
                      <tr
                        key={index}
                        className=" p-2 grid grid-cols-12 items-center justify-between "
                      >
                        <td className=" text-start font-bold col-span-8  text-lg">
                          {e.name}
                        </td>
                        <td className=" text-start font-bold  col-span-3 text-lg">
                          {e.today}
                        </td>
                        <td className=" text-start font-bold col-span-1  text-lg">
                          {e.price}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className=" rtl p-2 grid grid-cols-12 items-center justify-between bg-green-700/40 ">
                <h1 className=" text-start font-bold col-span-11  text-lg">
                  المجموع :
                </h1>
                <h1 className=" text-start font-bold col-span-1  text-lg">
                  {total}
                </h1>
              </div>
            </div>
          )}

          {/* Add Package */}
          <div className=" my-4 text-center">
            <button //Add Package to student BTN
              onClick={() => setOpen(true)}
              className=" mx-2 py-1 px-4 text-lg text-white shadow-md rounded-md bg-[#04868a]"
            >
              اضافة باقة
            </button>
            {chat && (
              <button //Add Package to student BTN
                className=" mx-2 py-1 px-4 text-lg text-white shadow-md rounded-md bg-sky-800"
              >
                <Link to={`/chat?id=${id}`}>محادثة</Link>
              </button>
            )}
          </div>
          <hr />

          <h1 className=" text-xl text-center m-2 font-bold text-[#3B3486]">
            اعادة تعيين كلمة السر
          </h1>
          <section className=" rtl flex items-center ">
            <input
              type="text"
              className="border-b w-72 mx-4 p-2 text-lg outline-none"
              value={password}
              placeholder="كلمة السر"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              onClick={resetPasswordHandeller}
              className=" my-4 bg-[#07464b] px-4 py-1 text-lg text-white rounded-lg shadow-md "
            >
              تغيير كلمة السر
            </button>
          </section>
          {sucsses && (
            <div className=" rtl my-3">
              <div className=" rtl bg-green-600 rounded-md shadow-md w-fit p-2 font-bold text-lg text-white ">
                <i className="fa-solid fa-circle-check mx-2"></i>
                تم تغيير كلمة السر للطالب بنجاح
              </div>
            </div>
          )}
        </div>
        {/* Add Package to student */}
      </div>
    );
  }
}
