import Cookies from "js-cookie";
import React, { createContext, useState } from "react";

export const login = createContext();

export default function LoginStore(props) {
  const isin = !!Cookies.get("token") || false;
  let typeOF = "";
  const inget = Cookies.get("dHlwZSBvZiBsb2c");

  if (inget === "dGhlIHR5cHIgaXMgdGVhY2hlcg") {
    typeOF = "teacher";
  } else if (inget === "dGhlIHR5cGUgaXMgc3R1ZGVudA") {
    typeOF = "student";
  }

  const [isLogged, setIsLogged] = useState(isin);
  const [type, setType] = useState(typeOF);
  return (
    <>
      <login.Provider value={{ isLogged, setIsLogged, type, setType }}>
        {props.children}
      </login.Provider>
    </>
  );
}
