import React from "react";
import ChatListComp from "./ChatListComponent";

export default function ChatList() {
  return (
    <div>
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">المحادثات </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      <div className=" m-4 rounded-2xl overflow-hidden shadow-lg border">
        <ChatListComp />
      </div>
    </div>
  );
}
