import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import SendedMessage from "../../../components/chat/SendedMessage";
import RecivedMessage from "../../../components/chat/RecivedMessage";
import Loader from "../../../components/Loader/Loader";
import Error from "../../Error/Error";
import * as signalr from "@microsoft/signalr";
import Cookies from "js-cookie";
import {
  hubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

export default function Communication() {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);

  let id = Cookies.get("id");
  let userid = Cookies.get("id");

  const fet = () => {
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/GetAllChatBetweenTwoUsers/${id}/30a9511b-4a0a-423c-ac14-0d3874f85598`
    ).then((res) => {
      if (res.status == 200) {
        res.json().then((data) => {
          setMessages(data);
          setLoad(false);
        });
      } else {
        setErr(true);
      }
    });
  };

  // start Connection with chat
  const conn = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    // .configureLogging(LogLevel.Information)
    .withUrl("https://biogeniusdotnet.biogeniuses.com/chatmessagehub", {
      skipNegotiation: true,
      transport: signalr.HttpTransportType.WebSockets,
    })
    .build();

  const addConnectionWithoutController = async (connectionId, userId) => {
    try {
      const dto = {
        connectionId: connectionId,
        userId: userId,
      };
      await conn.invoke("AddConnection", dto);
      // console.log("Connection added successfully.");
    } catch (error) {
      // console.error("Error adding connection:", error);
    }
  };

  const joinChatRoom = async () => {
    try {
      await conn.start().then(() => {});

      const id = await conn.invoke("GetConnectionId");
      console.log(id.value.value);
      await addConnectionWithoutController(id.value.value, userid);

      conn.on("ReceiveMessage", (message, id) => {
        // console.log(`Received message : ${message} from ${id} `);
        fet();
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Join

  useEffect(() => {
    joinChatRoom();
    // setConn(conn);
  }, []);
  //
  //
  // Getting Messages
  //
  //
  const scrollContainerRef = useRef(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  };

  // Getting Messages

  useEffect(() => {
    setLoad(true);
    fet();
  }, []);
  // maping Messages

  const messageList = messages.map((e, index) => {
    return (
      <div key={index}>
        {e.senderId !== id ? (
          <>
            <RecivedMessage student={false} message={e.message} />
          </>
        ) : (
          <>
            <SendedMessage message={e.message} />
          </>
        )}
      </div>
    );
  });

  // Message
  const [message, setMessage] = useState("");
  //
  //
  // Send Message
  //
  //

  const sendHandeller = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      senderId: id,
      receiverId: "30a9511b-4a0a-423c-ac14-0d3874f85598",
      message: message,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/SendMessage",
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
      }
    });

    setMessages([
      ...messages,
      {
        senderId: id,
        receiverId: "30a9511b-4a0a-423c-ac14-0d3874f85598",
        message: message,
      },
    ]);
    setMessage("");
  };
  if (!id || err) {
    return <Error />;
  } else {
    return (
      <div className="  h-screen pt-24 mt-[-6.25rem] flex ">
        {/* Lading */}
        {load && <Loader />}

        {/*  */}
        {/* Message Section */}
        <div className=" relative w-full border  ">
          {/* Name Tag */}
          <section className=" absolute top-0 w-full mt-[-6px] rtl bg-gray-100 flex items-center justify-between p-3 border-b shadow-lg text-xl font-bold text-sky-700 ">
            {/* <div className=" bg-gray-400 h-8 w-8 rounded-full my-2 flex items-center justify-center text-sm text-sky-800">
            <i className="fa-solid fa-graduation-cap"></i>
          </div> */}
            <p> </p>
            <Link to="/">
              <i className="fa-solid fa-arrow-left"></i>
            </Link>
          </section>
          {/* Messages */}
          <section className=" sc  pb-20 pt-12 h-full">
            <div
              ref={scrollContainerRef}
              className="  overflow-y-scroll px-2 h-full"
            >
              {messageList}
            </div>{" "}
          </section>
          {/* Send field */}
          <section className=" absolute bottom-0 w-full px-2  my-1 ">
            <div className=" bg-gray-200 w-full rounded-xl p-3 ">
              <form onSubmit={sendHandeller} className=" flex items-center">
                <input //Message text place
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="اكتب الرسالة"
                  className=" rtl bg-white/0 border-b border-gray-300 focus:border-sky-800 text-lg p-2 outline-none w-full"
                ></input>
                <button //Send Button
                  disabled={!message}
                  className=" mx-2 text-sky-800 text-2xl"
                >
                  <i className="fa-solid fa-paper-plane"></i>
                </button>
              </form>{" "}
            </div>
          </section>
        </div>
      </div>
    );
  }
}
