import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Assestens() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //
  // Getting Data
  //

  const [students, setStudents] = useState([]);
  const [forSearch, setForSearch] = useState([]);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Auth/GetUsersByRole/Assistant",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        res.json().then((data) => {
          setForSearch(data);
          setStudents(data);
        });
      }
    });
  }, [refresh]);

  //
  // Search
  //
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const searchHandeller = (e) => {
    e.preventDefault();
    let se = forSearch;
    if (phone) {
      se = se.filter((e) => e.phoneNumber && e.phoneNumber.includes(phone));

      //   console.log(se)
    }
    if (name) {
      se = se.filter((e) => e.fullName.includes(name));
    }
    setStudents(se);
  };

  const [openDelte, setOpenDelte] = useState(false);
  const [DelteId, setDelteId] = useState();

  const openDeleteUnitHandeller = (id) => {
    setOpenDelte(true);
    setDelteId(id);
  };

  const deleteUnitHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Auth/DeleteUser/${DelteId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDelte(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const mapedStudent = students.map((e, index) => {
    return (
      <tr
        key={index}
        className=" p-2 grid grid-cols-12 items-center justify-between "
      >
        <td className=" text-start col-span-7 underline  border-black font-bold  text-lg">
          {/* <Link to={`/student?id=${e.id}`}>{e.fullName}</Link> */}
          <button className=" underline" onClick={() => setAssID(e.id)}>
            {" "}
            {e.fullName}
          </button>
        </td>
        <td className=" text-start col-span-4 font-bold    text-lg  ">
          {e.phoneNumber}
        </td>
        <td className=" text-end col-span-1 font-bold  text-red-600   text-lg  ">
          <button onClick={() => openDeleteUnitHandeller(e.id)}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </td>
      </tr>
    );
  });

  const [assId, setAssID] = useState("");
  let pe = [
    { en: "EditCourse", ar: "تعديل الكورسات" },
    { en: "DeleteCourse", ar: "حذف الكورسات" },
    { en: "Report", ar: "تقارير الطلاب" },
    { en: "Money", ar: "التقارير المالية" },
    { en: "Chat", ar: "المحادثات" },
  ];
  const [assPrems, setAddPerms] = useState([]);
  useEffect(() => {
    if (assId) {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Auth/GetPermissionsForUser/${assId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setAddPerms(data);
          setLoad(false);
        });
    }
  }, [assId]);
  return (
    <div>
      {" "}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          المدرسين المساعدين{" "}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      {/*  */}
      {/* search */}
      {/*  */}
      <div className=" rtl">
        <form //Search Form
          onClick={searchHandeller}
          className=" text-center p-6 md:flex items-center justify-between"
        >
          <div>
            <input //search by name
              className="border-b mx-1 p-2 text-lg outline-none"
              placeholder="الاسم"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
            <input //search by phone
              className="border-b mx-1 p-2 text-lg outline-none"
              placeholder="رقم التليفون"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="text"
            />
            <button //Search BTN
              className=" my-4 bg-[#07464b] px-4 py-1 text-lg text-white rounded-lg shadow-md "
            >
              بحث <i className="fa-solid fa-magnifying-glass x-2"></i>
            </button>
          </div>
          <div>
            <Link to={"/add-assesten"}>
              <button className=" my-4 bg-green-400 px-4 py-1 text-lg text-white rounded-lg shadow-md ">
                اضافة مساعد
              </button>
            </Link>
          </div>
        </form>
      </div>
      {/*  */}
      {/* Students */}
      {/*  */}
      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2  grid grid-cols-12 items-center justify-between  ">
              <th className=" text-start col-span-7  ">المساعد</th>
              <th className=" text-start col-span-4 border  ">رقم التليفون</th>
              <th className=" text-end col-span-1 border  "> حذف</th>
            </tr>
          </thead>
          <tbody>{mapedStudent}</tbody>
        </table>
      </div>
      {openDelte && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-12 my-4 md:mx-24">
              حذف هذا المساعد
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpenDelte(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
      {assId && (
        <div className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20">
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-xl text-sky-800 font-bold text-center mb-8">
              الصلاحيات
            </h1>
            <section className="mx-6 p-3">
              {assPrems.map((e, index) => {
                let i = pe.filter((ass) => ass.en === e.permission);

                if (i.length > 0) {
                  return (
                    <div className=" rtl  flex items-center">
                      <h1 className=" text-xl font-bold w-56 ">{i[0].ar}</h1>
                      <i className="fa-solid text-2xl font-bold fa-check text-green-800"></i>
                    </div>
                  );
                }
              })}
            </section>
            <div className=" text-center">
              <button //Cancel Btn
                onClick={() => setAssID("")}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
