import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

export default function Revisions() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  //
  // Filtering
  //
  const [year, setYear] = useState(1); // year
  const [semster, setSemster] = useState(1); //semeter

  const [rev, setRev] = useState([]);

  var myHeaders = new Headers();

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/GetAllRevisions/${year}/${
        year == 3 ? "" : semster
      }`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setRev(data);
            // console.log(data);
            setLoad(false);
          });
        } else {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  }, [year, semster, refresh]);

  // Delete

  const [open, setOpen] = useState(false);
  const [deltedId, setDeltedId] = useState(false);
  const openDeleteUnitHandeller = (id) => {
    setOpen(true);
    setDeltedId(id);
  };

  const deleteUnitHandeller = () => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Reviews/DeleteRevision/${deltedId}`,
      {
        method: "POST",
      }
    ).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setLoad(false);
        setOpen(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // Maping
  const unitesMaped = rev.map((e, index) => {
    return (
      <tr className="p-2 grid grid-cols-8 md:grid-cols-12" key={index}>
        <td className=" text-lg font-bold text-[#3B3486] text-start col-span-6 md:col-span-10">
          {e.title}
        </td>

        <td className=" text-center text-lg font-bold text-[#3B3486]  col-span-1">
          <Link to={`/edit-revi?id=${e.id}`}>
            <i className="fa-solid fa-file-pen"></i>
          </Link>
        </td>
        <td className=" text-center text-lg font-bold text-red-600 col-span-1">
          <button onClick={() => openDeleteUnitHandeller(e.id)}>
            <i className="fa-solid fa-trash"></i>
          </button>
        </td>
      </tr>
    );
  });
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          جميع المراجعات
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/*  */}
      {/* Lessons filter */}
      {/*  */}

      <div className=" flex items-center justify-between p-4">
        <div className="">
          <button className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400">
            {" "}
            {/* New Lesson */}
            <Link to="/add-revision">
              مراجعة جديدة <i className="fa-solid fa-file-circle-plus"></i>
            </Link>
          </button>
        </div>
        <div className=" w-52 md:w-auto rtl">
          {" "}
          {/* Filters*/}{" "}
          <select //Year
            value={year}
            onChange={(e) => {
              setYear(e.target.value);
              //   if (e.target.value == 3) {
              //     setSemster(0);
              //   }
            }}
            className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الصف
            </option>
            <option value={1}>الاول الثانوي</option>
            <option value={2}>الثاني الثانوي</option>
            <option value={3}>الثالث الثانوي</option>
          </select>
          {year != 3 && (
            <select //semster
              value={semster}
              onChange={(e) => setSemster(e.target.value)}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                الفصل الدراسي
              </option>
              <option value={1}>الاول </option>
              <option value={2}>الثاني </option>
              {/* <option value="revision"> مراجعة</option> */}
            </select>
          )}
        </div>
      </div>
      {/*  */}
      {/* Lessons  */}
      {/*  */}
      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
              <th className=" text-start col-span-6 md:col-span-10">
                المراجعة
              </th>
              <th className=" col-span-1">تعديل</th>
              <th className=" col-span-1">حذف</th>
            </tr>
          </thead>
          <tbody>{unitesMaped}</tbody>
        </table>
      </div>
      {open && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-12 my-4 md:mx-24">
              حذف هذه المراجعة
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpen(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
