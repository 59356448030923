import React from "react";
import { Link } from "react-router-dom";

export default function Sub() {
  return (
    <>
      <div className=" mt-20 text-center p-4 md:p-8 bg-slate-500/20">
        <h1 className=" rtl my-6 text-3xl  mx-6  text-[#ff005f] font-bold">
          معنا مفتاحك لتحقيق طموحاتك , من الثانوية العامة الي مستقبل باهر
        </h1>
        <div className=" flex items-center justify-around rtl">
          <section className=" flex items-center justify-center">
            <a
              className=" mx-2"
              target="_blank"
              href="https://www.facebook.com/share/zHWdqStrsAyKZ4BP/?mibextid=qi2Omg"
            >
              <section className=" text-3xl h-12 w-12 rounded-full shadow-lg flex items-center justify-center bg-gray-400 text-sky-800 ">
                <i className="fa-brands fa-facebook-f"></i>
              </section>
            </a>
            <a
              className=" mx-2"
              target="_blank"
              href="https://www.instagram.com/biogeniuses_?igsh=Nm0wZ3RzcWQ2dzVy"
            >
              <section className=" text-3xl h-12 w-12 rounded-full shadow-lg flex items-center justify-center bg-gray-400 text-[#f20057] ">
                <i className="fa-brands fa-instagram"></i>
              </section>
            </a>
            <a
              className=" mx-2"
              target="_blank"
              href="https://youtube.com/@biogeniuses?si=OXMmvzT9y4Pz8Ulz"
            >
              <section className=" text-3xl h-12 w-12 rounded-full shadow-lg flex items-center justify-center bg-gray-400 text-red-700 ">
                <i className="fa-brands fa-youtube"></i>
              </section>
            </a>
            <a
              className=" mx-2"
              target="_blank"
              href="https://www.tiktok.com/@biogeniuses?_t=8o2MlUQRc2i&_r=1"
            >
              <section className=" text-3xl h-12 w-12 rounded-full shadow-lg flex items-center justify-center bg-gray-400 text-black ">
                <i class="fa-brands fa-tiktok"></i>
              </section>
            </a>
          </section>
          <section className="">
            <h1 className=" text-lg font-bold text-[#ff005f]/60">روابط هامة</h1>
            <button className="  shadow-md text-lg m-2 p-2 text-white rounded bg-[#00aab3]">
              {/* <Link to="/signup">
                انشاء حساب <i className="fa-solid fa-user-plus"></i>
              </Link> */}
              <Link to="/us">من نحن</Link>
            </button>
            <button className="  shadow-md text-lg m-2 p-2 text-white rounded bg-[#00aab3]">
              {/* <Link to="/signup">
                انشاء حساب <i className="fa-solid fa-user-plus"></i>
              </Link> */}
              <Link to="/police">السياسات</Link>
            </button>
          </section>
        </div>
      </div>
    </>
  );
}
