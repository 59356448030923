import React from "react";
import { Link } from "react-router-dom";

export default function Excers(props) {
  const ExamsMaped = props.exc.map((e, index) => {
    return (
      <div key={index}>
        <Link className="" to={`/exercise?id=${e.id}&lesson=${props.ex}&unit=${props.unit}&course=${props.course}`}>
          <div className=" ma hover:bg-white hover:shadow-md hover:text-[#9ed04e] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
            <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#9ed04e]">
              <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                {index + 1}
              </h1>
            </div>
            <div className=" flex items-center justify-between w-full">
              <h1 className=" text-xl"> {e.title}</h1>
              <i className="  mx-8 ani text-[#9ed04e]/0 rotate-180 fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <div className="mb-10  rounded-xl py-6 mx-6 md:mx-auto md:w-3/4">
      <hr />
      <h1 className=" text-2xl text-center text-[#9ed04e] font-bold my-10">
        الاختبارات
      </h1>
      {/* Ecxercices */}
      <div className=" shadow-md bg-[#9ed04e]/20  rounded-3xl p-8  my-8 rtl">
        {ExamsMaped}
      </div>
    </div>
  );
}
