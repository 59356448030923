import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import Benfi from "./Benfi";
import Lectures from "./Lectures";
import Exams from "./Exams";
import Revesion from "./Revesion";
import Discu from "./Discu";
import Sub from "./Sub";
import Lessins from "./Lessins";
import IntoInHomePage from "../Teacher/Intro/IntoInHomePage";
import DNA from "../../components/Home/DNA";
import Teacher from "./Teacher";
import Status from "./Status";
import Courses from "./Courses";

export default function Home() {
  const [openDrop, setOpenDrop] = useState(false);
  return (
    <div>
      {/*  */}
      {/* Main */}
      {/*  */}
      {/* <div className=" md:flex items-center justify-between pt-20   bg-[#eef396b4]">

        <div className=" rtl md:w-1/2 p-12  ">
          <h1 className=" font-bold text-[#0eaaad] text-3xl">Bio Genius</h1>
          <h4 className=" md:w-[60%] text-lg my-8">
            هي منصة متخصصة لتدريس مادة الاحياء للصف الاول والثاني والثالث
            الثانوي
          </h4>
          <Link to="/">
            <button className=" shadow-2xl m-2 p-2 text-white rounded bg-[#59B4C3]">
              اشتراك <i className="fa-solid fa-right-to-bracket"></i>
            </button>
          </Link>
        </div>
        <div className="  md:w-1/2 flex items-center justify-center">
          <img
            className=" h-60 md:h-fit"
            src="imgs/logo2.png"
            alt=" home page "
          />
        </div>
      </div>
      <div className=" h-20 md:h-32 bg-[#eef396b4] rounded-b-[100%]"></div> */}

      <div className=" relative back pt-0">
        {/*  */}
        {/* NavBar */}
        {/*  */}
        <div className=" fixed right-6 bottom-6 text-green-500 py-3 px-4 opacity-75 z-50 hover:opacity-100 bg-gray-200 shadow-xl rounded-full">
          <a
            target="_blank"
            href="https://wa.me/201118028630"
          >
            <i className="fa-brands fa-whatsapp text-2xl md:text-4xl"></i>
          </a>
        </div>

        <div className="  bg-white shadow-md  top-0 left-0 w-full py-2 px-4">
          <div className=" flex items-center justify-between">
            <section className=" text-lg ">
              <img
                className="  w-28"
                src="./imgs/logo.png"
                alt="Logo BioGenius"
              />
            </section>
            <section className="hidden md:flex">
              <Link to="/login">
                <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                  تسجيل الدخول <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </Link>
              <Link to="/signup">
                <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                  انشاء حساب <i className="fa-solid fa-user-plus"></i>
                </button>
              </Link>
            </section>
            <section className=" md:hidden">
              <button
                onClick={() => setOpenDrop(!openDrop)}
                className=" text-2xl p-4"
              >
                {openDrop ? (
                  <i className="fa-solid fa-xmark"></i>
                ) : (
                  <i className="fa-solid fa-bars"></i>
                )}
              </button>
            </section>
          </div>
          <div
            className={` ${
              openDrop ? "h-44" : "h-0"
            } transition-all ease-in-out duration-500  text-center overflow-hidden`}
          >
            <section>
              <Link to="/login">
                <button className=" my-6 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                  تسجيل الدخول <i className="fa-solid fa-right-to-bracket"></i>
                </button>
              </Link>
            </section>
            <section>
              <Link to="/signup">
                <button className=" my-4 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                  انشاء حساب <i className="fa-solid fa-user-plus"></i>
                </button>
              </Link>
            </section>
          </div>
        </div>

        <div className=" relative md:flex items-center justify-between">
          <div className=" rtl md:w-2/3 p-8  ">
            <h1 className=" font-bold text-left text-[#fff] sh text-5xl">
              Bio Geniuses
            </h1>
            <h4 className=" md:w-[80%] text-xl text-[#fff]  my-8">
              نفسك تجيب مجموع وتحقق حلمك ؟
              <br />
              انضم لبرامجنا التدريبية الجديدة واكتسب المهارات العلميه والعملية
              اللي تحتاجها عشان تجيب الدرجات النهائية وتطور حياتك المهنية. معانا
              هتدخل الامتحان وانت مطمن
            </h4>
            <div className=" mb-32 md:mb-48 mt-10 text-white text-2xl font-bold sh flex justify-around mx-auto w-[80%] md:w-[60%]">
              <h1>اجتهاد </h1>
              <h1 className=" mx-20">اتقان </h1>
              <h1>تفوق </h1>
            </div>

            {/* <button className=" absolute bottom-12 cursor-pointer z-50 md:mb-12 mt-20 shadow-md text-lg m-2 p-2 text-white rounded bg-[#00aab3]">
              <Link to="/signup">
                انشاء حساب <i className="fa-solid fa-user-plus"></i>
              </Link> */}
            {/* <Link to="/us">من نحن</Link> */}
            {/* </button> */}
          </div>
          <div className=" hidden md:flex   md:w-1/3  items-center justify-center">
            <img
              className=" h-60 md:h-fit"
              // src="imgs/logo.png"
              src="imgs/girl.png"
              alt=" home page "
            />
          </div>
        </div>
        <div className=" z-0  absolute w-full bottom-0  ">
          <img className=" hidden md:flex md:w-full " src="./imgs/wave.svg" />
          <img className="   md:hidden " src="./imgs/wave2.svg" />
        </div>
      </div>

      {/*  */}
      {/* Benfits */}
      {/*  */}
      <Benfi />
      {/* Detatils */}
      {/* <Lectures /> */}
      <Teacher />
      <Lessins />
      <Courses />
      {/* <Exams />
      <Revesion />
      <Discu /> */}
      <Status />
      <IntoInHomePage />
      <Sub />
    </div>
  );
}
