import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { useLocation } from "react-router";
import Cookies from "js-cookie";
import Error from "../../Error/Error";
import { Link } from "react-router-dom";

export default function Course() {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("course");
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);

  const token = Cookies.get("token");

  const [courseData, setCourseData] = useState([]);
  useEffect(() => {
    setLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://student.biogeniuses.com/api/checkDataStudent/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        console.log(res);
        res.json().then((data) => {
          setCourseData(data);
          console.log(data);
          setLoad(false);
        });
      } else {
        setLoad(false);
        setErr(true);
      }
    });
  }, []);

  let lessonsNum = 0;
  let examsNum = 0;
  const lessons = courseData.map((e) => {
    // console.log(e);
    return e.lessons.map((le, index) => {
      lessonsNum = lessonsNum + 1;
      return (
        <div
          className=" relative shadow-md rtl p-6 mx-4 md:mx-6 my-2 rounded-3xl bg-[#fdebf3] col-span-1"
          key={index}
        >
          <div className=" overflow-  rounded-full flex h-8 w-8 md:h-10 md:w-10 justify-center relative text-white  bg-[#e51d74]">
            <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-6px]">
              {index + 1}
            </h1>
          </div>
          <hr className=" w-[70%] mx-auto  my-4 border-black/20"></hr>
          <div className=" mb-20">
            <h1 className=" text-[#e51d74] text-2xl my-4 font-bold text-center">
              {le.title}
            </h1>
            {/* <h4 className=" my-6 text-xl text-[#e51d74]/50">{e.unit.title}</h4> */}
          </div>

          <div className=" absolute bottom-2 left-0 w-full px-6">
            <Link to={`/lesson?id=${le.id}&unit=${e.id}&course=${id}`}>
              <div className=" bg-white shadow-md text-[#e51d74] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className=" mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    الذهاب للدرس
                  </h1>
                  <i className="  mx-8 an text-[#e51d74] rotate-180 fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  });

  const exams = courseData.map((e) => {
    return e.exams.map((ex, index) => {
      examsNum = examsNum + 1;
      return (
        <div key={index}>
          <Link className="" to={`/exam?id=${ex.id}&unit=${e.id}&course=${id}`}>
            <div className=" ma hover:bg-white hover:shadow-md hover:text-[#e51d74] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#e51d74]">
                <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                  {index + 1}
                </h1>
              </div>
              <div className=" flex items-center justify-between w-full">
                <h1 className=" text-xl"> {ex.title}</h1>
                <i className="  mx-8 an text-[#fdebf3] rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      );
    });
  });

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}
        {/* Title */}

        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
            محتويات الكورس
          </h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {/* Lessons */}
        {lessonsNum !== 0 && (
          <>
            <h1 className=" text-lg font-bold text-center">الدروس</h1>

            <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
              <>{lessons}</>
            </div>
          </>
        )}
        {examsNum !== 0 && (
          <>
            <h1 className=" text-lg font-bold text-center">الامتحانات</h1>
            <div className=" shadow-md bg-[#fdebf3] mx-6 md:mx-40 rounded-3xl p-8  my-8 rtl">
              {exams}
            </div>
          </>
        )}
        {courseData.length === 0 && (
          <h1 className=" text-center font-bold my-24 text-sky-900 text-xl">
            {" "}
            سوف يتم اضافة محتويات الكورس قريبا
          </h1>
        )}
      </div>
    );
  }
}
