import React from "react";
import DNA from "../../components/Home/DNA";

export default function TeacherInfo() {
  return (
    <div>
      <img
        className=" mx-auto h-48 w-48 md:h-72 md:w-72 lg:w-96 lg:h-96 my-12 shadow-md rounded-full border-4 border-black/20  "
        src="./imgs/teacher.jpg"
        alt="teacher"
      />
      <h1 className=" rtl my-6 text-5xl sh mx-6 md:text-8xl  text-[#ff005f] font-bold">
        حاتم احمد جاد
      </h1>
      <div className="  rtl p-4 flex items-center justify-between">
        <div className=" w-full md:w-2/3">
          <div className="  p-8 shadow-md rounded-lg w-full bg-[#ff006c]">
            <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
              استاذ احياء هيخليك تحب المادة وتتفوق فيها!
            </h1>
            <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
              {" "}
              معلم خبير خبرة سنين في تدريس الاحياء للثانوية العامة.
            </h1>
            <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
              {" "}
              بيؤمن بإنّ التعلّم رحلة ممتعة، بيشرح كل حاجة ببساطة وفهم عميق،
              هتستمتع وتتعلم في نفس الوقت.
            </h1>
            <h1 className=" lg:w-[80%] text-xl text-white font-semibold my-3">
              {" "}
              هتكتشف متعة التعلّم وتحقق أحلامك!
            </h1>
          </div>
        </div>
        <div className=" hidden md:flex items-center  py-6 ml-2  md:mx-auto">
          <DNA />
        </div>
      </div>
    </div>
  );
}
