import React, { useContext, useEffect, useState } from "react";
import { login } from "../../../utils/LoginStore";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import Policises from "../../Home/Policises";

export default function SignUp() {
  const [load, setLoad] = useState(false);
  const logged = useContext(login);
  const navigation = useNavigate();

  const [error, setError] = useState(false);

  const closError = () => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  };

  useEffect(() => {
    if (logged.isLogged) {
      navigation("/");
      // return;
    }
  }, []);

  // Handlling SignUp

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPassworConfirmed] = useState("");
  const [passwordNotConfirmed, setPassworNotConfirmed] = useState(false);
  const [classRoom, setClassRoom] = useState();
  const [poli, setPoli] = useState(false);

  const signUPHandeller = (e) => {
    e.preventDefault();
    setLoad(true);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone", phone);
    formdata.append("classroom", classRoom);
    formdata.append("password", password);
    formdata.append("confirm", passwordConfirmed);
    formdata.append("email", mail);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/registertudent",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        var formd = new FormData();
        formd.append("password", password);
        formd.append("email", mail);

        var request = {
          method: "POST",
          body: formd,
          redirect: "follow",
        };

        fetch(
          "https://student.biogeniuses.com/api/auth/loginStudent",
          request
        ).then((res) => {
          if (res.status === 200) {
            setLoad(false);
            const thisTime = new Date().getTime();
            res.json().then((data) => {
              Cookies.set("token", data.access_token, { expires: 5 / 24 });
              Cookies.set("name", data.student.name, { expires: 5 / 24 });
              Cookies.set("id", data.student.id, { expires: 5 / 24 });
              Cookies.set("name", data.student.name, { expires: 5 / 24 });
              Cookies.set("mail", data.student.email, { expires: 5 / 24 });
              Cookies.set("phone", data.student.phone, { expires: 5 / 24 });
              Cookies.set("dHlwZSBvZiBsb2c", "dGhlIHR5cGUgaXMgc3R1ZGVudA", {
                expires: 5 / 24,
              });
              Cookies.set("expirein", thisTime + 5 * 60 * 60 * 1000, {
                expires: 5 / 24,
              });
              logged.setIsLogged(true);
              logged.setType("student");
              navigation("/courses");
            });
          } else {
            setLoad(false);
            setError(true);
            closError();
          }
        });
      } else {
        setLoad(false);
        setError(true);
        closError();
      }
    });
  };
  if (logged.isLogged) {
    // navigation(-1);
    return;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}
        {/* Nav Bar */}
        <div className=" p-4 md:p-8">
          <img
            className=" w-24 md:w-32"
            src="./imgs/logo.png"
            alt="Logo Bio Genuis"
          />
        </div>
        {/* Login Form  */}
        <div className=" fixed right-6 bottom-6 text-green-500 py-3 px-4 opacity-75 z-50 hover:opacity-100 bg-gray-200 shadow-xl rounded-full">
          <a target="_blank" href="https://wa.me/201118028630">
            <i className="fa-brands fa-whatsapp text-2xl md:text-4xl"></i>
          </a>
        </div>

        <div className="  my-6 md:mb-12 md:mt-[-1rem]  flex items-center justify-center">
          <div className="  p-8 shadow-xl rounded-xl border">
            <h1 className=" text-center text-xl px-12 md:px-44 text-[#07a9b3]">
              انشاء حساب
            </h1>
            <hr className=" my-6" />

            {/* SignUp Form */}
            <div className=" rtl">
              <form onSubmit={signUPHandeller}>
                <div //Name
                  className=" my-4 flex items-center border bg-slate-50 rounded-md "
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-user"></i>
                  <input //Name
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="الاسم"
                    type="text"
                  />
                </div>
                <div //Mail
                  className=" my-4 flex items-center border bg-slate-50 rounded-md "
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-at"></i>
                  <input //Email
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={mail}
                    required
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="البريد الالكتروني"
                    type="email"
                  />
                </div>
                <div //phone
                  className=" my-4 flex items-center border bg-slate-50 rounded-md "
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-mobile-screen-button"></i>
                  <input //Email
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={phone}
                    required
                    minLength={11}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder=" رقم التليفون"
                    type="text"
                  />
                </div>
                <div //Class
                  className=" my-4 flex items-center border bg-slate-50 rounded-md "
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-school"></i>
                  <select //class
                    placeholder="hgwt"
                    value={classRoom}
                    onChange={(e) => setClassRoom(e.target.value)}
                    className={` rounded-md bg-slate-50 w-full outline-none p-2 ${
                      classRoom ? "text-sky-800" : "text-black/40"
                    } font-bold`}
                  >
                    <option className=" text-black" selected hidden>
                      الصف
                    </option>
                    <option
                      className=" text-sky-800 text-lg p-1 font-bold"
                      value={1}
                    >
                      الاول الثانوي
                    </option>
                    <option
                      className=" text-sky-800 text-lg p-1 font-bold"
                      value={2}
                    >
                      الثاني الثانوي
                    </option>
                    <option
                      className=" text-sky-800 text-lg p-1 font-bold"
                      value={3}
                    >
                      الثالث الثانوي
                    </option>
                  </select>
                </div>
                <div //Password
                  className=" my-4 flex items-center border bg-slate-50 rounded-md "
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-lock"></i>
                  <input //password
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="كلمة السر"
                    type="password"
                  />
                </div>
                {password.length < 6 && (
                  <h4 className=" text-xs text-black/70 w-80 text-center mx-auto">
                    كلمة السر يجب ان تكون اكثر من 5 احرف
                  </h4>
                )}
                <div //RePassword
                  className={` my-4 flex items-center border bg-slate-50 ${
                    password !== passwordConfirmed &&
                    "border-red-500/50 shadow-red-400 "
                  } rounded-md `}
                >
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-check"></i>
                  <input //Repassword
                    className={` rounded-md bg-slate-50 ${
                      password !== passwordConfirmed && " shadow-red-400  "
                    } w-full outline-none p-2 text-sky-800 font-bold`}
                    value={passwordConfirmed}
                    required
                    onChange={(e) => setPassworConfirmed(e.target.value)}
                    placeholder=" اعادة كلمة السر"
                    type="password"
                  />
                </div>
                <div //Password
                  className=" my-4 flex items-center  rounded-md "
                >
                  <input //accept
                    id="accept"
                    className=" rounded-md bg-slate-50  mx-2 outline-none p-2 text-sky-800 font-bold"
                    checked={poli}
                    required
                    onChange={(e) => setPoli(e.target.checked)}
                    placeholder="كلمة السر"
                    type="checkbox"
                  />
                  <Link className=" font-bold text-gray-900" to="/police">
                    اوافق علي الشروط والسياسات
                  </Link>
                </div>
                {/* Error */}
                {error && (
                  <div className=" bg-red-600 opacity-80 p-2 text-white rounded-md">
                    <i className=" ml-2 fa-solid fa-circle-exclamation"></i>
                    بعض البيانات خطأ او مسجلة بالفعل
                  </div>
                )}

                <div className=" text-center mt-12 mb-2">
                  <button //Login BTN
                    disabled={!poli}
                    className=" disabled:opacity-80 py-2 px-4 bg-[#219b2b] text-white rounded-md "
                  >
                    انشاء الحساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </div>
              </form>
            </div>

            <hr className=" my-6" />
            <div className=" text-sky-900 flex rtl text-center py-2 ">
              <h1> لديك حساب؟ </h1>{" "}
              <Link className=" font-bold" to={"/login"}>
                تسجيل دخول
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
