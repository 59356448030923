import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Error from "../Error/Error";
import VideoViaLink from "../../components/Lesson/VideoViaLink";
import Images from "../../components/Lesson/Images";

export default function ShowfreeLesson() {
  const [load, setLoad] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const course = new URLSearchParams(search).get("course");
  const [err, setErr] = useState(false);
  //
  // MAIN DATA
  //

  const [title, setTitle] = useState("");
  const [Videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${course}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          let less = data;

          less = less.filter((e) => e.id == id);
          // console.log(less);
          setTitle(less[0].title);
          setVideos(less[0].videos);
          setImages(less[0].images);
          setExcercieses(less[0].images);

          setLoad(false);
        });
      } else {
        setErr(true);
        setLoad(false);
      }
    });
  }, []);

  const videoMaped = Videos.map((e, index) => {
    return (
      <VideoViaLink
        key={index}
        link={"https://biogeniusdotnet.biogeniuses.com/" + e.videoUrl}
        desc={e.description}
      />
    );
  });
  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {" "}
        {load && <Loader />}
        <div className="  bg-white shadow-md  top-0 left-0 w-full py-2 px-4">
          <div>
            <div className=" flex items-center justify-between">
              <section className=" text-lg ">
                <Link to="/">
                  <img
                    className="  w-28"
                    src="./imgs/logo.png"
                    alt="Logo BioGenius"
                  />
                </Link>
              </section>
              <section className="hidden md:flex">
                <Link to="/login">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
                <Link to="/signup">
                  <button className=" shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
              <section className=" md:hidden">
                <button
                  onClick={() => setOpenDrop(!openDrop)}
                  className=" text-2xl p-4"
                >
                  {openDrop ? (
                    <i className="fa-solid fa-xmark"></i>
                  ) : (
                    <i className="fa-solid fa-bars"></i>
                  )}
                </button>
              </section>
            </div>
            <div
              className={` ${
                openDrop ? "h-44" : "h-0"
              } transition-all ease-in-out duration-500  text-center overflow-hidden`}
            >
              <section>
                <Link to="/login">
                  <button className=" my-6 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#9bd14b]">
                    تسجيل الدخول{" "}
                    <i className="fa-solid fa-right-to-bracket"></i>
                  </button>
                </Link>
              </section>
              <section>
                <Link to="/signup">
                  <button className=" my-4 w-36 shadow-md  m-2 p-2 text-white rounded bg-[#00aab3]">
                    انشاء حساب <i className="fa-solid fa-user-plus"></i>
                  </button>
                </Link>
              </section>
            </div>
          </div>
        </div>
        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-center text-2xl m-2 font-bold text-[#3B3486]">
            {title}
          </h1>
          <hr className=" m-2 w-8 md:w-12 lg:w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {/* Videos List */}
        <div>{videoMaped}</div>
        {/* Gallery */}
        <div>{images.length !== 0 && <Images images={images} />}</div>
        {/* Ecxercises */}
        <div>
          {/* {excercieses.length !== 0 && <Excers ex={id} exc={excercieses} />} */}
        </div>
        <div className=" text-center">
          <Link className="text-center" to={`/login`}>
            <button className=" py-2 px-10 bg-green-800 text-white text-xl rounded-md shadow-lg mb-6">
              تسجيل لعرض محتوي الكورس كامل
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
