import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";

export default function ReportsCourse() {
  const [load, setLoad] = useState(false);

  //
  // Getting Data
  //

  const [courses, setCourses] = useState([]);
  const [forSearch, setForSearch] = useState([]);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/auth/reportForEachPackage",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        res.json().then((data) => {
          setForSearch(data);
          setCourses(data);
        });
      }
    });
  }, []);

  //
  // Search
  //
  const [name, setName] = useState("");

  const searchHandeller = (e) => {
    e.preventDefault();
    let se = forSearch;
    if (name) {
      se = se.filter((e) => e.name.includes(name));
    }
    setCourses(se);
  };

  //
  // Maping
  //

  const mapedStudent = courses.map((e, index) => {
    return (
      <tr key={index} className=" p-2 flex items-center justify-between ">
        <td className=" text-start font-bold text-sky-600 text-lg">{e.name}</td>
        <td className=" text-center font-bold px-10   text-lg  ">{e.count}</td>
      </tr>
    );
  });

  return (
    <div>
      {load && <Loader />}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          تقارير الكورسات{" "}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/*  */}
      {/* search */}
      {/*  */}

      <div className=" rtl">
        <form //Search Form
          onClick={searchHandeller}
          className=" text-center p-6 md:flex items-center justify-between"
        >
          <div>
            <input //search by name
              className="border-b mx-1 p-2 text-lg outline-none"
              placeholder="الاسم"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>
          <div>
            <button //Search BTN
              className=" my-4 bg-[#07464b] px-4 py-1 text-lg text-white rounded-lg shadow-md "
            >
              بحث <i className="fa-solid fa-magnifying-glass x-2"></i>
            </button>
          </div>
        </form>
      </div>

      <hr className=" my-8 w-3/4 mx-auto" />

      {/*  */}
      {/* Courses */}
      {/*  */}
      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2  flex items-center justify-between  ">
              <th className=" text-start  ">الكورس</th>
              <th className=" text-center border  ">عدد مرات الشراء</th>
            </tr>
          </thead>
          <tbody>{mapedStudent}</tbody>
        </table>
      </div>
    </div>
  );
}
