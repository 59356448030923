import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Matrail() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const refreshHandeller = () => {
    setRefresh(!refresh);
  };

  const [matrials, setMatrails] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(`https://biogeniusdotnet.biogeniuses.com/api/Materials/GetAll`).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setLoad(false);
            setMatrails(data);
          });
        }
      }
    );
  }, [refresh]);

  //
  // Delete
  //
  const [openDelte, setOpenDelte] = useState(false);
  const [DelteId, setDelteId] = useState();

  const openDeleteUnitHandeller = (id) => {
    setOpenDelte(true);
    setDelteId(id);
  };

  const deleteUnitHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Materials/Delete/${DelteId}`,
      requestOptions
    ).then((response) => {
      if (response.status == 200 || response.status == 204) {
        setLoad(false);
        setOpenDelte(false);
        setRefresh(!refresh);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  //aDD
  const [openAdd, setOpenAdd] = useState(false);
  const [material, setMatrail] = useState("");

  const addHandeller = () => {
    setLoad(true);
    const body = new FormData();
    body.append("materialName", material);

    var option = {
      method: "POST",
      body: body,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Materials/Create?materialName=${material}`,
      option
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setMatrail("");
        setOpenAdd(false);
        refreshHandeller();
      }
    });
  };
  return (
    <div>
      {" "}
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">المواد </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <div className=" flex items-center justify-between p-4">
        <div className="">
          <button //Add
            onClick={() => setOpenAdd(true)}
            className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400"
          >
            {" "}
            {/* New Unit */}
            مادة جديدة <i className="fa-solid fa-file-circle-plus"></i>
          </button>
        </div>
      </div>
      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
              <th className=" text-start col-span-7 md:col-span-11">المادة</th>
              {/* <th className=" col-span-1">تعديل</th> */}
              <th className=" col-span-1">حذف</th>
            </tr>
          </thead>
          <tbody>
            {matrials.map((e, index) => {
              return (
                <tr
                  className="p-2 grid grid-cols-8 md:grid-cols-12"
                  key={index}
                >
                  <td className=" text-lg font-bold text-[#3B3486] text-start col-span-7 md:col-span-11">
                    {e.name}
                  </td>
                  <td className=" text-center text-lg font-bold text-red-600 col-span-1">
                    <button onClick={() => openDeleteUnitHandeller(e.id)}>
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {openAdd && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-center my-4 text-xl text-sky-800">
              اضافة مادة
            </h1>
            <input
              value={material}
              onChange={(e) => setMatrail(e.target.value)}
              className=" p-2 rtl outline-none border rounded-md w-72"
              placeholder="اسم المادة"
              type="text"
            />
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={addHandeller}
                className=" bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                اضافة
              </button>
              <button //Cancel Btn
                onClick={() => setOpenAdd(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
      {openDelte && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-12 my-4 md:mx-24">
              حذف هذه المادة
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpenDelte(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
