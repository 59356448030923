import React, { useContext } from "react";
import { login } from "../../utils/LoginStore";
import Home from "./Home";
import LessonsList from "../Teacher/Main/LessonsList";
import Packeges from "../Teacher/Packages/Packeges";
import TeacherLayout from "../Teacher/TeacherLayout";
import StudentLayout from "../Student/Layout";
import HomeStudent from "../Student/Home/HomeStudent";
import Package from "../Student/Package/Package";

export default function Main() {
  const logged = useContext(login).isLogged;
  const type = useContext(login).type;
  if (logged) {
    if (type === "teacher") {
      return (
        <>
          <TeacherLayout>
            <Packeges />
          </TeacherLayout>
        </>
      );
    } else if (type === "student") {
      return (
        <>
          <StudentLayout>
            {/* <HomeStudent /> */}
            <Package />
          </StudentLayout>
        </>
      );
    }
  } else {
    return <Home />;
  }
}
