import React from "react";

export default function RecivedMessage(props) {
  return (
    <div className="flex justify-start rtl items-end">
      <div className=" bg-gray-400 h-8 w-8 rounded-full my-2 flex items-center justify-center text-sm text-sky-800">
        {props.student ? (
          <i className="fa-solid fa-graduation-cap"></i>
        ) : (
          <>
            <i className="fa-solid fa-chalkboard-user"></i>
          </>
        )}
      </div>

      <div className="  p-4 rounded-l-3xl text-white rounded-tr-3xl max-w-[80%] md:max-w-[70%] bg-[#00a3aa]  shadow-md w-fit m-2">
        <p>{props.message}</p>
      </div>
    </div>
  );
}
