import React, { useEffect, useState } from "react";

export default function Status() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackages`
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setCourses(data);
        });
      }
    });
  }, []);
  const Section = (props) => {
    return (
      <div className=" border m-2 rounded-md py-10 w-72 flex items-center justify-center border-sky-500">
        <div>
          <h1 className=" text-center text-5xl text-sky-900">{props.num}</h1>
          <h4 className=" mt-2 text-center">{props.text}</h4>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className=" flex-wrap my-10 flex items-center justify-center">
        <Section num={23} text={"سنة خبرة"} />
        <Section num={1200} text={"طلاب راضون"} />
        <Section num={11520} text={"ساعات شرح"} />
        <Section num={courses.length} text={"كورس منشور"} />
      </div>
    </>
  );
}
