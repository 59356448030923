import React from 'react'
import { Link } from 'react-router-dom'

export default function PayErr() {
  return (
    <div className=" flex items-center justify-center h-screen">
            <div className=" text-center shadow-md border mx-auto p-4 md:p-12 md:w-2/3">
              <i className="fa-solid fa-ban text-6xl text-red-700"></i>
              <h1 className=" text-3xl my-6"> عملية الدفع لم تنجح</h1>
              <Link to={"/courses"}>
                <button className=" underline text-sky-700">
                  الرجوع الي الباقات
                </button>
              </Link>
            </div>
          </div>
  )
}
