import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router";

export default function AddExam() {
  const auth = btoa("11162387:60-dayfreetrial");
  const [load, setLoad] = useState(false);
  // Headers

  const search = useLocation().search;
  const packagesId = new URLSearchParams(search).get("id");

  const [examTitle, setExamTitle] = useState("");
  const [year, setYear] = useState(0);
  const [semster, setSemster] = useState(1);
  const [unit, setUnit] = useState(0);
  const [checked, setChecked] = useState(false);

  // Getting Units
  const [units, setUnits] = useState();

  // const unitsMaped = units.map((e, index) => {
  //   return (
  //     <option key={index} value={e.value}>
  //       {e.text}
  //     </option>
  //   );
  // });
  // Getting Units
  useEffect(() => {
    // setUnit(0);
    // if (!checked) {
    //   if (semster !== 0 && year !== 0) {
    //     setLoad(true);
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Basic ${auth}`);

    //     var requestOptions = {
    //       method: "GET",
    //       headers: myHeaders,
    //       redirect: "follow",
    //     };
    //     fetch(
    //       // Geting Uints
    //       `https://biogeniusdotnet.biogeniuses.com/api/Units/GetUnitsByYearAndTerm/${year}/${semster}`,
    //       requestOptions
    //     )
    //       .then((response) => {
    //         if (response.status === 200) {
    //           response.json().then((data) => {
    //             setUnits(data);
    //             setLoad(false);
    //           });
    //         } else {
    //           setLoad(false);
    //         }
    //       })
    //       .catch((error) => console.log("error", error));
    //   }
    // }
    setLoad(true);

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Units/Details/${packagesId}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setUnit(packagesId);
          setUnits(data.title);
          setYear(data.year);
          setSemster(data.term);
          setLoad(false);
        });
      }
    });
  }, [semster, year, checked]);

  //
  // Questions
  //

  const [id, setId] = useState(0);

  const [newExam, setNewExam] = useState([]);

  //new Question
  const newQuestionHandeller = () => {
    setNewExam([
      ...newExam,
      {
        questionContent: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        correctAnswer: "0",
        questionScore: 1000,
        year: +year,
        term: year == 3 ? null : +semster,
        // lessonId: +id,
        isExerciseQuestion: false,
      },
    ]);
  };

  // delete question
  const deleteHandeller = (inx) => {
    let an = [...newExam];

    an.splice(inx, 1);

    setNewExam(an);
  };

  //Maping Questions
  const questionsOfNewExam = newExam.map((e, index) => {
    return (
      <div key={index} className=" border-b p-2 my-2 ">
        <div className=" flex items-center justify-between">
          <h1 className=" text-lg py-2 px-4">{index + 1} .</h1>
          <button
            onClick={() => deleteHandeller(index)}
            className=" px-4 opacity-50 hover:opacity-80 text-red-600 underline"
          >
            حذف <i className="fa-solid fa-trash"></i>
          </button>
        </div>

        <input
          required
          type="text"
          //set values of Question
          onChange={(el) => {
            let ques = [...newExam];
            let quest = ques[index];
            ques[index] = {
              questionContent: el.target.value,
              option1: quest.option1,
              option2: quest.option2,
              option3: quest.option3,
              option4: quest.option4,
              correctAnswer: quest.correctAnswer,
              questionScore: 1000,
              year: +year,
              term: year == 3 ? null : +semster,
              // lessonId: +id,
              isExerciseQuestion: false,
            };
            setNewExam(ques);
          }}
          value={newExam[index].questionContent}
          className=" outline-none text-lg text-slate-500 border focus:border-sky-500 transition-all duration-300 font-bold bg-gray-100 w-full rounded-md p-3"
          placeholder="السؤال"
        ></input>

        <hr className="my-2 w-[50%] mx-auto opacity-50"></hr>

        <div className=" my-3">
          {/* Choose 1 */}
          <div className=" relative">
            <button //choose as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option1,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option1 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option1}
              // set choose 1
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: el.target.value,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الاول"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option1 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 2 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option2,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option2 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              value={newExam[index].option2}
              // set choose 2
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: el.target.value,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              placeholder=" الاختيار الثاني"
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option2 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 3 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option3,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option3 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الثالث"
              // set choose 3
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: el.target.value,
                  option4: quest.option4,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              value={newExam[index].option3}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option3 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
          {/* Choose 4 */}
          <div className=" relative">
            <button //chooase as correct answer
              onClick={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: quest.option4,
                  correctAnswer: quest.option4,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              className=" absolute left-2 top-2 text-xl"
            >
              {newExam[index].correctAnswer == newExam[index].option4 ? (
                <i className=" text-green-600 fa-solid fa-square-check"></i>
              ) : (
                <i className="fa-regular fa-square"></i>
              )}
            </button>
            <input
              type="text"
              placeholder=" الاختيار الرابع"
              value={newExam[index].option4}
              // set choose 4
              onChange={(el) => {
                let ques = [...newExam];
                let quest = ques[index];
                ques[index] = {
                  questionContent: quest.questionContent,
                  option1: quest.option1,
                  option2: quest.option2,
                  option3: quest.option3,
                  option4: el.target.value,
                  correctAnswer: quest.correctAnswer,
                  questionScore: 1000,
                  year: +year,
                  term: year == 3 ? null : +semster,
                  // lessonId: +id,
                  isExerciseQuestion: false,
                };
                setNewExam(ques);
              }}
              className={`my-1 p-2 outline-none ${
                newExam[index].correctAnswer == newExam[index].option4 &&
                "bg-green-500/40"
              } text-md border rounded-md w-full focus:border-sky-500 transition-all duration-300`}
            ></input>
          </div>
        </div>
      </div>
    );
  });

  let check = !checked && unit === 0;
  const navigate = useNavigate();

  const senExamHandeller = () => {
    setLoad(true);

    // console.log(newExam)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      title: examTitle,
      description: "string",
      year: +year,
      term: year == 3 ? null : +semster,
      isUnitExam: true,
      unitId: +packagesId,
      selectedQuestionIds: [],
      questionsList: newExam,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Exams/CreateExamWithQuestions",
      requestOptions
    )
      .then((response) => {
        if (response.status == 200) {
          setLoad(false);
          navigate(`/all-exams?id=${packagesId}`);
          toast.success("تم اضافة الامتحان", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          setLoad(false);

          response.json().then((err) => {
            // console.log(err)
            toast.error(err.errorMessage, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });
        }
      })

      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    let arr = [...newExam];
    newExam.map((e, index) => {
      arr[index] = {
        ...arr[index],
        year: +year,
        term: year == 3 ? null : +semster,
      };
    });
    setNewExam(arr);
  }, [year, semster]);

  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* Header */}
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">امتحان جديد </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <div className=" rtl p-4 my-6">
        <div // Main Infos
          className=" text-center"
        >
          <input
            value={examTitle}
            onChange={(e) => setExamTitle(e.target.value)}
            className=" outline-none p-2 w-64 bg-white/0 border-b border-[#45407e] text-[#45407e]/80 mx-auto"
            type="text"
            placeholder="اسم الامتحان"
          />
          <br />
          {/* <select //Year
            value={year}
            disabled
            onChange={(e) => setYear(e.target.value)}
            className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الصف
            </option>
            <option value={1}>الاول الثانوي</option>
            <option value={2}>الثاني الثانوي</option>
            <option value={3}>الثالث الثانوي</option>
          </select>
          <br />
          <select //semster
            disabled
            value={semster}
            onChange={(e) => setSemster(e.target.value)}
            className=" disabled:opacity-55 m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الفصل الدراسي
            </option>
            <option value={1}>الاول </option>
            <option value={2}>الثاني </option>
           
          </select> */}
          <br />
          {/* <div className=" flex items-center justify-center">
            <h2 className=" text-lg ">امتحان تيرم :</h2>
            <Switch
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div> */}
          <input
            value={units}
            disabled
            type="text"
            className=" outline-none p-2 w-64 bg-white/0 border-b border-[#45407e] text-[#45407e]/80 mx-auto"
          />
        </div>

        <hr className=" w-[70%] mx-auto my-8" />
      </div>
      {/*  */}
      {/* Question */}
      {/*  */}
      <div className=" rtl">
        {newExam.length === 0 && (
          <h1 className=" text-lg text-center m-4 ">لا يوجد اسئلة</h1>
        )}

        {questionsOfNewExam}

        <div className="text-center mt-12">
          <button //New Question Btn
            onClick={newQuestionHandeller}
            className=" text-lg py-2 px-4 my-12 text-white shadow-md bg-green-600 rounded-md text-center mx-2"
          >
            اضافة سؤال
          </button>
          <button // Send Questions Btn
            onClick={senExamHandeller}
            disabled={
              newExam.length === 0 ||
              !examTitle ||
              year == 0 ||
              semster == 0 ||
              check
            }
            className=" disabled:cursor-not-allowed disabled:opacity-40 text-lg py-2 px-4 my-12 text-white shadow-md bg-sky-600 rounded-md text-center mx-2"
          >
            انهاء
          </button>
        </div>
      </div>
    </div>
  );
}
