import React, { useState } from "react";

export default function Test() {
  const [token, setToken] = useState("");
  const [file, setFile] = useState();
  const getToken = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      username: "01094487343",
      password: "Paymob@123",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://accept.paymob.com/api/auth/tokens", requestOptions)
      .then((response) => response.json())
      .then((result) => setToken(result.token))
      .catch((error) => console.log("error", error));
  };

  const senHandeller = () => {
    var myHeaders = new Headers();
    var raw = JSON.stringify({
      title: "string  ",
      file: [file],
      description: "string",
      year: 3,
      term: null,
      revisionId: 6,
      type: 2,
    });

    var requestOptions = {
      method: "POST",
      // headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Reviews/CreateReviewFile",
      requestOptions
    ).then((res) => {
      console.log(res.status);
      console.log(raw);
    });
  };
  return (
    <div>
      <button onClick={getToken} className=" border p-2 m-4 rounded-full">
        Token
      </button>
      <p className=" text-wrap">{token}</p>
      <p>{` old Call back : https://accept.paymobsolutions.com/api/acceptance/post_pay`}</p>

      <p>iframe Link</p>
      <p>{`https://accept.paymob.com/api/acceptance/iframes/809708?payment_token={payment_key_obtained_previously}`}</p>
      <p>{`https://accept.paymob.com/api/acceptance/iframes/809707?payment_token={payment_key_obtained_previously}

`}</p>
      <div>
        <input
          required
          type="file"
          id="file"
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
        />
        <button onClick={senHandeller}>send</button>
      </div>
    </div>
  );
}
