import React, { useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Video from "./Video";

export default function Add(props) {
  const [load, setLoad] = useState(false);
  const [file, setFile] = useState();
  const [loadUpload, setLoadUpload] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const addVid = (el) => {
    setFile(el);
  };

  const ShowVid = React.useMemo(() => <Video add={addVid} />, []);

  const addHandeller = () => {
    setLoadUpload(true);

    let num = Math.random();
    var formdata = new FormData();
    formdata.append(
      "Title",
      file.type.substring(0, 5) === "video"
        ? `video intro Video ${num}`
        : `image intro Video ${num}`
    );
    formdata.append("File", file);
    formdata.append("Type", 1);

    axios({
      method: "post",
      url: "https://biogeniusdotnet.biogeniuses.com/api/Introductions/Create",
      data: formdata,
      onUploadProgress: (p) => {
        setUploadProgress((p.loaded / p.total) * 100);
      },
    })
      .then((response) => {
        setLoad(false);
        if (response.status === 200) {
          setLoadUpload(false);
          props.cancel();
          props.refresh();
        } else {
          setLoadUpload(false);
          toast.error("هناك مشكلة في رفع الملف", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch(function (error) {
        // console.log(error)
        setLoadUpload(false);
        toast.error("هناك مشكلة في رفع الملف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return Promise.reject(error);
      });
  };
  return (
    <div className=" rtl z-50 fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20">
      {" "}
      {/* Lading */}
      {load && <Loader />}
      <div className=" coin w-full mx-2 md:w-[70%] bg-white p-8 rounded-lg">
        {ShowVid}
        {loadUpload && (
          <div className=" m-2 border text-ceneter border-sky-900 p-2 rounded-md">
            <h1 className=" text-lg text-sky-800 font-bold mb-4 text-center">
              {" "}
              يتم الرفع {uploadProgress.toString().substring(0, 4)}%
            </h1>
            <div className=" bg-gray-400 rounded-full">
              <div
                style={{ width: `${uploadProgress}%` }}
                className=" transition-all ease-in-out duration-300 rounded-full bg-sky-900 p-1"
              ></div>
            </div>
          </div>
        )}
        {!loadUpload && (
          <div className="  text-center mt-8">
            <button //Delte Btn
              disabled={!file}
              onClick={addHandeller}
              className=" disabled:opacity-70 bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
            >
              اضافة
            </button>
            <button //Cancel Btn
              onClick={props.cancel}
              className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
            >
              الغاء
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
