import React from "react";
import { Link } from "react-router-dom";

export default function Courses() {
  return (
    <div>
      <div className=" rtl  py-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1">
          <div>
            <img
              src={"./imgs/1.jpg"}
              className=" w-full h-48 rounded-md"
              alt={`course year 1`}
            />
            <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
              الصف الاول الثانوي
            </h1>
          </div>
          <div className="mb-2">
            <Link to={`/allcourses?year=1`}>
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    الكورسات
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1">
          <div>
            <img
              src={"./imgs/2nd.jpg"}
              className=" w-full h-48 rounded-md"
              alt={`course year 2`}
            />
            <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
              الصف الثاني الثانوي
            </h1>
          </div>
          <div className="mb-2">
            <Link to={`/allcourses?year=2`}>
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    الكورسات
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1">
          <div>
            <img
              src={"./imgs/3rd.jpg"}
              className=" w-full h-48 rounded-md"
              alt={`course year 3`}
            />
            <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
              الصف الثالث الثانوي
            </h1>
          </div>
          <div className="mb-2">
            <Link to={`/allcourses?year=3`}>
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    الكورسات
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
