import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loader from "../../../components/Loader/Loader";

export default function AddPach(props) {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const [load, setLoad] = useState(false);

  const [packages, setPackages] = useState([]);
  const [pur, setPur] = useState();
  // Get all Packages
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackages`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setPackages(data);
          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  //
  // Add Package
  //

  const addHandeller = () => {
    setLoad(true);
    let pa = packages;
    pa = pa.filter((e) => e.id == pur);
    console.log(pa[0]);
    // var formdatadd = new FormData();
    // formdata.append("Purchases", pa[0].name);
    // formdata.append("expireOfTime", pa[0].endDate.substring(0, 10));
    // formdata.append("year", pa[0].year);
    // formdata.append("term", pa[0].term);
    // formdata.append("type", pa[0].isStudy ? "units" : "revision");
    // formdata.append("id", id);

    const formdata = new FormData();
    formdata.append("Purchases", pa[0].id);
    formdata.append("start", pa[0].startDate.substring(0, 10));
    formdata.append("end", pa[0].endDate.substring(0, 10));
    formdata.append("idStudent", id);
    formdata.append("name", pa[0].name);
    formdata.append("price", pa[0].price);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/updateDataStudentFromTeacher",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        setLoad(false);
        props.close();
        props.re();
      }
    });
  };
  return (
    <div className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20">
      {load && <Loader />}
      <div className=" coin bg-white pb-6  px-8 rounded-lg">
        <h1 className=" text-bold text-xl text-sky-900 mx-12 my-4 md:mx-24">
          اضاف باقة للطالب
        </h1>
        <div>
          <select
            value={pur}
            onChange={(e) => {
              setPur(e.target.value);
            }}
            className="rtl m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الباقة
            </option>
            {packages.map((e) => (
              <option key={e.id} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>

        <div className="  text-center mt-8">
          <button //Delte Btn
            onClick={addHandeller}
            disabled={!pur}
            className=" disabled:opacity-70 bg-green-700 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            اضافة
          </button>
          <button //Cancel Btn
            onClick={props.close}
            className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  );
}
