import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Add from "./Add";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

export default function Free() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const editCourse = Cookies.get("EditCourse") === "true";

  const search = useLocation().search;
  const packagesId = new URLSearchParams(search).get("id");

  const refreshHandeller = () => {
    setRefresh(!refresh);
  };

  // Getting

  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${packagesId}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLoad(false);
          setLessons(data);
        });
      } else {
        setLoad(false);
      }
    });
  }, [refresh]);

  // Maping

  const mapedFreeLesson = lessons.map((e, index) => {
    return (
      <tr key={index} className=" p-2 grid grid-cols-8 md:grid-cols-12">
        <td className=" text-start font-bold underline text-sky-600 text-lg col-span-7 md:col-span-11">
          <Link to={`/viewLesson?id=${e.id}`}>{e.title}</Link>
        </td>

        {editCourse && (
          <td className=" col-span-1 text-lg font-bold text-red-600 text-center">
            <button
              onClick={() => {
                setOpenDelete(true);
                setInx(index);
              }}
            >
              {" "}
              <i className="fa-solid fa-trash"></i>
            </button>
          </td>
        )}
      </tr>
    );
  });

  // Add

  const [openAdd, setOpenAdd] = useState(false);

  // Delete

  const [openDelete, setOpenDelete] = useState(false);
  const [inx, setInx] = useState();

  const deleteUnitHandeller = () => {
    let del = [...lessons];
    del.splice(inx, 1);
    // console.log(del)
    let dataToSend = [];
    del.map((e) => {
      dataToSend.push(e.id);
    });
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(dataToSend);
    // var raw = JSON.stringify({
    //   selectedLessons: dataToSend,
    // });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/ChooseFreeLessonsByBackage/${packagesId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          setLoad(false);
          setOpenDelete(false);
          setRefresh(!refresh);
        } else {
          console.log("error");
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      {load && <Loader />}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          المحتوي المجاني{" "}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/*  */}
      {/* New Content */}
      {/*  */}

      <div className=" flex items-center justify-between p-4">
        {editCourse && (
          <div className="">
            <button //Add
              onClick={() => setOpenAdd(true)}
              className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400"
            >
              {" "}
              {/* New Unit */}
              اضافة درس <i className="fa-solid fa-file-circle-plus"></i>
            </button>
          </div>
        )}
      </div>
      {/* ContentList */}

      <div className=" p-4">
        <table className=" rtl w-full shadow-sm rounded-md">
          <thead>
            <tr className=" bg-[#b2b2b350] p-2 grid grid-cols-8 md:grid-cols-12">
              <th className=" text-start col-span-7 md:col-span-11">الدرس</th>
              {editCourse && <th className=" col-span-1">حذف</th>}
            </tr>
          </thead>
          <tbody>{mapedFreeLesson}</tbody>
        </table>
        {lessons.length === 0 && (
          <h1 className=" text-center text-xl text-red-500  my-24">
            لا توجد دروس مجانية في هذا الكورس
          </h1>
        )}
      </div>

      {openAdd && (
        <Add
          packagesId={packagesId}
          refresh={refreshHandeller}
          cancel={() => setOpenAdd(false)}
        />
      )}
      {openDelete && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-4 md:mx-12 my-4">
              حذف هذا الدرس من الدروس المجانية
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpenDelete(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
