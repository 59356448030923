import React, { useEffect, useState } from "react";
import "../student.css";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function ExamsForstudent() {
  const [load, setLoad] = useState(false);
  // Lessons
  const [exams, setExams] = useState([]);

  // Fetching data
  const token = Cookies.get("token");

  useEffect(() => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/getLVAllExam",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        res.json().then((data) => {
          let less = [];
          data.map((e) => {
            e.map((el) => {
              less.push(el);
              setExams(less);
            });
          });
          // setExams(data);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  //
  // Exams
  //

  const ExamsMaped = exams.map((e, index) => {
    return (
      <div key={index}>
        <Link className="" to={`/exam?id=${e.id}`}>
          <div className=" ma hover:bg-white hover:shadow-md hover:text-[#e51d74] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
            <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#e51d74]">
              <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
                {index + 1}
              </h1>
            </div>
            <div className=" flex items-center justify-between w-full">
              <h1 className=" text-xl"> {e.title}</h1>
              <i className="  mx-8 an text-[#fdebf3] rotate-180 fa-solid fa-chevron-right"></i>
            </div>
          </div>
        </Link>
      </div>
    );
  });

  return (
    <div>
      {/* Lading */}
      {load && <Loader />}

      {/* Title */}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]"> الامتحانات</h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/* Exams */}
      <div className=" shadow-md bg-[#fdebf3] mx-6 md:mx-40 rounded-3xl p-8  my-8 rtl">
        {exams.length === 0 ? (
          <h1 className=" text-center text-xl font-bold ">لا توجد امتحانات</h1>
        ) : (
          ExamsMaped
        )}{" "}
      </div>
    </div>
  );
}
