import React, { useContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { login } from "../../../utils/LoginStore";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

export default function LoginTeacher() {
  const [load, setLoad] = useState(false);
  // Values
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");

  // login Checking
  const logged = useContext(login);
  const navigation = useNavigate();

  useEffect(() => {
    if (logged.isLogged) {
      navigation("/packages");
      // return;
    }
  }, []);

  // Login
  const loginHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: mail,
      password: password,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://biogeniusdotnet.biogeniuses.com/api/Auth/login", requestOptions).then(
      (res) => {
        if (res.status === 200) {
          const thisTime = new Date().getTime();
          res.json().then((data) => {
            Cookies.set("token", data.token, { expires: 12 / 24 });
            Cookies.set("id", data.userId, { expires: 12 / 24 });
            Cookies.set("dHlwZSBvZiBsb2c", "dGhlIHR5cHIgaXMgdGVhY2hlcg", {
              expires: 12 / 24,
            });
            Cookies.set("expirein", thisTime + 12 * 60 * 60 * 1000, {
              expires: 12 / 24,
            });
            if (data.userRoles.includes("SuperAdmin")) {
              Cookies.set("assestent", "true", { expires: 12 / 24 });
              Cookies.set("Money", "true", { expires: 12 / 24 });
              Cookies.set("EditCourse", "true", { expires: 12 / 24 });
              Cookies.set("DeleteCourse", "true", { expires: 12 / 24 });
              Cookies.set("Report", "true", { expires: 12 / 24 });
              Cookies.set("Money", "true", { expires: 12 / 24 });
              Cookies.set("Chat", "true", { expires: 12 / 24 });
            } else {
              Cookies.set("assestent", "false", { expires: 12 / 24 });
              data.userPermissions.map((e) => {
                Cookies.set(e, "true", { expires: 12 / 24 });
              });
            }

            logged.setIsLogged(true);
            logged.setType("teacher");
            // navigation("/packages");
            window.location.reload();
          });
          // setLoad(false);
        } else {
          setLoad(false);
          toast.error("البريد الاكتروني او كلمة السر خطأ", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    );
  };

  

  if (logged.isLogged) {
    // navigation(-1);
    return;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        {/* Nav Bar */}
        <div className=" mb-12 p-4 md:p-8">
          <Link to={"/"}>
            <img
              className=" w-24 md:w-32"
              src="./imgs/logo.png"
              alt="Logo Bio Genuis"
            />
          </Link>
        </div>
        {/* Login Form  */}
        <div className=" flex items-center justify-center">
          <div className=" p-8 shadow-xl rounded-xl border">
            <h1 className=" text-xl px-12 md:px-24 text-[#07a9b3]">
              تسجيل الدخول للمدرس
            </h1>
            <hr className=" my-6" />

            <div className=" rtl">
              <form onSubmit={loginHandeller}>
                <div className=" my-4 flex items-center border bg-slate-50 rounded-md ">
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-at"></i>
                  <input //Email
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={mail}
                    required
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="البريد الالكتروني"
                    type="email"
                  />
                </div>
                <div className=" my-4 flex items-center border bg-slate-50 rounded-md ">
                  <i className=" mx-2 text-sky-800/50 fa-solid fa-lock"></i>
                  <input //password
                    className=" rounded-md bg-slate-50 w-full outline-none p-2 text-sky-800 font-bold"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="كلمة السر"
                    type="password"
                  />
                </div>

                <div className=" text-center mt-12 mb-2">
                  <button //Login BTN
                    disabled={!mail || !password}
                    className=" disabled:opacity-80 py-2 px-4 bg-[#219b2b] text-white rounded-md "
                  >
                    تسجيل دخول <i className=" fa-solid fa-right-to-bracket"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
