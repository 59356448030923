import React, { useState } from "react";
import "./add.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Gallery(props) {
  const [file, setFile] = useState([]); //Gallery

  const showGllery = file.map((e, index) => {
    console.log();
    return (
      <img
        className=" hw w-48 my-4 h-48 mx-auto  col-span-1 rounded-md shadow-md"
        key={index}
        alt="deatails"
        src={URL.createObjectURL(e[0])}
      />
    );
  });

  return (
    <div>
      <h1 className=" text-2xl text-[#45407e] my-4 font-bold">
        اضافة صور توضيحية
      </h1>{" "}
      <div className="  flex flex-wrap mx-auto md:w-2/3">
        <div className=" gi grid grid-cols-3 w-full  mx-auto">
          {showGllery}
          <div className=" font-bold text-2xl  text-[#45407e] shadow-md p-8 hw h-48 w-48 col-span-1 my-4 flex items-center justify-center mx-auto border rounded-lg">
            {" "}
            {/* Upload images */}
            <section>
              <label
                className=" po cursor-pointer h-full w-full"
                htmlFor="gallery"
              >
                اضافة صورة <i className="fa-solid fa-cloud-arrow-up"></i>
              </label>

              <input
                className="hidden"
                id="gallery"
                accept="image/png, image/jpeg, image/jpg"
                onChange={(e) => {
                  let fi = [...file];
                  if (e.target.files.length > 0) {
                    props.add(e.target.files[0]);
                    fi.push(e.target.files);
                    setFile(fi);
                  }
                }}
                type="file"
                name="filefield"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
