import React, { useState } from "react";
import "./add.css";

export default function Video(props) {
  const [file, setFile] = useState();

  return (
    <div>
      <div className=" my-12">
        {file ? ( // if file or else
          <div className=" relative">
            <button //Delete Btn
              onClick={() => {
                setFile();
                props.add();
              }}
              className=" text-red-500/70 hover:text-red-500 cursor-pointer text-lg "
            >
              {/* <i className="fa-solid fa-trash"></i> */}
            </button>{" "}
            {/*  Video */}
            <video
              className=" w-full h-60  border  rounded-md shadow-md mx-auto"
              controls
              src={URL.createObjectURL(file)}
            />
          </div>
        ) : (
          <div className=" font-bold text-2xl  text-[#45407e] shadow-md p-8   flex items-center justify-center mx-auto border rounded-lg">
            {" "}
            {/* Upload Video */}
            <section>
              <label
                className=" po cursor-pointer h-full w-full"
                htmlFor="file"
              >
                اضافة فيديو <i className="fa-solid fa-cloud-arrow-up"></i>
              </label>

              <input
                required
                type="file"
                id="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  props.add(e.target.files[0]);
                }}
                style={{ display: "none" }}
              />
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
