import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loader from "../../components/Loader/Loader";
import Error from "../Error/Error";
import VideoViaLink from "../../components/Lesson/VideoViaLink";
import Images from "../../components/Lesson/Images";
import Excers from "../../components/Lesson/Excers";
import { Link } from "react-router-dom";

export default function FreeLesson() {
  const [load, setLoad] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const course = new URLSearchParams(search).get("course");
  const [err, setErr] = useState(false);

  //
  // MAIN DATA
  //

  const [title, setTitle] = useState("");
  const [Videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [excercieses, setExcercieses] = useState([]);

  //   const [lessons, setLessons] = useState([]);

  // fetching main data

  useEffect(() => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${course}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          let less = data;

          less = less.filter((e) => e.id == id);
          // console.log(less);
          setTitle(less[0].title);
          setVideos(less[0].videos);
          setImages(less[0].images);
          setExcercieses(less[0].images);

          setLoad(false);
        });
      } else {
        setErr(true);
        setLoad(false);
      }
    });
  }, []);

  // maping Videos

  const videoMaped = Videos.map((e, index) => {
    return (
      <VideoViaLink
        key={index}
        link={"https://biogeniusdotnet.biogeniuses.com/" + e.videoUrl}
        desc={e.description}
      />
    );
  });

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        <div>
          {/* Lading */}
          {load && <Loader />}

          {/* Title */}

          <div className=" my-4 flex items-center justify-center">
            <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
            <h1 className=" text-2xl m-2 font-bold text-[#3B3486]"> {title}</h1>
            <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          </div>

          {/* Videos List */}
          <div>{videoMaped}</div>
          {/* Gallery */}
          <div>{images.length !== 0 && <Images images={images} />}</div>
          {/* Ecxercises */}
          <div>
            {/* {excercieses.length !== 0 && <Excers ex={id} exc={excercieses} />} */}
          </div>
        </div>
        <div className=" text-center">
          <Link className="text-center" to={`/checkout?id=${course}`}>
            <button className=" py-2 px-10 bg-green-800 text-white text-xl rounded-md shadow-lg mb-6">
              شراء لعرض محتوي الكورس كامل
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
