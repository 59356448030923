import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation } from "react-router-dom";
import { Switch } from "@mui/material";
import Cookies from "js-cookie";

export default function Packeges() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const search = useLocation().search;
  const packagesId = new URLSearchParams(search).get("id");

  // Getting Packages

  const [packages, setPackages] = useState([]);
  const [materials, setMatrails] = useState([]);
  const [packagesSearch, setPackagesSearch] = useState([]);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackages`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setPackages(data);
          setPackagesSearch(data);
          // setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });

    fetch(`https://biogeniusdotnet.biogeniuses.com/api/Materials/GetAll`).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setLoad(false);
            setMatrails(data);
          });
        }
      }
    );
  }, [refresh]);

  // Delete

  const [openDelete, setOpenDelete] = useState(false);
  const [id, setId] = useState();

  const deleteUnitHandeller = () => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Students/DeletePackage/${id}`
    ).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setLoad(false);
        setRefresh(!refresh);
        setOpenDelete(false);
      } else {
        setLoad(false);
      }
    });
  };

  // Maping

  const [editId, setTditId] = useState("");
  const deleteCourse = Cookies.get("DeleteCourse") === "true";
  const editCourse = Cookies.get("EditCourse") === "true";
  const packagesMaped = packages.map((e, index) => {
    return (
      <div
        className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1"
        key={index}
      >
        {+e.discount > 0 && (
          <section className=" absolute top-0 h-[30px] flex items-center justify-center px-3 text-[15px] bg-[#3B3486]/70 text-white ">
            خصم
          </section>
        )}
        <div>
          <img
            src={
              e.imageUrl
                ? `https://biogeniusdotnet.biogeniuses.com${e.imageUrl}`
                : "./imgs/course.jpg"
            }
            className=" w-full h-48 rounded-md"
            alt={`course ${e.name}`}
          />
          <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
            {e.name}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#3B3486]/50">
            النوع: {e.isStudy ? "باقة شرح" : "باقة مراجعة"}
          </h4> */}
          <h4 className=" my-6 text-xl text-[#3B3486]/50">
            المادة: {e.materialName}
          </h4>
          <h4 className=" my-6 text-xl text-[#3B3486]/50">الصف: {e.year}</h4>
          <h4
            style={{ opacity: `${e.term ? 100 : 0}` }}
            className=" my-6 text-xl text-[#3B3486]/50"
          >
            الفصل: {e.term}
          </h4>
          <h4 className=" flex  justify-center my-6 text-xl text-center font-bold text-[#3B3486]">
            السعر:{" "}
            {+e.discount > 0 && (
              <section className="relative mx-2 text-[#3B3486]/80  w-fit">
                {e.price}
                <hr className=" mt-[-15px] rotate-[150deg]  h-[1px] bg-[red]/60 border-[red]/60 "></hr>{" "}
              </section>
            )}{" "}
            {+e.price - +e.discount} جنيهاً
          </h4>
          <div className=" text-center flex  my-6 items-center justify-around">
            <h1 className=" text-lg text-red-600 ">
              {" "}
              {e.endDate.substring(0, 10)}
            </h1>
          </div>
        </div>

        <section>
          <div className="mb-2">
            <Link
              // to={`/lessons?id=${e.id}`}
              to={`/units?id=${e.id}&matrail=${e.materialId}`}
            >
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    عرض المحتويات
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
          <div className={`${!editCourse && `${deleteCourse && "mb-12"}`}`}>
            <Link
              // to={`/lessons?id=${e.id}`}

              to={`/free?id=${e.id}`}
            >
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    عرض المحتوي المجاني
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          </div>
          {editCourse && (
            <div className={`${deleteCourse && "mb-12"}  `}>
              <button
                className=" w-full"
                onClick={() => {
                  setTditId(e.id);
                }}
              >
                <div className="  w-full bg-white shadow-md text-sky-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                  <div className=" flex items-center justify-between w-full">
                    <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                      {" "}
                      تعديل
                    </h1>
                    <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                  </div>
                </div>
              </button>
            </div>
          )}

          {deleteCourse && (
            <div className=" absolute bottom-2 left-0 w-full px-6 ">
              <button
                className=" w-full"
                onClick={() => {
                  setOpenDelete(true);
                  setId(e.id);
                }}
              >
                <div className=" w-full bg-white shadow-md text-red-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                  <div className=" flex items-center justify-between w-full">
                    <h1 className=" mx-5 text-lg md:text-base lg:text-xl">
                      {" "}
                      حذف
                    </h1>
                    <i className="  mx-8 an text-red-800 fa-solid fa-trash"></i>
                  </div>
                </div>
              </button>
            </div>
          )}
        </section>
      </div>
    );
  });

  // New Package

  const [openPackage, setOpenPackage] = useState(false);
  const [name, setName] = useState("");
  const [year, setYear] = useState();
  const [term, setTerm] = useState();
  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState(0);
  const [study, setStudy] = useState(true);
  const [end, setEnd] = useState();
  const [file, setFile] = useState();
  const [materialName, setMaterialName] = useState();
  const [materialId, setMaterialID] = useState();

  // Sending

  const addHandeller = () => {
    setLoad(true);
    const now = new Date().toISOString();
    const endDate = new Date(end).toISOString();

    var formdata = new FormData();
    formdata.append("Name", name);
    formdata.append("Price", price);
    formdata.append("Discount", discount);
    formdata.append("MaterialName", materialName);
    formdata.append("MaterialId", materialId);
    formdata.append("StartDate", now);
    formdata.append("EndDate", endDate);
    formdata.append("Year", +year);
    formdata.append("Term", year == 3 ? "" : +term);
    formdata.append("Image", file);

    var option = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Packages/CreatePackage",
      option
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setRefresh(!refresh);
        setOpenPackage(false);
      } else {
        setLoad(false);
        toast.error("هناك مشكلة", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  useEffect(() => {
    if (editId) {
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackageById?id=${editId}`
      ).then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setLoad(false);
            setName(data.name);
            setYear(data.year);
            setTerm(data.term);
            setPrice(data.price);
            setDiscount(data.discount);
            setEnd(data.endDate.substring(0, 10));
            setFile();
          });
        }
      });
    }
  }, [editId]);

  const editHandeller = () => {
    setLoad(true);

    var formdata = new FormData();
    formdata.append("Name", name);
    formdata.append("Price", price);
    formdata.append("Discount", discount);
    formdata.append("EndDate", end);
    formdata.append("Year", +year);
    formdata.append("Term", year == 3 ? "" : +term);
    file && formdata.append("Image", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Students/Editpackage/${editId}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        setTditId("");
        setName("");
        setPrice("");
        setYear("");
        setTerm("");
        setEnd("");
        setRefresh(!refresh);
      }
    });
  };

  const [yearFilter, setYearFilter] = useState();
  const [matrailFilter, setmatrailFilter] = useState();

  useEffect(() => {
    let search = packagesSearch;
    if (yearFilter) {
      search = search.filter((e) => e.year == yearFilter);
    }
    if (matrailFilter) {
      search = search.filter((e) => e.materialId == matrailFilter);
    }

    setPackages(search);
  }, [yearFilter, matrailFilter]);

  return (
    <>
      <div>
        {" "}
        {/* Lading */}
        {load && <Loader />}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        {/* Title */}
        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">الكورسات</h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        <div className=" md:flex items-center justify-between p-4">
          {editCourse && (
            <button //NEW Packages
              onClick={() => {
                setName("");
                setMaterialName("");
                setMaterialID("");
                setPrice();
                setDiscount(0);
                setYear();
                setTerm();
                setEnd();
                setStudy(true);
                setOpenPackage(true);
              }}
              className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400"
            >
              اضافة كورس <i className="fa-solid fa-file-circle-plus"></i>
            </button>
          )}
          <div className=" w-52 md:w-auto rtl">
            {" "}
            {/* Filters*/}{" "}
            <select //Year
              value={yearFilter}
              onChange={(e) => {
                setYearFilter(e.target.value);
              }}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                الصف
              </option>
              <option value={1}>الاول الثانوي</option>
              <option value={2}>الثاني الثانوي</option>
              <option value={3}>الثالث الثانوي</option>
            </select>
            <select //matrail
              value={matrailFilter}
              onChange={(e) => {
                setmatrailFilter(e.target.value);
              }}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                المادة
              </option>
              {materials.map((e) => (
                <option key={e.index} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <hr className=" w-[60%] mx-auto mb-6" />
        {/* Maping Packages */}
        {/* <div className=" p-4 rtl flex flex-wrap justify-around">
          {packagesMaped}
        </div> */}
        <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <>{packagesMaped}</>
        </div>
      </div>

      {/* New Package */}
      {openPackage && (
        <div className=" fixed top-0 left-0 w-full h-screen bg-black/40 flex items-center justify-center">
          <div className=" max-h-[90vh] overflow-auto coin bg-white py-4 px-8 md:px-12 rounded-lg">
            <h1 className=" text-center text-bold font-bold text-xl text-sky-600 px-12 my-4 md:mx-24">
              اضافة كورس
            </h1>
            <div className=" rtl p-2">
              <form>
                <input //Name
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="اسم الكورس"
                  className=" p-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="text"
                />
                <br></br>
                <input //price
                  value={price}
                  min={1}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder=" السعر"
                  className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="number"
                />
                <br></br>
                <input //price
                  value={discount}
                  min={0}
                  onChange={(e) => setDiscount(e.target.value)}
                  placeholder=" الخصم"
                  className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="number"
                />
                <br></br>
                <select //matrail
                  value={materialId}
                  onChange={(e) => {
                    setMaterialID(e.target.value);
                    let da = materials.filter((el) => el.id == e.target.value);
                    setMaterialName(da[0].name);
                  }}
                  className=" m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
                >
                  <option selected hidden>
                    المادة
                  </option>
                  {materials.map((e) => (
                    <option name={e.name} key={e.index} value={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <select //Year
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                  className=" m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
                >
                  <option selected hidden>
                    الصف
                  </option>
                  <option value={1}>الاول الثانوي</option>
                  <option value={2}>الثاني الثانوي</option>
                  <option value={3}>الثالث الثانوي</option>
                </select>{" "}
                <br></br>
                <select //Term
                  disabled={year == 3}
                  value={term}
                  onChange={(e) => {
                    setTerm(e.target.value);
                  }}
                  className=" disabled:opacity-50 m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
                >
                  <option selected hidden>
                    الفصل الدراسي
                  </option>
                  <option value={1}>الفصل الدراسي الاول </option>
                  <option value={2}>الفصل الدراسي الثاني </option>
                </select>
                <div //End Date
                >
                  <label className=" text-lg my-2 " htmlFor="date">
                    تاريخ الانتهاء :
                  </label>
                  <input //End Date
                    id="date"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                    type="date"
                  />
                </div>
                {/* <div //is Study
                  className=" flex items-center my-3 justify-center"
                >
                  <h2 className=" text-lg "> باقة شرح :</h2>
                  <Switch
                    checked={study}
                    onChange={(e) => setStudy(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div> */}
                <div //End Date
                >
                  <label className=" text-lg my-2 " htmlFor="date">
                    الغلاف :
                  </label>
                  <input //cover
                    id="file"
                    // value={file}
                    onChange={(e) => setFile(e.target.files[0])}
                    className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none  border-b text-center text-[#45407e] "
                    type="file"
                  />
                </div>
              </form>
            </div>

            <div className="  text-center mt-8">
              <button //Delte Btn
                disabled={!year || !name || !price || !end}
                onClick={addHandeller}
                className=" disabled:opacity-70 bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                اضافة
              </button>
              <button //Cancel Btn
                onClick={() => setOpenPackage(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Edit Package */}
      {editId && (
        <div className=" fixed top-0 left-0 w-full h-screen bg-black/40 flex items-center justify-center">
          <div className=" max-h-[90vh] overflow-auto coin bg-white py-4 px-8 md:px-12 rounded-lg">
            <h1 className=" text-center text-bold font-bold text-xl text-sky-600 px-12 my-4 md:mx-24">
              تعديل كورس
            </h1>
            <div className=" rtl p-2">
              <form>
                <input //Name
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="اسم الكورس"
                  className=" p-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="text"
                />
                <br></br>
                <input //price
                  value={price}
                  min={1}
                  onChange={(e) => setPrice(e.target.value)}
                  placeholder=" السعر"
                  className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="number"
                />
                <br></br>
                <input //price
                  value={discount}
                  min={0}
                  onChange={(e) => setDiscount(e.target.value)}
                  placeholder=" الخصم"
                  className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                  type="number"
                />
                <br></br>
                <select //Year
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                  className=" m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
                >
                  <option selected hidden>
                    الصف
                  </option>
                  <option value={1}>الاول الثانوي</option>
                  <option value={2}>الثاني الثانوي</option>
                  <option value={3}>الثالث الثانوي</option>
                </select>{" "}
                <br></br>
                <select //Term
                  disabled={year == 3}
                  value={term}
                  onChange={(e) => {
                    setTerm(e.target.value);
                  }}
                  className=" disabled:opacity-50 m-2 w-full py-2 outline-none text-xl border-b  text-[#45407e] "
                >
                  <option selected hidden>
                    الفصل الدراسي
                  </option>
                  <option value={1}>الفصل الدراسي الاول </option>
                  <option value={2}>الفصل الدراسي الثاني </option>
                </select>
                <div //End Date
                >
                  <label className=" text-lg my-2 " htmlFor="date">
                    تاريخ الانتهاء :
                  </label>
                  <input //End Date
                    id="date"
                    value={end}
                    onChange={(e) => setEnd(e.target.value)}
                    className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
                    type="date"
                  />
                </div>
                <div //End Date
                >
                  <label className=" text-lg my-2 " htmlFor="date">
                    الغلاف :
                  </label>
                  <input //cover
                    id="file"
                    // value={file}
                    onChange={(e) => setFile(e.target.files[0])}
                    className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none  border-b text-center text-[#45407e] "
                    type="file"
                  />
                </div>
                {/* <div //is Study
                  className=" flex items-center my-3 justify-center"
                >
                  <h2 className=" text-lg "> باقة شرح :</h2>
                  <Switch
                    checked={study}
                    onChange={(e) => setStudy(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div> */}
                {/* <div //End Date
                >
                  <label className=" text-lg my-2 " htmlFor="date">
                    الغلاف :
                  </label>
                  <input //cover
                    id="file"
                    // value={file}
                    onChange={(e) => setFile(e.target.files[0])}
                    className=" py-2 w-full focus:border-sky-300 transition-all ease-linear duration-500 outline-none  border-b text-center text-[#45407e] "
                    type="file"
                  />
                </div> */}
              </form>
            </div>

            <div className="  text-center mt-8">
              <button //Delte Btn
                disabled={!year || !name || !price || !end}
                onClick={editHandeller}
                className=" disabled:opacity-70 bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                تعديل
              </button>
              <button //Cancel Btn
                onClick={() => setTditId("")}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}

      {/* delete */}
      {openDelete && (
        <div // Delte Cont
          className=" fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20"
        >
          <div className=" coin bg-white p-8 rounded-lg">
            <h1 className=" text-bold text-xl text-red-600 mx-4 md:mx-12 my-4">
              حذف الكورس ؟
            </h1>
            <div className="  text-center mt-8">
              <button //Delte Btn
                onClick={deleteUnitHandeller}
                className=" bg-red-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                حذف
              </button>
              <button //Cancel Btn
                onClick={() => setOpenDelete(false)}
                className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
              >
                الغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
