import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../components/Loader/Loader";
import { useNavigate } from "react-router";

export default function AddAssesten() {
  const [load, setLoad] = useState(false);

  const [progress, setProgress] = useState(1);
  const [id, setId] = useState("");

  const navigate = useNavigate();

  const [info, setInfo] = useState({
    name: "",
    mail: "",
    phone: "",
    password: "",
  });
  const { name, mail, phone, password } = info;

  const handelChange = (e) => {
    const { value, name } = e.target;

    setInfo({ ...info, [name]: value });
  };

  const nextHandeller = (e) => {
    e.preventDefault();
    setLoad(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      fullName: name,
      username: mail,
      email: mail,
      phoneNumber: phone,
      password: password,
      confirmPassword: password,
      selectedRoles: ["Assistant"],
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Auth/Create",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setProgress(2);
        setLoad(false);
        res.json().then((data) => {
          setId(data.userId);
        });
      } else {
        setLoad(false);
        toast.error("بعض البيانات خطأ او مستخدمة بالفعل", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  // const perms = ['Money' , 'EditCourse' , 'DeleteCourse' , 'Report' ,'Chat']
  // const perms = ["EditCourse", "DeleteCourse", "Report", "Chat"];
  const [allPerms, setAllPerms] = useState([]);
  const [userPerms, setUserPerms] = useState([]);

  const toggelClick = (id) => {
    let ids = userPerms.filter((e) => e == id);
    if (ids.length === 0) {
      setUserPerms([...userPerms, id]);
    } else {
      setUserPerms(userPerms.filter((e) => e !== id));
    }
  };

  useEffect(() => {
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Auth/GetAllPermissions`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          let p = [];
          let pe = [
            { en: "EditCourse", ar: "تعديل الكورسات" },
            { en: "DeleteCourse", ar: "حذف الكورسات" },
            { en: "Report", ar: "تقارير الطلاب" },
            { en: "Money", ar: "التقارير المالية" },
            { en: "Chat", ar: "المحادثات" },
          ];

          pe.map((e) => {
            let da = data;
            da = da.filter((el) => el.name === e.en);
            p.push({ ...da[0], ar: e.ar });
          });
          setAllPerms(p);
        });
      }
    });
  }, []);

  const finishHandeller = (e) => {
    e.preventDefault();
    setLoad(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(userPerms);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Auth/AddPermissionsToUser/${id}`,
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        navigate("/assestens");
      }
    });
  };
  return (
    <div className="">
      {" "}
      {load && <Loader />}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className=" relative  h-[83vh] w-full overflow-x-hidden">
        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
            اضافة مدرس مساعد
          </h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {/* Progress */}
        <div className="my-4 rtl mx-6 rounded-full bg-gray-200/30">
          <div //progress
            className={` p-1 rounded-full bg-[#3B3486]/50 transition-all ease-in-out  duration-500 ${
              progress === 1 && " w-1/2"
            } ${progress === 2 && " w-full"}  `}
          ></div>
        </div>
        <div //Permissions Info
          className={` transition-all ease-in-out duration-500 absolute   ${
            progress == 2 ? "right-0" : "right-[100%]"
          } w-full `}
        >
          <h1 className=" text-center text-xl m-2 font-bold text-[#3B3486]">
            اضافة الصلاحيات
          </h1>

          <div className=" rtl p-4  w-fit mx-auto">
            <section className="flex my-4">
              <h1 className=" text-xl text-start  font-bold w-48 text-sky-800 ">
                الصلاحية
              </h1>
              <h1 className=" text-xl font-bold  text-sky-800 ">القيمة</h1>
            </section>
            {allPerms.map((e, index) => {
              let idIn = userPerms;
              idIn = idIn.filter((el) => el == e.id);

              return (
                <section key={index} className="flex my-4">
                  <h1 className=" text-lg text-start  font-bold w-48 text-sky-900 ">
                    {e.ar}
                  </h1>
                  <button
                    onClick={() => toggelClick(e.id)}
                    className=" shadow-inner relative w-14 h-6 rounded-full border bg-gray-200"
                  >
                    <section
                      className={` transition-all ease-in-out duration-500 w-5 h-5 absolute top-[1px] ${
                        idIn.length > 0
                          ? "right-[3px] bg-green-400"
                          : "right-[31px] bg-red-400"
                      }  rounded-full  `}
                    ></section>
                  </button>
                </section>
              );
            })}
          </div>
          <div className="text-center">
            <button
              onClick={finishHandeller}
              className=" my-20 bg-[#07464b] px-4 py-1 text-lg text-white rounded-lg shadow-md "
            >
              انهاء
            </button>
          </div>
        </div>
        <div //Basic Info
          className={` transition-all ease-in-out duration-500 absolute   ${
            progress == 1 ? "right-0" : "right-[-100%]"
          } w-full `}
        >
          <h1 className=" text-center text-xl m-2 font-bold text-[#3B3486]">
            البيانات الاساسية
          </h1>
          <form onSubmit={nextHandeller} className=" text-center">
            <input //name
              className="p-2 mt-4  w-80 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
              value={name}
              required
              name="name"
              onChange={handelChange}
              type="text"
              placeholder="الاسم"
            />{" "}
            <br />
            <input //mail
              className="p-2 mt-4  w-80 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
              value={mail}
              required
              name="mail"
              onChange={handelChange}
              type="email"
              placeholder="الايميل"
            />
            <br />
            <input //phone
              className="p-2 mt-4  w-80 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
              value={phone}
              name="phone"
              required
              onChange={handelChange}
              type="text"
              placeholder="رقم التليفون"
            />{" "}
            <br />
            <input //password
              className="p-2 mt-4  w-80 focus:border-sky-300 transition-all ease-linear duration-500 outline-none text-xl border-b text-center text-[#45407e] "
              value={password}
              name="password"
              required
              onChange={handelChange}
              type="password"
              placeholder="كلمة السر"
            />
            <br />
            <h4 className=" text-xs text-black/70 w-80 text-center mx-auto">
              كلمة السر يجب ان تحتوي علي احرف كبيرة وصغيرة وارقام وان تكون اكثر
              من 8 احرف
            </h4>
            <button className=" my-20 bg-[#07464b] px-4 py-1 text-lg text-white rounded-lg shadow-md ">
              التالي
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
