import React from "react";
import { Link } from "react-router-dom";

export default function UserChat(props) {
  return (
    <div
      className={` ${
        !props.read && "bg-gray-100"
      } hover:cursor-pointer  hover:bg-gray-100 rtl py-2 px-4 w-full border-b `}
    >
      <Link to={`/chat?id=${props.id}`}>
        <div className=" flex items-center justify-between">
          <div>
            <h1 className=" text-xl font-bold text-[#3B3486]">{props.name}</h1>
            <p className=" md:hidden text-black/50 text- mt-1">
              {props.you && (
                <span className=" text-black/70 ml-2 font-bold">انت :</span>
              )}
              {props.mess.length > 40
                ? props.mess.substring(0, 40) + "...."
                : props.mess}
            </p>
            <p className=" hidden text-black/50 md:flex text- mt-1">
              {props.you && (
                <span className=" text-black/70 ml-2 font-bold">انت :</span>
              )}
              {props.mess.length > 100
                ? props.mess.substring(0, 100) + "......."
                : props.mess}
            </p>
          </div>
          <div>
            {!props.read ? (
              //   <i className="fa-solid fa-circle-dot text-xl text-[#00a3a9]"></i>
              <div className=" bg-[#3B3486] rounded-full h-4 w-4"></div>
            ) : (
              <i className="fa-solid fa-angle-right text-xl text-[#3B3486] rotate-180"></i>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}
