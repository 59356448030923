import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

export default function Reviosin() {
  const [load, setLoad] = useState(false);

  // Lessons
  const [lessons, setLessons] = useState([]);
  const token = Cookies.get("token");

  // Getting data
  useEffect(() => {
    setLoad(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/GetAllRevisions",
      requestOptions
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          let less = [];
          data.map((e) => {
            e.map((el) => {
              less.push(el);
              setLessons(less);
            });
          });
          // setLessons(data);
          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  // Maping

  const LessonsMaped = lessons.map((e, index) => {
    return (
      <div
        className=" relative shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#3B3486]/20 col-span-1"
        key={index}
      >
        <div className=" overflow-  rounded-full flex h-8 w-8 md:h-10 md:w-10 justify-center relative text-white  bg-[#3B3486]">
          <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-6px]">
            {index + 1}
          </h1>
        </div>
        <hr className=" w-[70%] mx-auto  my-4 border-black/20"></hr>
        <div>
          <h1 className=" mb-20 text-[#3B3486] text-2xl my-4 font-bold text-center">
            {e.title}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#e51d74]/50">{e.unit.title}</h4> */}
        </div>

        <div className=" absolute bottom-2 left-0 w-full px-6">
          <Link to={`/revision-show?id=${e.id}`}>
            <div className=" bg-[white] shadow-md text-[#3B3486] flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
              <div className=" flex items-center justify-between w-full">
                <h1 className=" mx-5 text-lg md:text-base lg:text-xl">
                  {" "}
                  الذهاب للشرح
                </h1>
                <i className="  mx-8 an text-[#3B3486] rotate-180 fa-solid fa-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          {"المراجعات "}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/*  */}
      {/* Lessons */}
      {/*  */}

      <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {lessons.length === 0 ? (
          <div className=" w-full text-center col-span-1 md:col-span-2 lg:col-span-3">
            <h1 className=" text-center my-3 text-xl font-bold ">
              لا توجد مراجعات
            </h1>
            <Link to={"/buy-package"}>
              <button className=" py-1 px-4 shadow-md rounded-md text-lg bg-[#3B3486] text-white">
                شراء باقة
              </button>
            </Link>
          </div>
        ) : (
          <>{LessonsMaped}</>
        )}{" "}
      </div>
    </div>
  );
}
