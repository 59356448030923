import React, { useEffect, useState } from "react";
import UserChat from "../../../components/chat/UserChat";
import Error from "../../Error/Error";
import Loader from "../../../components/Loader/Loader";

import * as signalr from "@microsoft/signalr";
import {
  hubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

export default function ChatListComp(props) {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersNames, setUsersNames] = useState([]);
  const fet = () => {
    fetch(
      //Fetching Users
      `https://biogeniusdotnet.biogeniuses.com/api/ChatMessage/GetTeacherStudentChats/30a9511b-4a0a-423c-ac14-0d3874f85598`
    ).then((res) => {
      if (res.status == 200) {
        res.json().then((data) => {
          setUsers(data);
          //   console.log(data);
          setLoad(false);
        });
      } else {
        setErr(true);
      }
    });
  };

  //
  // Connecting
  //

  // start Connection with chat
  const conn = new HubConnectionBuilder()
    .configureLogging(LogLevel.Debug)
    // .configureLogging(LogLevel.Information)
    .withUrl("https://biogeniusdotnet.biogeniuses.com/chatmessagehub", {
      skipNegotiation: true,
      transport: signalr.HttpTransportType.WebSockets,
    })
    .build();

  const addConnectionWithoutController = async (connectionId, userId) => {
    try {
      const dto = {
        connectionId: connectionId,
        userId: userId,
      };
      await conn.invoke("AddConnection", dto);
      // console.log("Connection added successfully.");
    } catch (error) {
      // console.error("Error adding connection:", error);
    }
  };

  const joinChatRoom = async () => {
    try {
      await conn.start().then(() => {});

      const id = await conn.invoke("GetConnectionId");
      console.log(id.value.value);
      await addConnectionWithoutController(
        id.value.value,
        "30a9511b-4a0a-423c-ac14-0d3874f85598"
      );

      conn.on("ReceiveMessage", (message, id) => {
        console.log(`Received message : ${message} from ${id} `);
        fet();
      });
    } catch (e) {
      console.log(e);
    }
  };

  // Join

  useEffect(() => {
    joinChatRoom();
    // setConn(conn);
  }, []);

  // Getting Users
  useEffect(() => {
    setLoad(true);
    fet();

    //   Getting Users Names
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/auth/AllStudent",
      requestOptions
    ).then((res) => {
      if (res.status == 200) {
        // setLoad(false);
        res.json().then((data) => {
          setUsersNames(data);
        });
      }
    });
  }, [props.refresh]);

  // Maping Data

  const ListOfUsers = users.map((e, index) => {
    let names = usersNames;
    names = names.filter((user) => user.id == e.studentId);
    if (names.length > 0) {
      return (
        <UserChat
          key={index}
          you={e.messages[0].receiverId === e.studentId}
          read={e.messages[0].isRead}
          mess={e.messages[0].message}
          name={names[0].name}
          id={e.studentId}
        />
      );
    } else {
      return <></>;
    }
  });

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}

        {/* List Of Users */}
        {ListOfUsers}
      </div>
    );
  }
}
