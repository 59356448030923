import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import Add from "./Add";
import { map } from "jquery";
import IntoInHomePage from "./IntoInHomePage";

export default function Intro() {
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const refreshHandeller = () => {
    setRefresh(!refresh);
  };

  // Add
  const [openAdd, setOpenAdd] = useState(false);

  // fetching data

  const [intros, setIntros] = useState([]);

  useEffect(() => {
    setLoad(true);
    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Introductions/GetStudyFiles"
    ).then((res) => {
      if (res.status === 200) {
        setLoad(false);
        res.json().then((data) => {
          setIntros(data.model);
        });
      } else if (res.status === 204) {
        setLoad(false);
        setIntros([]);
      } else {
        setLoad(false);
      }
    });
  }, [refresh]);

  // Delete

  const deleteHandeller = (id) => {
    setLoad(true);
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/Introductions/Delete/${id}`
    ).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setLoad(false);
        refreshHandeller();
      } else {
        setLoad(false);
      }
    });
  };

  // Maping

  const IntrosMapped = intros.map((e, index) => {
    return (
      <div
        key={index}
        className=" text-center w-full md:w-2/5 rounded-md shadow-xl m-3"
      >
        <button
          onClick={() => deleteHandeller(e.id)}
          className=" p-1 text-red-600"
        >
          <i className="fa-solid fa-trash"></i>
        </button>
        {e.title.substring(0, 5) === "video" ? (
          <video
            controls
            controlsList="nodownload"
            className=" w-full h-64 rounded-md"
            src={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
          />
        ) : (
          <img
            className=" w-full h-64 rounded-md"
            alt="intro"
            src={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
          />
        )}
      </div>
    );
  });

  return (
    <div>
      {load && <Loader />}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">
          الفيديوهات التعريفية{" "}
        </h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>

      {/*  */}
      {/* New Content */}
      {/*  */}

      <div className=" flex items-center justify-between p-4">
        <div className="">
          <button //Add
            onClick={() => setOpenAdd(true)}
            className=" rtl text-lg text-white shadow-md rounded-md py-2 px-4 bg-green-400"
          >
            {" "}
            {/* New Unit */}
            اضافة ملف <i className="fa-solid fa-file-circle-plus"></i>
          </button>
        </div>
      </div>
      <div className=" p-3 rtl flex justify-around items-center flex-wrap">
        {IntrosMapped}
      </div>

      {openAdd && (
        <Add refresh={refreshHandeller} cancel={() => setOpenAdd(false)} />
      )}
    </div>
  );
}
