import React, { useEffect, useState } from "react";
import Image from "../../../components/Lesson/GetImageLink";
import Loader from "../../../components/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router";

export default function ImgesEdit(props) {
  const [load, setLoad] = useState(false);
  const [id, setId] = useState(0);
  const search = useLocation().search;
  const lessonid = new URLSearchParams(search).get("id");

  const [refresh, setRefresh] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    //   Images

    setImages([]);
    const getImages = async () => {
      setLoad(true);
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      setLoad(true);
      fetch(
        `https://biogeniusdotnet.biogeniuses.com/api/LessonImages/GetLessonImages/${lessonid}`,
        requestOptions
      ).then((response) => {
        if (response.status === 200) {
          response.json().then(async (data) => {
            setLoad(false);
            setImages(data);
            console.log(data);
          });
        } else {
          setLoad(false);
          //   setError(true);
        }
      });
    };

    getImages();
  }, [refresh]);

  const [openDeleteImage, setOpenDeleteImage] = useState(false);
  const [inx, setInx] = useState(1);

  const delteHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/LessonImages/Delete/${id}`,
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    ).then((res) => {
      if (res.status == 200 || res.status == 204) {
        setOpenDeleteImage(false);
        setLoad(false);
        // setImages([]);
        setRefresh(!refresh);
        toast.success("تم حذف الصورة", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoad(false);
        toast.error("هناك مشكلة في الحذف", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const ImagesMaped = images.map((e, indx) => {
    // console.log(indx)
    return (
      <>
        <div
          key={indx}
          className=" border rounded-md shadow-lg m-4 col-span-1 h "
        >
          <button
            onClick={() => {
              setOpenDeleteImage(true);
              setId(e.value);
            }}
            className="  top-1  text-xl my-2 text-red-600"
          >
            <i className="fa-solid fa-trash "></i>
          </button>

          <Image id={e.value} />
        </div>
      </>
    );
  });
  return (
    <div className=" mx-auto my-2 md:w-2/3">
      {/* Lading */}
      {load && <Loader />}

      <div className=" grid grid-cols-1 md:grid-cols-3 ">{ImagesMaped}</div>

      {openDeleteImage && (
        <div // Delete Lesson Section
          className=" bg-black/30 h-screen w-full fixed top-0 left-0 flex items-center justify-center"
        >
          <div className=" coin p-8 bg-white rounded-lg">
            <h1 className=" text-xl text-red-600 text-bold mx-20">
              حذف هذه الصورة
            </h1>
            <div className="  text-center rtl mt-10">
              <button
                onClick={() => setOpenDeleteImage(false)}
                className=" bg-sky-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                الغاء
              </button>
              <button
                onClick={delteHandeller}
                className=" bg-red-600 rounded-lg text-white mx-3 py-1 px-4 text-lg"
              >
                حذف
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
