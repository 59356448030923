import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export default function ImageViaLink(props) {
  const [load, setLoad] = useState(false);
  const [link, setLink] = useState("");
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}
      <img
        className=" rounded-md max-w-full max-h-48 mx-auto"
        alt="الصور التوضيحية للدرس"
        src={props.link}
      />
    </div>
  );
}
