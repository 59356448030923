import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loader from "../../../components/Loader/Loader";
import Cookies from "js-cookie";
import Error from "../../Error/Error";

export default function Exam() {
  const [load, setLoad] = useState(false);
  const [err, setErr] = useState(false);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const course = new URLSearchParams(search).get("course");
  const unit = new URLSearchParams(search).get("unit");

  const [ques, setQues] = useState([]);
  const [title, setTiltle] = useState("");
  const [taked, setTaked] = useState(false);
  const [result, setResult] = useState(0);

  const token = Cookies.get("token");

  // Fetching Ques
  useEffect(() => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `https://student.biogeniuses.com/api/checkDataStudent/${course}`,
      requestOptions
    ).then((res) => {
      // console.log(res.status);
      if (res.status === 200) {
        res.json().then((data) => {
          // console.log(data)
          // console.log(data)
          let examUnit = data.filter((units) => units.id == unit);
          if (examUnit.length > 0) {
            let exams = examUnit[0].exams.filter((units) => units.id == id);
            if (exams.length > 0) {
              setTiltle(exams[0].title);
              let questions = [];
              exams[0].questions.map((el) => {
                questions.push({
                  question: el.questionContent,
                  student_answer: "",
                  option1: el.option1,
                  option2: el.option2,
                  option3: el.option3,
                  option4: el.option4,
                  correct_answer: el.correctAnswer,
                });
                setQues(questions);
              });
              // exams[0].questions.map((el) => {
              // questions.push({
              //   question: el.question.questionContent,
              //   student_answer: "",
              //   option1: el.question.option1,
              //   option2: el.question.option2,
              //   option3: el.question.option3,
              //   option4: el.question.option4,
              //   correct_answer: el.question.correctAnswer,
              //   });
            } else {
              setErr(true);
            }
          } else {
            setErr(true);
          }
          // data.map((e) => {
          //   setErr(true);
          //   if (e.length > 0) {
          //     setErr(false);
          //     setTiltle(e[0].title);
          //     let questions = [];
          // e[0].questions.map((el) => {
          //   questions.push({
          //     question: el.question.questionContent,
          //     student_answer: "",
          //     option1: el.question.option1,
          //     option2: el.question.option2,
          //     option3: el.question.option3,
          //     option4: el.question.option4,
          //     correct_answer: el.question.correctAnswer,
          //   });
          //       setQues(questions);
          //     });
          //   }
          // });
          setLoad(false);
        });
      } else {
        setLoad(false);
        setErr(true);
      }
    });

    const formdata = new FormData();
    formdata.append("idExam", id);
    const requestOptionsCheck = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://student.biogeniuses.com/api/getExam",
      requestOptionsCheck
    ).then((res) => {
      if (res.status === 200) {
        setTaked(true);
        res.json().then((data) => {
          setResult(data[0][0].score);
          // console.log(data[0][0].score)
        });
      } else {
        setTaked(false);
      }
    });
  }, []);

  // main maping
  const Questions = ques.map((e, index) => {
    return (
      <div className=" shadow-md rtl my-5 p-4" key={index}>
        <div className=" flex items-center p-4 text-xl text-[#3B3486] font-bold border-b">
          <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#3B3486]">
            <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
              {index + 1}
            </h1>
          </div>
          <h1>{e.question}</h1>
        </div>

        <div className=" p-6">
          <div //Option1
            onClick={() => {
              let qu = [...ques];
              qu[index] = {
                ...qu[index],
                student_answer: e.option1,
              };
              setQues(qu);
            }}
            className={` my-2 border p-2 cursor-pointer rounded-full ${
              e.student_answer === e.option1 &&
              " bg-[#9ed04e]/30 text-white shadow-md "
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option1}
            </h1>
          </div>

          <div //Option2
            onClick={() => {
              let qu = [...ques];
              qu[index] = {
                ...qu[index],
                student_answer: e.option2,
              };
              setQues(qu);
            }}
            className={` my-2 border p-2  cursor-pointer rounded-full ${
              e.student_answer === e.option2 &&
              " bg-[#9ed04e]/30 text-white shadow-md "
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option2}
            </h1>
          </div>

          <div //Option3
            onClick={() => {
              let qu = [...ques];
              qu[index] = {
                ...qu[index],
                student_answer: e.option3,
              };
              setQues(qu);
            }}
            className={` my-2 border p-2  cursor-pointer rounded-full ${
              e.student_answer === e.option3 &&
              " bg-[#9ed04e]/30 text-white shadow-md "
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option3}
            </h1>
          </div>

          <div //Option4
            onClick={() => {
              let qu = [...ques];
              qu[index] = {
                ...qu[index],
                student_answer: e.option4,
              };
              setQues(qu);
            }}
            className={` my-2 border p-2  cursor-pointer rounded-full ${
              e.student_answer === e.option4 &&
              " bg-[#9ed04e]/30 text-white shadow-md "
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option4}
            </h1>
          </div>
        </div>
      </div>
    );
  });

  // Maped after Send

  const QuesWithAnsr = ques.map((e, index) => {
    return (
      <div className=" shadow-md rtl my-5 p-4" key={index}>
        <div className=" flex items-center p-4 text-xl text-[#3B3486] font-bold border-b">
          <div className=" z-0 rounded-full flex min-h-8 min-w-8 md:min-h-9 md:min-w-9 justify-center relative text-white mx-3 bg-[#3B3486]">
            <h1 className=" absolute text-2xl md:text-3xl font-bold bottom-[-9px] md:bottom-[-7px]">
              {index + 1}
            </h1>
          </div>
          <h1>{e.question}</h1>
        </div>

        <div className=" p-6">
          <div //Option1
            className={` flex items-center justify-between my-2 border p-2 rounded-full ${
              e.correct_answer === e.option1
                ? "bg-[#9ed04e]/30 text-white shadow-md"
                : `${
                    e.student_answer === e.option1 &&
                    "bg-red-400/30 text-white shadow-md"
                  } `
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option1}
            </h1>
            <i
              className={` mx-4 text-lg ${
                e.correct_answer === e.option1
                  ? "fa-solid fa-check text-green-800"
                  : `${
                      e.student_answer === e.option1 &&
                      "fa-solid fa-x text-red-800"
                    } `
              } `}
            ></i>
          </div>

          <div //Option2
            className={` flex items-center justify-between my-2 border p-2   rounded-full ${
              e.correct_answer === e.option2
                ? "bg-[#9ed04e]/30 text-white shadow-md"
                : `${
                    e.student_answer === e.option2 &&
                    "bg-red-400/30 text-white shadow-md"
                  } `
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option2}
            </h1>
            <i
              className={` mx-4 text-lg ${
                e.correct_answer === e.option2
                  ? "fa-solid fa-check text-green-800"
                  : `${
                      e.student_answer === e.option2 &&
                      "fa-solid fa-x text-red-800"
                    } `
              } `}
            ></i>
          </div>

          <div //Option3
            className={` flex items-center justify-between my-2 border p-2   rounded-full ${
              e.correct_answer === e.option3
                ? "bg-[#9ed04e]/30 text-white shadow-md"
                : `${
                    e.student_answer === e.option3 &&
                    "bg-red-400/30 text-white shadow-md"
                  } `
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option3}
            </h1>
            <i
              className={` mx-4 text-lg ${
                e.correct_answer === e.option3
                  ? "fa-solid fa-check text-green-800"
                  : `${
                      e.student_answer === e.option3 &&
                      "fa-solid fa-x text-red-800"
                    } `
              } `}
            ></i>
          </div>

          <div //Option4
            className={` flex items-center justify-between my-2 border p-2   rounded-full ${
              e.correct_answer === e.option4
                ? "bg-[#9ed04e]/30 text-white shadow-md"
                : `${
                    e.student_answer === e.option4 &&
                    "bg-red-400/30 text-white shadow-md"
                  } `
            }`}
          >
            <h1 className=" px-4 text-lg text-[#9ed04e] font-semibold">
              {e.option4}
            </h1>
            <i
              className={` mx-4 text-lg ${
                e.correct_answer === e.option4
                  ? "fa-solid fa-check text-green-800"
                  : `${
                      e.student_answer === e.option4 &&
                      "fa-solid fa-x text-red-800"
                    } `
              } `}
            ></i>
          </div>
        </div>
      </div>
    );
  });

  //
  // Send
  //

  const [sended, setSended] = useState(false);
  const sendHandeeler = async () => {
    setLoad(true);
    let res = 0;
    await ques.map((e) => {
      if (e.correct_answer == e.student_answer) {
        res = res + 1;
      }
    });
    setResult(res);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append("idExam", id);
    formdata.append("score", res);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch("https://student.biogeniuses.com/api/postexam", requestOptions).then(
      (res) => {
        if (res.status === 200) {
          setSended(true);
          setLoad(false);
          window.scrollTo(0, 0);
        } else {
          setLoad(false);
        }
      }
    );
  };

  if (err) {
    return <Error />;
  } else {
    return (
      <div>
        {/* Lading */}
        {load && <Loader />}

        <div className=" my-4 flex items-center justify-center">
          <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
          <h1 className=" text-2xl m-2 font-bold text-[#3B3486]"> {title}</h1>
          <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        </div>
        {taked ? (
          <div>
            <div className=" text-center p-4 md:p-8 text-green-700">
              <i className="fa-solid fa-check  text-5xl"></i>
              <h1 className="  text-4xl">تم الامتحان من قبل</h1>
              <h1 className=" my-6 text-4xl">
                {" "}
                {ques.length}\
                <span className=" font-bold text-black">{result}</span> : الدرجة
              </h1>
              {/* { <button //Retake Btn
                onClick={() => setTaked(false)}
                className=" text-center my-4 bg-sky-800 text-white text-lg py-2 px-4 rounded-md shadow-2xl"
              >
                اعادة الامتحان
              </button>} */}
            </div>
          </div>
        ) : (
          <>
            {sended ? (
              <div>
                <div className=" text-center p-4 md:p-8 text-green-700">
                  <i className="fa-solid fa-check  text-5xl"></i>
                  <h1 className="  text-4xl">تم التسليم</h1>
                  <h1 className=" my-6 text-4xl">
                    {" "}
                    {ques.length}\
                    <span className=" font-bold text-black">{result}</span> :
                    الدرجة
                  </h1>
                </div>
                {QuesWithAnsr}
              </div>
            ) : (
              <>
                {Questions}
                {/* Send Btn */}
                <div className=" text-center">
                  <button
                    onClick={sendHandeeler}
                    className=" text-center my-4 bg-sky-800 text-white text-lg py-2 px-4 rounded-md shadow-2xl"
                  >
                    <i className="fa-solid fa-paper-plane"></i> تسليم
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
