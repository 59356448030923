import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function Package() {
  const [load, setLoad] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packagesSearch, setPackagesSearch] = useState([]);
  const [previousBuy, setPrevious] = useState([]);
  const [materials, setMatrails] = useState([]);

  // Feching data

  const token = Cookies.get("token");
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      // Geting Uints
      `https://biogeniusdotnet.biogeniuses.com/api/Packages/GetPackagesByDateRange`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setPackages(data);
          setPackagesSearch(data);
          // setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
    fetch(`https://biogeniusdotnet.biogeniuses.com/api/Materials/GetAll`).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            // setLoad(false);
            setMatrails(data);
          });
        }
      }
    );
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptionsforAlreadyBought = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://student.biogeniuses.com/api/getAllPurchaseOnly",
      requestOptionsforAlreadyBought
    )
      .then((res) => res.json())
      .then((data) => {
        setPrevious(data);
        setLoad(false);
        // console.log(data)
      });
  }, []);

  // Maping Packages

  const LessonsMaped = packages.map((e, index) => {
    let pre = false;
    let prevs = previousBuy;
    prevs = prevs.filter((el) => el.id == e.id);
    if (prevs.length > 0) {
      pre = true;
    }
    return (
      <div
        className=" relative  shadow-md rtl p-6 mx-4 md:mx-6 my-6 rounded-3xl bg-[#9ed04e]/20 col-span-1"
        key={index}
      >
        {+e.discount > 0 && (
          <section className=" absolute top-0 h-[30px] flex items-center justify-center px-3 text-[15px] bg-[#3B3486]/70 text-white ">
            خصم
          </section>
        )}
        <div>
          <img
            src={
              e.imageUrl
                ? `https://biogeniusdotnet.biogeniuses.com${e.imageUrl}`
                : "./imgs/1.avif"
            }
            className=" w-full h-48 rounded-md"
            alt={`course ${e.name}`}
          />
          <h1 className=" text-[#9ed04e] text-2xl my-4 font-bold text-center">
            {e.name}
          </h1>
          {/* <h4 className=" my-6 text-xl text-[#3B3486]/50">
          النوع: {e.isStudy ? "باقة شرح" : "باقة مراجعة"}
        </h4> */}
          <h4 className=" my-6 text-xl text-[#3B3486]/50">
            المادة: {e.materialName}
          </h4>
          <h4 className=" my-6 text-xl text-[#3B3486]/50">الصف: {e.year}</h4>
          <h4
            style={{ opacity: `${e.term ? 100 : 0}` }}
            className=" my-6 text-xl text-[#3B3486]/50"
          >
            الفصل: {e.term}
          </h4>
          <h4 className=" flex  justify-center my-6 text-xl text-center font-bold text-[#3B3486]">
            السعر:{" "}
            {+e.discount > 0 && (
              <section className="relative mx-2 text-[#3B3486]/80  w-fit">
                {e.price}
                <hr className=" mt-[-15px] rotate-[150deg]  h-[1px] bg-[red]/60 border-[red]/60 "></hr>{" "}
              </section>
            )}{" "}
            {+e.price - +e.discount} جنيهاً
          </h4>
        </div>
        <div className="mb-2">
          {!pre ? (
            <Link
              // to={`/lessons?id=${e.id}`}
              to={`/showcourse?id=${e.id}`}
            >
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    انطلق معنا
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          ) : (
            <Link
              // to={`/lessons?id=${e.id}`}
              to={`/course?course=${e.id}`}
            >
              <div className="  w-full bg-white shadow-md text-green-800 flex items-center my-2 rounded-full transition-all ease-in-out duration-300 py-2">
                <div className=" flex items-center justify-between w-full">
                  <h1 className="mx-5 text-lg md:text-base lg:text-xl">
                    {" "}
                    عرض التفاصيل
                  </h1>
                  <i className=" mx-8 an fa-solid fa-chevron-left"></i>
                </div>
              </div>
            </Link>
          )}
        </div>
      </div>
    );
  });

  const [yearFilter, setYearFilter] = useState();
  const [matrailFilter, setmatrailFilter] = useState();

  useEffect(() => {
    let search = packagesSearch;
    if (yearFilter) {
      search = search.filter((e) => e.year == yearFilter);
    }
    if (matrailFilter) {
      search = search.filter((e) => e.materialId == matrailFilter);
    }

    setPackages(search);
  }, [yearFilter, matrailFilter]);
  return (
    <div>
      {/* Lading */}
      {load && <Loader />}

      {/* Title */}

      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486]">انطلق معنا</h1>
        <hr className=" m-2 w-24 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <div className=" rtl md:flex items-center justify-between p-4">
        <div className=" w-52 md:w-auto rtl">
          {" "}
          {/* Filters*/}{" "}
          <select //Year
            value={yearFilter}
            onChange={(e) => {
              setYearFilter(e.target.value);
            }}
            className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              الصف
            </option>
            <option value={1}>الاول الثانوي</option>
            <option value={2}>الثاني الثانوي</option>
            <option value={3}>الثالث الثانوي</option>
          </select>
          <select //matrail
            value={matrailFilter}
            onChange={(e) => {
              setmatrailFilter(e.target.value);
            }}
            className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
          >
            <option selected hidden>
              المادة
            </option>
            {materials.map((e) => (
              <option key={e.index} value={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <hr className=" w-[60%] mx-auto mb-6" />

      {/* Packages */}

      <div className=" rtl p-8 md:p-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <>{LessonsMaped}</>
      </div>
    </div>
  );
}
