import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export default function Image(props) {
  const [load, setLoad] = useState(false);
  const [link, setLink] = useState("");
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/LessonImages/Details/${props.id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setLink("https://biogeniusdotnet.biogeniuses.com" + data.imageUrl);

          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);
  return (
    <div className=" ">
      {/* Lading */}
      {load && <Loader />}
      <img
        className=" rounded-md max-w-full max-h-48 mx-auto"
        alt="الصور التوضيحية للدرس"
        src={link}
      />
    </div>
  );
}
