import React from "react";
import "./loader.css";

export default function Loader() {
  return (
    <>
      <div className=" z-50 h-screen w-screen fixed top-0 left-0 bg-black/30 flex items-center justify-center">
        <svg className=" sv" viewBox="25 25 50 50">
          <circle cx="50" cy="50" r="20"></circle>
        </svg>
      </div>
    </>
  );
}
