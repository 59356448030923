import React, { useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export default function VideoShow(props) {
  const [load, setLoad] = useState(false);
  const [link, setLink] = useState("");
  const [desc, setDesc] = useState("");
  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/LessonVideos/Details/${props.id}`,
      requestOptions
    ).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setDesc(data.description);
          setLink("https://biogeniusdotnet.biogeniuses.com" + data.videoUrl);
          setLoad(false);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  return (
    <div className=" shadow-lg mb-10 border rounded-xl py-6 mx-6 md:mx-auto md:w-3/4 ">
      {/* Lading */}
      {load && <Loader />}
      {/* Video */}
      <video
        className=" w-full h-96"
        controlsList="nodownload"
        src={link}
        controls
      />

      {/* Description */}
      {!!desc && (
        <div>
          <h1 className=" text-xl text-center text-[#9ed04e]  my-6 ">الشرح</h1>

          <p className=" text-lg leading-relaxed text-right mx-auto w-2/3">
            {desc}
          </p>
        </div>
      )}
    </div>
  );
}
