import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";

export default function Add(props) {
  const [load, setLoad] = useState(false);
  const [year, setYear] = useState(0);
  const [semster, setSemster] = useState(0);
  const [lesson, setLesson] = useState(0);

  // Getting Lessons
  const [Lessons, setLessons] = useState([]);
  const [LessonsSelected, setLessonsSelected] = useState([]);
  const [lessonsForSeach, setLessonsForSeacrch] = useState([]);

  useEffect(() => {
    setLoad(true);
    var requestOptions = {
      // define Vars
      method: "GET",
      redirect: "follow",
    };
    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/ChooseFreeLessonsByBackage/${props.packagesId}`,
      requestOptions
    ) // Fetch data
      .then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            setLessons(res);
            setLessonsForSeacrch(res);
            setLoad(false);
          });
        } else {
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/GetFreeLessonsByBackage/${props.packagesId}`
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setLoad(false);
          let ids = [];
          data.map((e) => {
            ids.push(e.id);
          });
          // console.log(ids);
          setLessonsSelected(ids);
        });
      } else {
        setLoad(false);
      }
    });
  }, []);

  //
  // Filtering
  //

  useEffect(() => {
    var search = [...lessonsForSeach];
    if (year !== 0) {
      search = search.filter((e) => e.year == year);
      // console.log(search)
    }
    if (year != 3) {
      if (semster !== 0) {
        search = search.filter((e) => e.term == semster);
        // console.log(search)
      }
    }

    setLessons(search);
  }, [year, semster, lessonsForSeach]);

  //
  // Maping lesson
  //
  const options = Lessons.map((e, index) => {
    return (
      <option key={index} value={e.id}>
        {e.title}
      </option>
    );
  });

  //
  // send
  //

  const addHandeller = () => {
    setLoad(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let ids = [...LessonsSelected];
    ids.push(+lesson);

    var raw = JSON.stringify(ids);
    // var raw = JSON.stringify({
    //   selectedLessons: ids,
    // });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://biogeniusdotnet.biogeniuses.com/api/FreeContents/ChooseFreeLessonsByBackage/${props.packagesId}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          setLoad(false);
          props.cancel();
          props.refresh();
        } else {
          console.log("error");
          setLoad(false);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div className=" z-50 fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-black/20">
      {" "}
      {/* Lading */}
      {load && <Loader />}
      <div className=" coin bg-white p-8 rounded-lg">
        <h1 className=" text-bold text-xl text-sky-600 mx-12 my-4 md:mx-24">
          اضافة درس
        </h1>

        <div className=" text-center rtl">
          <form>
            <select //Year
              value={lesson}
              onChange={(e) => setLesson(e.target.value)}
              className=" m-2 w-52 p-2 outline-none text-xl border-b  text-[#45407e] "
            >
              <option selected hidden>
                الدرس
              </option>
              {options}
            </select>
          </form>
        </div>

        <div className="  text-center mt-8">
          <button //Delte Btn
            disabled={lesson == 0}
            onClick={addHandeller}
            className=" disabled:opacity-70 bg-green-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            اضافة
          </button>
          <button //Cancel Btn
            onClick={props.cancel}
            className=" bg-sky-600 text-lg py-1 px-4 mx-2 rounded-lg text-white "
          >
            الغاء
          </button>
        </div>
      </div>
    </div>
  );
}
