import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";

export default function IntoInHomePage() {
  const [intros, setIntros] = useState([]);

  useEffect(() => {
    fetch(
      "https://biogeniusdotnet.biogeniuses.com/api/Introductions/GetStudyFiles"
    ).then((res) => {
      if (res.status === 200) {
        //   setLoad(false);
        res.json().then((data) => {
          setIntros(data.model);
        });
      } else {
      }
    });
  }, []);

  const IntrosMapped = intros.map((e, index) => {
    return (
      <div key={index} className=" text-center w-full rounded-md  p-5">
        {e.title.substring(0, 5) === "video" ? (
          <video
            controls
            controlsList="nodownload"
            className=" w-full h-64 rounded-md shadow-xl"
            src={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
          />
        ) : (
          <img
            className=" w-full h-64 rounded-md shadow-xl"
            alt="intro"
            src={"https://biogeniusdotnet.biogeniuses.com/" + e.fileUrl}
          />
        )}
      </div>
    );
  });
  return (
    <div>
      {intros.length > 0 && (
        <div className=" rates hidden md:flex">
          <ReactOwlCarousel
            autoplay
            autoplayTimeout={2000}
            autoplayHoverPause={true}
            items={3}
            className="owl-theme"
            loop
            margin={10}
          >
            {IntrosMapped}
          </ReactOwlCarousel>
        </div>
      )}
      {intros.length > 0 && (
        <div className=" rates md:hidden">
          <ReactOwlCarousel
            autoplay
            autoplayTimeout={2000}
            autoplayHoverPause={true}
            items={1}
            className="owl-theme"
            loop
            margin={10}
          >
            {IntrosMapped}
          </ReactOwlCarousel>
        </div>
      )}
    </div>
  );
}
