import React from "react";

export default function Policises() {
  return (
    <div className=" rtl">
      <div className=" my-4 flex items-center justify-center">
        <hr className="  m-2 w-8 h-2 bg-[#3B3486] rounded-full" />
        <h1 className=" text-2xl m-2 font-bold text-[#3B3486] text-center ">
          {" "}
          سياسة الخصوصية لمنصة بايو جينيس التعليمية{" "}
        </h1>
        <hr className=" m-2 w-8 h-2 bg-[#3B3486] rounded-full" />
      </div>
      <div className=" my-10 p-3">
        <h1 className=" font-bold text-3xl">
          نرحب بكم في منصة بايوجينيس التعليمية{" "}
        </h1>
        <h1 className=" my-2 font- text-xl">
          نحن ملتزمون بحماية خصوصيتك واحترام بياناتك الشخصية
        </h1>
        <h1 className=" my-2 font- text-xl">
          توضح هذه السياسات تعاملنا مع معلوماتك الشخصية عندما تستخدم منصة بايو
          جينيس التعليمية.
        </h1>
        <h1 className="  my-4 font-bold text-xl">
          1 استخدام المعلومات الشخصية:
        </h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          نستخدم المعلومات الشخصية التي نجمعها منك لألغراض التالية :
        </h1>
        <h1 className=" px-8 my-2 font- text-xl">
          تسجيلك في الدورات التدريبية
        </h1>
        <h1 className=" px-8 my-2 font- text-xl">تقديم محتوي تعليمي لك</h1>
        <h1 className=" px-8 my-2 font- text-xl"> اإلجابة على أسئلتك</h1>
        <h1 className=" px-8 my-2 font- text-xl">تقديم الدعم الفني لك</h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          لتحسين خدماتنا وتطوير منتجات جديدة
        </h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          لارسال رسائل إخبارية وعروض ترويجية إليك.
        </h1>
        <h1 className="  my-4 font-bold text-xl">2 كشف المعلومات الشخصية:</h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          لن نكشف معلوماتك الشخصية ألي طرف ثالث باستثناء
        </h1>
        <h1 className=" px-8 my-2 font- text-xl">
          {" "}
          في حالة وجود امر قضائي أو طلب من سلطة حكومية
        </h1>
        <h1 className=" px-8 my-2 font- text-xl">حماية حقوقنا أو ممتلكاتنا</h1>
        <h1 className=" px-8 my-2 font- text-xl">
          {" "}
          لمنع الاحتيال والنشاط غير القانوني
        </h1>
        <h1 className="  my-4 font-bold text-xl">3 أمان المعلومات الشخصية:</h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          {" "}
          نتخذ جميع الاجراءات الامنية اللازمة لحماية معلوماتك الشخصية من الوصول
          غير المصرح به أو الاستخدام أو الكشف أو التعديل أو التلف
        </h1>
        <h1 className=" px-2 my-2 font-semibold text-xl">
          نستخدم تقنيات تشفير البيانات لحماية معلوماتك الشخصية أثناء النقل
          والتخزين.
        </h1>
        <h1 className="  my-12 text-center font-bold text-xl">
          نشكركم علي تفهمكم
        </h1>
        <h1 className="  my-12 text-center font-bold text-xl">
          مع تمنياتنا لكم بدراسة مثمرة وموفقة
        </h1>
      </div>
    </div>
  );
}
